import React, {useEffect, useState} from 'react';
// @material-ui/icons
import CategoryIcon from '@material-ui/icons/Category';
// Core components
import Card from "../../Card/Card";
import AsyncSelect from 'react-select/async';
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import {baseUrl} from "../../../appConfig";
import {apiToken} from "../../../appConfig";
import Axios from "axios";
import PropTypes from "prop-types";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CreateDialog from "./CreateDialog/CreateDialog";


const promiseOptions = async (inputValue = "") => {
    return new Promise(resolve => {
        Axios.get(baseUrl + "/Categories/search?query=" + inputValue, {headers:{Authorization : apiToken}})
            .then(res => {
                if(res.status === 200){
                    resolve(res.data.data);
                }
            })
    });
};

const CategorySelector = ({onChange, defaultValue}) => {
    const [value, setValue] = useState(null);
    const [values, setValues] = useState([]);
    const [createDialog, setCreateDialog] = useState(false);

    useEffect(()=> {
        if(defaultValue && values.length > 0){
            setValue(values.filter(val => val.value === defaultValue.toString())[0])
        }
        // eslint-disable-next-line
    }, [defaultValue, values]);

    return (
        <Card>
            <CardHeader color="warning" icon>
                <CardIcon color="info">
                    <Tooltip title="افزودن">
                        <IconButton
                            color="inherit"
                            onClick={()=> setCreateDialog(true)}
                        >
                            <CategoryIcon/>
                        </IconButton>
                    </Tooltip>
                </CardIcon>
                <h3 className="px-3" style={{color: "#000"}}>دسته بندی</h3>
            </CardHeader>
            <CardBody>
                <AsyncSelect
                    isRtl
                    cacheOptions
                    defaultOptions
                    value={value}
                    placeholder="انتخاب کنید ..."
                    noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                    loadingMessage={() => "در حال بارگزاری .."}
                    styles={{ menu: base => ({ ...base, zIndex: 5555 }) }}
                    loadOptions={async inputValue => {
                        const request = await promiseOptions(inputValue);
                        await setValues(request);
                        return request;
                    }}
                    onChange={res => {
                        setValue(res);
                        onChange(res);
                    }}
                />
            </CardBody>
            <CreateDialog
                keepMounted
                open={createDialog}
                onClose={()=> setCreateDialog(false)}
                onCreate={res => setValues([...values, res])}
            />
        </Card>
    );
};

CategorySelector.propTypes = {
    defaultValue: PropTypes.number,
    onChange : PropTypes.func
};

export default CategorySelector;