import React, {useEffect, useState} from 'react';
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import 'react-dropzone/examples/theme.css';
import {connect} from "react-redux";
import CloseIcon from '@material-ui/icons/CancelSharp';
import {SetSiteLoading} from "../../redux/actions/actions";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import {getUrl} from "../../functions";
import Axios from "axios";
import {apiToken, baseUrl} from "../../appConfig";
import Typography from "@material-ui/core/Typography";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        direction: "ltr",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "1rem",
        backgroundColor: theme.palette.background.paper
    },
    LocationCloseIcon: {
        left: '3px',
        top: '3px',
    },
    large: {
        width: theme.spacing(30),
        height: theme.spacing(30),
    },
}));

const UserInformationDrawer = ({open, onClose, value, anchor = "left"}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [data, setData] = useState({});

    useEffect(() => {
        if (open)
            Axios.get(baseUrl + "/targets/byName/" + value, {headers: {Authorization : apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data.data);
                    }
                })
        // eslint-disable-next-line
    }, [value]);

    return (
        <Drawer
            PaperProps={{style: {width: 300}, className: "px-2 pt-4 pb-2"}}
            anchor={anchor}
            variant="persistent"
            open={open}
            elevation={3}
        >
            <IconButton onClick={() => onClose()} className={classes.LocationCloseIcon + " position-absolute"}>
                <CloseIcon/>
            </IconButton>
            <div className="w-100 d-flex flex-column align-items-center p-3">
                <Avatar
                    alt="Remy Sharp"
                    src={getUrl(data.avatarId)}
                    className={classes.large}
                />
                <h5>{value}</h5>
                <Typography
                    variant="body2"
                    style={{
                        whiteSpace:"pre-wrap",
                        textAlign:"justify",
                        minHeight: '40rem',
                        height: '50vh',
                        overflow: "hidden"
                    }}
                >
                    {data.description && data.description.replace(/<{1}[^<>]{1,}>{1}/g, " ").replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ')}
                </Typography>
                <Button
                    className="mt-3"
                    variant="outlined"
                    color="primary"
                    onClick={()=> navigate("/admin/targets/study/" + data.targetId)}
                >
                    مطالعه
                </Button>
            </div>
        </Drawer>
    );
};

const mapState = states => ({
    UserDetails: states.userDetails
});
export default connect(mapState)(UserInformationDrawer);