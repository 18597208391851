import React, {forwardRef, useImperativeHandle, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {MenuItem} from "@material-ui/core";

const Inputs = (props, ref) => {
    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [term, setTerm] = useState(1);
    const [termData, setTermData] = useState([]);
    const [error, setError] = useState([]);

    useImperativeHandle(ref, ()=> ({
        name,
        slug,
        setName,
        setSlug,
        term,
        setTerm,
        setTermData,
        setError
    }), [name, slug, term]);

    const inputHandler = async (input) => {
        const {value, name} = input.target;

        if(name === "name")
            setName(value);
        else if(name === "slug")
            setSlug(value);
        else if(name === "term")
            setTerm(value);

        if (value === "")
            setError([error, name]);
        else if (error.indexOf(name) > -1 && value !== "")
            setError(error.filter(x => x !== name))
    };

    return (
        <div>
            <TextField
                error={error.indexOf("name") > -1}
                helperText={error.indexOf("name") > -1 ? "لطفا یک عنوان انتخاب کنید" : ""}
                name="name"
                label="عنوان"
                variant="outlined"
                className="w-100 my-3"
                onChange={inputHandler}
                value={name}
                inputProps={{"custom-importance": "true"}}
            />
            <TextField
                name="slug"
                label="نامک"
                variant="outlined"
                className="w-100 my-3"
                onChange={inputHandler}
                value={slug}
                inputProps={{"custom-importance": "false"}}
            />
            <TextField
                select
                name="term"
                label="ترم"
                variant="outlined"
                className="w-100 my-3"
                onChange={inputHandler}
                value={term}
                inputProps={{"custom-importance": "true"}}
            >
                {
                    termData.map(data => (
                        <MenuItem key={data.termId} value={data.termId}>{data.name}</MenuItem>
                    ))
                }
            </TextField>
        </div>
    );
};

export default forwardRef(Inputs);