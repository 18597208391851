import React, {useCallback, useEffect, useState} from 'react';
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import ChartistGraph from "react-chartist";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import AccessTime from "@material-ui/icons/AccessTime";
import {emailsSubscriptionChart} from "../../../variables/charts";
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/rtlStyle";
import './CategoryStatistics.css';
import moment from "moment-jalali";

const useStyles = makeStyles(styles);
const CategoryStatistics = ({statisticData, createdDate}) => {
    const classes = useStyles();

    const [data, setData] = useState({
        labels : [],
        series : []
    });

    useEffect(()=> {
        if(statisticData && typeof statisticData === "object" && statisticData.length){
            const processedData = {
                labels : statisticData.map(data => `<div class="chart-params">${data.key}</div>`),
                series : [statisticData.map(data => parseInt(data.value))]
            };
            setData(processedData)
        }
        // eslint-disable-next-line
    }, [statisticData]);

    const {options, responsiveOptions, animation} = emailsSubscriptionChart;

    if(data.series && data.series[0]){
        const highestValue = data.series[0].sort((a, b)=> b - a)[0];
        options.high = highestValue + Math.ceil((highestValue * .1));
    }

    const Chart = useCallback(()=> (
        <ChartistGraph
            className="ct-chart"
            data={data}
            type="Bar"
            options={options}
            responsiveOptions={responsiveOptions}
            listener={animation}
        />
    ), [data]);

    return (
        <GridItem xs={12} sm={12} md={4}>
            <Card chart>
                <CardHeader color="warning">
                    <Chart />
                </CardHeader>
                <CardBody>
                    <h4 className={classes.cardTitle}>دسته بندی های پر استفاده</h4>
                    <p className={classes.cardCategory}>دسته بندی هایی با بیشترین استفاده توسط پروژه ها</p>
                </CardBody>
                <CardFooter chart>
                    <div className={classes.stats}>
                        <AccessTime/>
                        آخرین به روز رسانی <strong className="mx-1">{moment(createdDate).fromNow()}</strong>
                    </div>
                </CardFooter>
            </Card>
        </GridItem>
    );
}

CategoryStatistics.propTypes = {
    statisticData : PropTypes.array.isRequired
};

export default CategoryStatistics;