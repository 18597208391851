import React, {useEffect, useState} from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Publish from "../../../components/Editor/Publish/Publish";
import {SetSiteLoading} from "../../../redux/actions/actions";
import {useNavigate, useParams} from 'react-router-dom';
import Axios from "axios";
import {apiToken, baseUrl} from '../../../appConfig';
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import AssessmentRoundedIcon from "@material-ui/icons/AssessmentRounded";
import Tasks from "../../../components/Tasks/Tasks";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import {connect} from 'react-redux';

let taskRef;
const AccessibilityEditor = ({dispatch}) => {
    const [data, setData] = useState([]);

    const {userId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(()=> {
        Axios.get(baseUrl + "/projects", {headers : {Authorization : apiToken}})
            .then(res => {
                if(res.status === 200){
                    setData(res.data.data.map(data => ({
                        postId : data.projectId,
                        title : data.name,
                        fileManagerId : data.avatarId
                    })));
                }
            });
        Axios.get(baseUrl + "/projects/forsubscriber/" + userId, {headers : {Authorization : apiToken}})
            .then(res => {
                if(res.status === 200 && res.data.data.length > 0 && taskRef){
                    taskRef.setChecked(res.data.data);
                }
            })
        // eslint-disable-next-line
    }, []);

    const createAccess = async id => {
        await dispatch(SetSiteLoading(true));
        await Axios.post(baseUrl + "/projects/createAccess", {
            userId : userId,
            projectIds : [
                id
            ]
        }, {headers : {Authorization : apiToken}})
            .then(res => {
                if(res.status === 200){
                    taskRef.setChecked([...taskRef.checked, id]);
                    sendNotification(enqueueSnackbar, "دسترسی افزوده شد")
                }
            })
            .catch(res => {
                if(res.response && res.response.status === 400){
                    sendErrorNotification(enqueueSnackbar, res.response.data.message)
                }
            });
        await dispatch(SetSiteLoading(false));
    };

    const removeAccess = async id => {
        await dispatch(SetSiteLoading(true));
        await Axios.post(baseUrl + "/projects/removeAccess", {
            userId : userId,
            projectId : id
        }, {headers : {Authorization : apiToken}})
            .then(res => {
                if(res.status === 200){
                    taskRef.setChecked(taskRef.checked.filter(x => x !== id));
                    sendNotification(enqueueSnackbar, "دسترسی حذف شد")
                }
            })
            .catch(res => {
                if(res.response && res.response.status === 400){
                    sendErrorNotification(enqueueSnackbar, res.response.data.message)
                }
            });
        await dispatch(SetSiteLoading(false));
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Paper className="bg-transparent">
                    <Box p={2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <CustomTabs
                                    rtlActive
                                    title="دسترسی ها:"
                                    headerColor="warning"
                                    tabs={[
                                        {
                                            tabName: "پروژه",
                                            tabIcon: AssessmentRoundedIcon,
                                            tabContent: (
                                                <Tasks
                                                    rtlActive
                                                    switchOnExist
                                                    ref={ref => {taskRef = ref}}
                                                    tasks={data || []}
                                                    onCreate={createAccess}
                                                    onRemove={removeAccess}
                                                />
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    </Box>
                </Paper>
            </GridItem>
        </GridContainer>
    );
};

export default connect()(AccessibilityEditor);