import React, {useCallback, useEffect, useState} from 'react';
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import ChartistGraph from "react-chartist";
import {completedTasksChart} from "../../../variables/charts";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import AccessTime from "@material-ui/icons/AccessTime";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/rtlStyle";
import PropTypes from "prop-types";
import moment from "moment-jalali";

const useStyles = makeStyles(styles);
const MostProjectRelation = ({statisticData, createdDate}) => {
    const classes = useStyles();

    const [data, setData] = useState({
        labels : [],
        series : []
    });

    useEffect(()=> {
        if(statisticData && typeof statisticData === "object" && statisticData.length){
            const processedData = {
                labels : statisticData.map(data => `<div class="chart-params">${data.key}</div>`),
                series : [statisticData.map(data => parseInt(data.value))]
            };
            setData(processedData)
        }
        // eslint-disable-next-line
    }, [statisticData]);

    const {options, animation} = completedTasksChart;

    if(data.series && data.series[0]){
        const highestValue = data.series[0].sort((a, b)=> b - a)[0];
        options.high = highestValue + Math.ceil((highestValue * .1));
    }

    const Chart = useCallback(()=> (
        <ChartistGraph
            className="ct-chart"
            data={data}
            type="Bar"
            options={options}
            listener={animation}
        />
    ), [data]);

    return (
        <GridItem xs={12} sm={12} md={4}>
            <Card chart>
                <CardHeader color="danger">
                    <Chart />
                </CardHeader>
                <CardBody>
                    <h4 className={classes.cardTitle}>بیشترین ارتباطات پروژه ای</h4>
                    <p className={classes.cardCategory}>بیشترین ارتباط های موجود افراد با پروژه ها</p>
                </CardBody>
                <CardFooter chart>
                    <div className={classes.stats}>
                        <AccessTime/>
                        آخرین به روز رسانی <strong className="mx-1">{moment(createdDate).fromNow()}</strong>
                    </div>
                </CardFooter>
            </Card>
        </GridItem>
    );
}

MostProjectRelation.propTypes = {
    statisticData : PropTypes.array.isRequired
};

export default MostProjectRelation;