import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {SetSiteLoading} from "../../../../redux/actions/actions";
import {baseUrl} from '../../../../appConfig';
import Axios from "axios";
import {Button} from "@material-ui/core";
import {sendErrorNotification, sendNotification} from "../../../../functions";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {apiToken} from "../../../../appConfig";

const CreateDialog = ({onClose, onCreate, ...props}) => {
    const {enqueueSnackbar} = useSnackbar();
    const [error, setError] = useState([]);

    const handleSubmit = async (form) => {
        form.preventDefault();
        form.persist();
        await props.dispatch(SetSiteLoading(true));
        const inputs = form.target.querySelectorAll('input');
        let values = {};
        for (let i = 0; i < inputs.length; i++) {
            values[inputs[i].name] = {};
            values[inputs[i].name].importance = inputs[i].getAttribute('custom-importance') === "true";
            values[inputs[i].name].value = inputs[i].value;
        }
        if (Object.values(values).filter(val => val.value === "" && val.importance).length > 0) {
            setError(Object.keys(values).filter(val => values[val].value === "" && values[val].importance))
        }
        else{
            await Axios.post(`${baseUrl}/categories`,
                {
                    name : values.name.value,
                    slug : values.slug.value
                },
                {
                    headers: {
                        Authorization : apiToken,
                        "Content-Type": "application/json"
                    }
                })
                .then(async res => {
                    if (res.status === 200) {
                        if(onCreate)
                            onCreate(res.data.data);
                        sendNotification(enqueueSnackbar, "دسته بندی شما با موفقیت اضافه شد")
                        onClose();
                    }
                })
                .catch(res => {
                    if (res.response && res.response.status === 400) {
                        sendErrorNotification(enqueueSnackbar, res.response.data.message);
                    } else {
                        sendErrorNotification(enqueueSnackbar);
                    }
                })
        }
        await props.dispatch(SetSiteLoading(false));
    };

    const inputHandler = (input) => {
        if (input.target.value === "")
            setError([error, input.target.name]);
        else if (error.indexOf(input.target.name) > -1 && input.target.value !== "")
            setError(error.filter(x => x !== input.target.name))
    }

    return (
        <Dialog
            {...props}
            onClose={onClose}
        >
            <DialogTitle>افزودن دسته بندی</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        error={error.indexOf("name") > -1}
                        helperText={error.indexOf("name") > -1 ? "لطفا یک عنوان انتخاب کنید" : ""}
                        name="name"
                        label="عنوان"
                        variant="outlined"
                        className="w-100 my-3"
                        onChange={inputHandler}
                        inputProps={{"custom-importance": "true"}}
                    />
                    <TextField
                        name="slug"
                        label="نامک"
                        variant="outlined"
                        className="w-100 my-3"
                        onChange={inputHandler}
                        inputProps={{"custom-importance": "false"}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onClose}
                    >
                        انصراف
                    </Button>
                    <Button
                        className="mx-2"
                        variant="outlined"
                        color="primary"
                        type="submit"
                    >
                        ایجاد
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

CreateDialog.propTypes = {
    dispatch : PropTypes.func,
    onCreate : PropTypes.func,
    onClose : PropTypes.func.isRequired,
};

const mapState = ()=> ({});
export default connect(mapState)(CreateDialog);