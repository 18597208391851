import React, {forwardRef, useImperativeHandle, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PlaylistAddRoundedIcon from "@material-ui/icons/PlaylistAddRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import {sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Inputs = (props, ref) => {
    const {enqueueSnackbar} = useSnackbar();

    const [openDialog, setOpenDialog] = useState(false);
    const [name, setName] = useState("");
    const [relationType, setRelationType] = useState(1);
    const [relationTypeData, setRelationTypeData] = useState([]);
    const [error, setError] = useState([]);
    let nameForTarget = "";

    const setRelationTypeDataList = props.setRelationTypeData;

    const createRelationType = ()=> {
        Axios.post(
            baseUrl + "/relationTypes",
            {
                title : nameForTarget
            },
            {headers : {Authorization : apiToken}}
        )
            .then(res => {
                if(res.status === 200){
                    const data = [...relationTypeData, res.data.data];
                    setRelationTypeData(data);
                    setRelationTypeDataList(data);
                    setOpenDialog(false);
                    sendNotification(enqueueSnackbar, "نوع ارتباط شما با موفقیت اضافه شد");
                }
            })
    };

    useImperativeHandle(ref, () => ({
        name,
        relationType,
        setName,
        setRelationType,
        setRelationTypeData,
        setError
    }), [name, relationType]);

    const inputHandler = async (input) => {
        const {value, name} = input.target;

        if(name === "name")
            setName(value);
        else if(name === "relationType")
            setRelationType(value);

        if (value === "")
            await setError([...error, name]);
        else if (error.indexOf(name) > -1 && value !== "")
            await setError(error.filter(x => x !== name))
    };

    function handleChangeDialog() {
        setOpenDialog(!openDialog)
    }

    return (
        <>
            <TextField
                error={error.indexOf("name") > -1}
                helperText={error.indexOf("name") > -1 ? "لطفا یک عنوان انتخاب کنید" : ""}
                name="name"
                label="عنوان"
                variant="outlined"
                className="w-100 my-3"
                onChange={inputHandler}
                value={name}
                inputProps={{"custom-importance": "true"}}
            />
            <div
                style={{
                    position:"relative"
                }}
            >
                <TextField
                    select
                    className="w-100 my-3"
                    variant="outlined"
                    label="نوع ارتباط"
                    name="relationType"
                    value={relationType}
                    onChange={inputHandler}
                >
                    {
                        relationTypeData.map(data => (
                            <MenuItem
                                key={data.relationTypeId}
                                value={data.relationTypeId}
                                className="py-2"
                            >
                                {data.title}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <PlaylistAddRoundedIcon
                    className="position-absolute cursor-pointer"
                    style={{
                        top: "2.2rem",
                        left: "2.7rem",
                        width: "1.5rem",
                        height: "1.5rem",
                    }}
                    onClick={handleChangeDialog}
                />
                <Dialog
                    keepMounted
                    open={openDialog}
                    TransitionComponent={Transition}
                    PaperProps={{style : {minWidth : 320}}}
                    onClose={handleChangeDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">اضافه کردن نوع ارتباط</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label="عنوان"
                            type="text"
                            className="my-2 w-100"
                            onChange={(e) =>
                                nameForTarget = e.target.value
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleChangeDialog}
                            color="primary"
                        >
                            لغو
                        </Button>
                        <Button
                            onClick={createRelationType}
                            color="primary"
                        >
                            تایید
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default forwardRef(Inputs);