import React, {useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {getUrl} from "../../../functions";
import ListItemText from "@material-ui/core/ListItemText";
import {convertDateZoneToCurrentZone} from "../../../timeFunctions";
import TextSmsRoundedIcon from "@material-ui/icons/TextsmsRounded";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";


const RepliedComment = ({data, onReplay, onDelete, haveQuality}) => {
    const [open, setOpen] = useState(false);
    const [dataCom, setData] = useState([]);

    useEffect(() => {
        setData(data)
        // eslint-disable-next-line
    }, []);

    const handleHover = leave => element => {
        const {currentTarget} = element;
        const parentElement = currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(".controls-btn");
        if(!leave){
            if(parentElement)
                parentElement.classList.remove("d-flex");
            currentTarget.querySelector(".replay-controls-btn").classList.add("d-flex");
        }
        else{
            currentTarget.querySelector(".replay-controls-btn").classList.remove("d-flex");
        }
    };

    const RepliedCommentLoop = (dt) => {
        return dt.map((d, index) =>
            <React.Fragment key={index}>
                <ListItem
                    className="
                    d-flex
                    flex-column
                    align-items-start
                    position-relative
                    replay-img-gallery
                    pl-0
                    pr-4
                "
                    onMouseEnter={handleHover(false)}
                    onMouseLeave={handleHover(true)}
                >
                    <div
                        className="replay-controls-btn position-absolute"
                    >
                        {
                            haveQuality(d.from) && (
                                <IconButton
                                    size="small"
                                    onClick={() => onDelete(d)}
                                >
                                    <DeleteForeverRoundedIcon/>
                                </IconButton>
                            )
                        }
                        <IconButton
                            size="small"
                            onClick={() => onReplay(d)}
                        >
                            <ReplyRoundedIcon/>
                        </IconButton>
                    </div>
                    <div className="d-flex ">
                        <ListItemAvatar>
                            <Avatar
                                alt=""
                                src={d.avatarId && getUrl(d.avatarId)}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={d.userFullName}
                            secondary={convertDateZoneToCurrentZone(d.createdDate, "Asia/Tehran").fromNow()}
                        />
                    </div>
                    <ListItemText
                        primary={d.content}
                    />
                </ListItem>
                {
                    d.repliedComment &&
                    d.repliedComment.length > 0 &&
                    RepliedCommentLoop(d.repliedComment)
                }
            </React.Fragment>
        )
    };

    return (
        <>
            <div className="d-flex justify-content-end w-100">
                <IconButton
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    <TextSmsRoundedIcon/>
                </IconButton>
            </div>
            <Collapse
                in={open}
                timeout="auto"
                className="w-100"
            >
                <List
                    component="div"
                    disablePadding
                >
                    {
                        RepliedCommentLoop(dataCom)
                    }
                </List>
            </Collapse>
        </>
    )
};

export default RepliedComment;