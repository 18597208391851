import React, {useEffect} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CategoryList from "./CategoryList/CategoryList";
import CategoryEditor from "./CategoryEditor/CategoryEditor";
import Axios from "axios";
import {apiToken, baseUrl} from "../../appConfig";

let categoryListRef;
let categoryEditorRef;
const Categories = () => {
    useEffect(()=> {
        if(categoryEditorRef.inputsRef)
            Axios.get(baseUrl + "/terms?returnValue=10000", {headers : {Authorization : apiToken}})
                .then(res => {
                    if(res.status === 200){
                        categoryEditorRef.inputsRef.setTermData(res.data.data);
                        categoryListRef.setTermData(res.data.data);
                    }
                })
        // eslint-disable-next-line
    }, [categoryEditorRef]);

    useEffect(()=> {
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    const onSubmit = (viewModel, createMode = false) => {
        const {tableData, setTableData} = categoryListRef;
        if(createMode){
            const newValues = [{
                id: viewModel.categoryId,
                data: [viewModel.name, viewModel.slug, viewModel.termName]
            }, ...tableData.values];
            setTableData({
                values : newValues,
                countAll : tableData.countAll + 1
            });
        }
        else{
            const currentData = tableData.values.filter(val => val.id === viewModel.categoryId)[0];
            if(currentData){
                const index = tableData.values.indexOf(currentData);
                if(index > -1){
                    tableData.values[index] = {id : viewModel.categoryId, data : [viewModel.name, viewModel.slug, viewModel.termName]};
                    setTableData(tableData);
                }
            }
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} lg={4}>
                <CategoryEditor ref={ref => {categoryEditorRef = ref}} onSubmit={onSubmit} />
            </GridItem>
            <GridItem xs={12} lg={8}>
                <CategoryList ref={ref => {categoryListRef = ref}} />
            </GridItem>
        </GridContainer>
    );
};

export default Categories;