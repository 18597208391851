import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
//css
import './InformationTable.css';
import 'leaflet/dist/leaflet.css';
//icons
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import HelpIcon from '@material-ui/icons/HelpOutline';
//components
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";
import {connect} from "react-redux";
import {Button, Grid, IconButton, TextField} from "@material-ui/core";
import axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import Divider from "@material-ui/core/Divider";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

const InformationItem = (
    {
        index,
        data,
        xs,
        sm,
        lg,
        removeItem,
        setDataPost,
        defaultValue,
        onClickAddField,
        selectMode,
        onChangeValueForParent
    }
) => {
    let [params, setParams] = useState([]);
    const [value, setValue] = useState();
    let [deletedIDs, setDeletedIDs] = useState([]);
    let defaultIds = [data.clientId + '0', data.clientId + '1', data.clientId + '2', data.clientId + '3', data.clientId + '4', data.clientId + '5', data.clientId + '6', data.clientId + '7', data.clientId + '8', data.clientId + '9'];

    useEffect(() => {
        if (selectMode) {
            setValue({value: data.categoryId, label: data.categoryId})
        } else {
            setValue(data.value)
        }
        setParams(data.params);
        if (data.params.length > 0) {
            let numbers;
            data.params.map((param, i) => {
                if (i === 0) {
                    numbers = defaultIds.filter(x => x !== param.clientId)
                } else {
                    numbers = numbers.filter(x => x !== param.clientId)
                }
                if (i === data.params.length - 1) {
                    setDeletedIDs(numbers)
                }
            })
        } else {
            setDeletedIDs(defaultIds)
        }
        // eslint-disable-next-line
    }, []);

    const promiseOptions = (inputValue = "") => {
        return new Promise(resolve => {
            axios.get(baseUrl + `/categories/search?query=${inputValue}&termId=3&returnValue=12`, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data.data);
                    }
                })
        });
    };

    const onChangeIn = (key) => {
        const keyIndex = params.findIndex(x => x.clientId === key.clientId.slice(0, -1));
        const xIndex = params[keyIndex].params.findIndex(x => x.clientId === key.clientId);
        params[keyIndex].params[xIndex] = key;
        onChangeValueForParent(params[keyIndex]);
    };

    const onInputChangeIn = (index, value) => {
        params[index].value = value;
        setParams([...params]);
        onChangeValueForParent(params[index]);
    };

    const removeItemIn = async (id, newParam, isParent) => {
        if (!isParent) {
            await setDeletedIDs([...deletedIDs, id]);
            const newData = await params.filter(x => x.clientId !== id);
            await setParams([]);
            await setParams(newData);
            removeItem(data.clientId, newData, true);
        } else {
            const xIndex = params.findIndex(x => x.clientId === id);
            params[xIndex].params = newParam;
            removeItem(data.clientId, params, true);
        }
    };

    const onClickAddFieldIn = (key, isParent) => {
        if (isParent) {
            const keyIndex = params.findIndex(x => x.clientId === key.clientId.slice(0, -1));
            const index = params[keyIndex].params.findIndex(x => x.clientId === key.clientId);
            params[keyIndex].params[index] = key;
            onClickAddField(params[keyIndex], true);
        } else {
            const index = params.findIndex(x => x.clientId === key.clientId.slice(0, -1));
            params[index].params = [...params[index].params, key];
            onClickAddField(params[index], true);
        }
    };

    return (
        <Grid
            key={index}
            className="d-flex flex-wrap align-items-center"
            style={{width: "34rem"}}
        >
            <div
                style={{width: "17rem"}}
                className="text-field-parent position-relative px-2"
            >
                <div className="position-relative d-flex align-items-center">
                    {
                        selectMode
                            ?
                            <AsyncSelect
                                isRtl
                                cacheOptions
                                defaultOptions
                                key={index}
                                className="w-100 my-2"
                                value={value}
                                placeholder="انتخاب کنید ..."
                                noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                                loadingMessage={() => "در حال بارگزاری .."}
                                styles={{menu: base => ({...base, zIndex: 555555})}}
                                loadOptions={inputValue =>
                                    promiseOptions(inputValue)
                                }
                                onChange={e => {
                                    setDataPost(index, e.value);
                                    setValue(e)
                                }}
                            />
                            :
                            <TextField
                                key={index}
                                variant="outlined"
                                size="small"
                                value={value !== "" ? value : defaultValue}
                                type="text"
                                className="my-2 text-single-information"
                                onChange={(e) => {
                                    setDataPost(index, e.target.value);
                                    setValue(e.target.value)
                                }}
                            />
                    }
                    <IconButton
                        onClick={() => {
                            removeItem(data.clientId, null, false);
                        }}
                        className="icon-button-mini"
                    >
                        <CloseRoundedIcon/>
                    </IconButton>
                </div>
            </div>
            <div
                className="d-flex flex-column align-items-start "
                style={{width: "17rem"}}
            >
                {
                    params &&
                    params.length > 0 &&
                    params.map((detail, i) =>
                        detail &&
                        <InformationItem
                            xs={8}
                            sm={8}
                            lg={8}
                            data={detail}
                            key={i}
                            index={i}
                            defaultValue={detail.value}
                            setDataPost={onInputChangeIn}
                            removeItem={removeItemIn}
                            onClickAddField={onClickAddFieldIn}
                            onChangeValueForParent={onChangeIn}
                        />
                    )
                }
                {
                    params && params.length < 10 && (value && (value.length > 0 || (value.label && value.label.length > 0))) ?
                        <Button
                            variant="contained"
                            color="secondary"
                            aria-label="add an alarm"
                            className="w-25 mx-2"
                            onClick={() => {
                                if (deletedIDs.length > 0) {
                                    setParams([
                                        ...params,
                                        {
                                            ...{
                                                clientId: deletedIDs[0],
                                                value: "",
                                                params: []
                                            }
                                        }
                                    ]);
                                    onClickAddField(
                                        {
                                            clientId: deletedIDs[0],
                                            value: "",
                                            params: []
                                        }
                                    );
                                    setDeletedIDs(deletedIDs.filter(x => x !== deletedIDs[0]))
                                }
                            }}
                        >
                            <AddRoundedIcon/>
                        </Button>
                        :
                        (value && (value.length > 0 || (value.label && value.label.length > 0))) && data.clientId.length >= 2 &&
                        <Divider className='w-100 my-2 bg-dark'/>
                }
            </div>
            {
                selectMode &&
                <Divider className='w-100 my-2'/>
            }
        </Grid>
    )
};

const InformationTable = ({onChange, defaultValue}) => {
    let [data, setData] = useState([]);

    useEffect(() => {
        if (defaultValue && defaultValue.length > 0) {
            setData(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    const checkCategoryId = (inf) => {
        return inf.filter(x => x.categoryId !== null)
    };

    const onInputChange = (key) => {
        const keyIndex = data.findIndex(x => x.clientId === key.clientId.slice(0, -1));
        const xIndex = data[keyIndex].params.findIndex(x => x.clientId === key.clientId);
        data[keyIndex].params[xIndex] = key;
        setData([...data]);
        onChange(checkCategoryId(data));
    };

    const addFieldInformation = (key, isParent) => {
        const keyIndex = data.findIndex(x => x.clientId === key.clientId.slice(0, -1));
        if (isParent) {
            const index = data[keyIndex].params.findIndex(x => x.clientId === key.clientId);
            data[keyIndex].params[index] = key;
        } else {
            data[keyIndex].params = [...data[keyIndex].params, key];
        }
        setData([...data]);
        onChange(checkCategoryId(data));
    };

    const removeItem = async (id, newParam, isParent) => {
        if (!isParent) {
            const newData = await data.filter(x => x.clientId.toString() !== id);
            await setData([]);
            await setData([...newData]);
            await onChange(checkCategoryId([...newData]));
        } else {
            data[id].params = newParam;
            await onChange(checkCategoryId(data));
        }
    };

    return (
        <Card style={{minHeight: '20rem'}}>
            <CardHeader stats icon>
                <CardIcon color="success">
                    <SettingsApplicationsRoundedIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3
                        className="p-3"
                        style={{color: "#000"}}
                    >
                        اطلاعات
                    </h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    در این قسمت بخش های مختلف یک سوژه ی حقوقی به صورت درختی و شاخه ای پر می شود.
                                </span>
                                <span>
                                    برای اضافه کردن شاخه از دکمه ی مثبت زیر استفاده کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardBody
                className="cardInformation"
            >
                {
                    data && data.map((d, index) =>
                        <InformationItem
                            xs={12}
                            sm={6}
                            lg={3}
                            id={d.id}
                            data={d}
                            key={index}
                            index={index}
                            selectMode
                            defaultValue={d.categoryId}
                            setDataPost={(num, value) => {
                                data[num] = {
                                    ...data[num],
                                    categoryId: value
                                };
                                setData([...data]);
                                onChange(checkCategoryId(data));
                            }}
                            isParent
                            removeItem={removeItem}
                            onChange={(data) => {
                                setData([...data]);
                                onChange(data)
                            }}
                            onClickAddField={addFieldInformation}
                            onChangeValueForParent={onInputChange}
                        />
                    )
                }
                <GridItem sm={3}>
                    <IconButton
                        color="primary"
                        aria-label="add an alarm"
                        onClick={() => {
                            data = [
                                ...data,
                                {
                                    categoryId: null,
                                    clientId: `${data.length}`,
                                    params: []
                                }
                            ];
                            setData([...data]);
                        }}
                    >
                        <AddRoundedIcon/>
                    </IconButton>
                </GridItem>
            </CardBody>
        </Card>
    );
};

InformationTable.propTypes = {
    onChange: PropTypes.func
};

const mapState = () => ({});
export default connect(mapState)(InformationTable);