import React, {useState, useEffect} from 'react';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody";
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {SetMediaDrawer, SetSiteLoading} from "../../redux/actions/actions";
import {connect} from "react-redux";
import {getUrl} from "../../functions";
import Table from "../Table/Table";
import {apiToken, baseUrl} from "../../appConfig";
import Axios from "axios";

const VideoUploader = ({onAddVideo, defaultData, onDelete, ...props}) => {
    const [chosenPicture, setChosenPicture] = useState(null);
    const [file, setFile] = useState(null);
    const [dataTable, setData] = useState([]);
    const [value, setValue] = useState('');
    const [selectedVideo, setSelectedVideo] = useState("list");

    useEffect(() => {
        const values = defaultData.map((data, i) => ({
            id: i,
            data: [data.thumb, data.title]
        }));
        setData(values);
    }, [defaultData]);

    const UploadFile = (fileUp) => {
        props.dispatch(SetSiteLoading(true));
        const formData = new FormData();
        formData.append('file', fileUp);
        Axios.post(baseUrl + "/FileManagers", formData, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    setFile(res.data.data[0].fileManagerId)
                }
                props.dispatch(SetSiteLoading(false));
            })
            .catch(() =>
                props.dispatch(SetSiteLoading(false))
            )
    };

    return (
        <Card>
            <CardHeader stats icon>
                <CardIcon
                    color="orange"
                    className={"cursor-pointer default-graph " + `${selectedVideo === "list" && "active-graph"}`}
                    onClick={() => setSelectedVideo("list")}
                >
                    <VideocamIcon/>
                </CardIcon>
                <CardIcon
                    color="orange"
                    className={"cursor-pointer default-graph " + `${selectedVideo === "add" && "active-graph"}`}
                    onClick={() => setSelectedVideo("add")}
                >
                    <VideoCallIcon/>
                </CardIcon>
            </CardHeader>
            <CardBody>
                {
                    selectedVideo === "add" ?
                        <div>
                            <TextField
                                variant="outlined"
                                value={value}
                                label="عنوان"
                                type='text'
                                className="my-2 text-single-information"
                                onChange={(e) => {
                                    setValue(e.target.value)
                                }}
                            />
                            <div className='dropzone'>
                                <input
                                    type='file'
                                    accept='video/*'
                                    onChange={(e) => {
                                        // setFile(`${e.target.files[0]}`);
                                        UploadFile(e.target.files[0])
                                    }}
                                />
                            </div>
                            <div className="d-flex flex-column mt-3">
                                <Fab
                                    variant="extended"
                                    color="secondary"
                                    onClick={() => props.dispatch(SetMediaDrawer({
                                        type: "image",
                                        value: chosenPicture,
                                        action: value => {
                                            setChosenPicture(value);
                                        }
                                    }))}
                                    style={{borderRadius: '.3rem'}}
                                >
                                    <span>انتخاب تصویر برای ویدیو</span>
                                </Fab>
                                {
                                    chosenPicture &&
                                    (
                                        <div
                                            className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light">
                                            <img
                                                className="h-auto"
                                                style={{maxWidth: "100%", maxHeight: "20rem"}}
                                                src={getUrl(chosenPicture, 500)}
                                                alt="feature"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <Fab
                                variant="extended"
                                color="secondary"
                                onClick={() => {
                                    onAddVideo(
                                        {
                                            title: value,
                                            thumb: chosenPicture,
                                            fileManagerId: file
                                        }
                                    );
                                    setSelectedVideo("list");
                                    setFile(null);
                                    setChosenPicture(null);
                                    setValue('');
                                    setData(
                                        [
                                            ...dataTable,
                                            {
                                                id: dataTable.length,
                                                data: [chosenPicture, value]
                                            }
                                        ]
                                    )
                                }}
                                className="w-100 mt-3"
                                style={{borderRadius: '.3rem', backgroundColor: '#ff6d57'}}
                            >
                                <span>اضافه کردن</span>
                            </Fab>
                        </div>
                        :
                        <Table
                            onPaging={(event, value) => 'getTargets(value,targetType)'}
                            limitViews={12}
                            tableHeaderColor="warning"
                            tableHead={["آواتار", "نام"]}
                            tableData={dataTable || []}
                            totalAmount={10}
                            actions={{
                                // onRead: (key, ID) => {
                                //     // navigate("/admin/targets/study/" + ID);
                                // },
                                onDelete: async (key, ID) => {
                                    setData([...dataTable.filter(val => val.id !== ID)]);
                                    onDelete(ID)
                                },
                                // onEdit: isSubscriber ? null : (key, ID) => {
                                //     navigate("/admin/targets/editor/" + ID);
                                // }
                            }}
                        />
                }
            </CardBody>
        </Card>
    );
};

VideoUploader.propTypes = {
    onAddVideo: PropTypes.func
};

export default connect()(VideoUploader);