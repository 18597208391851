import React from 'react';
import './IdentityItem.css'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const IdentityItem = ({title, value}) => {
    return (
        <div className="IdentityItem p-3 d-flex justify-content-between align-items-center mb-3 mx-2">
            <Typography className="my-2" variant="h6">{title} :  </Typography>
            <Chip
                style={{
                    background: "linear-gradient(60deg, #66bb6a, #43a047)",
                    borderRadius: ".3rem",
                    fontSize: "1.2rem"
                }}
                color="secondary"
                className="mr-3 chip-default-study py-1"
                label={value}
            />
        </div>
    );
};

export default IdentityItem;