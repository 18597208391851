// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AssessmentRoundedIcon from "@material-ui/icons/AssessmentRounded";
import SupervisorAccountSharpIcon from '@material-ui/icons/SupervisorAccountSharp';
import BubbleChartRoundedIcon from '@material-ui/icons/BubbleChartRounded';
import PermMediaRoundedIcon from '@material-ui/icons/PermMediaRounded';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard";
import Login from "./views/Login/Login";
import ProjectsList from "./views/Projects/ProjectsList";
import ProjectsEditor from "./views/Projects/ProjectsEditor/ProjectsEditor";
import TrackChangesRoundedIcon from "@material-ui/icons/TrackChangesRounded";
import Study from "./views/Study/Study";
import CategoryRoundedIcon from "@material-ui/icons/CategoryRounded";
import Categories from "./views/Categories/Categories";
import AccountTreeRoundedIcon from "@material-ui/icons/AccountTreeRounded";
import Relations from "./views/Relations/Relations";
import TargetsList from "./views/Targets/TargetsList";
import TargetsEditor from "./views/Targets/TargetsEditor";
import Accessibility from "./views/Accessibility/Accessibility";
import ImageGallery from "./views/ImageGallery/ImageGallery";
import RelationPeople from "./views/RelationPeople/RelationPeople";
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AccessibilityEditor from "./views/Accessibility/Editor/AccessibilityEditor";
import OtherSections from "./views/OtherSections/OtherSections";
import Multimedia from "./views/Multimedia/Multimedia";

const dashboardRoutes = [
    {
        path: "/",
        name: "Login",
        rtlName: "ورود",
        icon: ExitToApp,
        component: Login,
        layout: "/login",
        hideInSidebar: true,
        accessRole: null,
        exact: true
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        rtlName: "پیشخوان",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        accessRole: 2,
        exact: true
    },
    {
        path: "/targets/",
        name: "targets",
        rtlName: "سوژه ها",
        icon: TrackChangesIcon,
        component: TargetsList,
        layout: "/admin",
        accessRole: 2,
        exact: true
    },
    {
        path: "/projects/",
        name: "projects",
        rtlName: "پروژه ها",
        icon: AssessmentRoundedIcon,
        component: ProjectsList,
        layout: "/admin",
        accessRole: 2,
        exact: true
    },
    {
        path: "/gallery",
        name: "Gallery",
        rtlName: "آرشیو عکس",
        icon: PermMediaRoundedIcon,
        component: ImageGallery,
        layout: "/admin",
        accessRole: 2,
        exact: true
    },
    {
        path: "/multimedia/",
        name: "Multimedia",
        rtlName: "چندرسانه ای",
        icon: PermMediaRoundedIcon,
        component: Multimedia,
        layout: "/admin",
        accessRole: 1,
        exact: false
    },
    {
        path: "/projects/editor",
        name: "Create project",
        rtlName: "افزودن پروژه",
        icon: AssessmentRoundedIcon,
        component: ProjectsEditor,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 1,
        exact: true
    },
    {
        path: "/projects/editor/:projectId",
        name: "Edit project",
        rtlName: "ویرایش پروژه",
        icon: AssessmentRoundedIcon,
        component: ProjectsEditor,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 1,
        exact: true
    },
    {
        path: "/projects/study/:projectId",
        name: "Study",
        rtlName: "مطالعه",
        icon: TrackChangesRoundedIcon,
        component: Study,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 2,
        exact: true
    },
    {
        path: "/targets/study/:targetId",
        name: "Study",
        rtlName: "مطالعه",
        icon: TrackChangesRoundedIcon,
        component: Study,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 2,
        exact: true
    },
    {
        path: "/categories",
        name: "Categories",
        rtlName: "دسته بندی ها",
        icon: CategoryRoundedIcon,
        component: Categories,
        layout: "/admin",
        accessRole: 1,
        exact: true
    },
    {
        path: "/categories/:categoryId",
        name: "Edit category",
        rtlName: "ویرایش دسته بندی",
        icon: CategoryRoundedIcon,
        component: Categories,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 1,
        exact: true
    },
    {
        path: "/relations",
        name: "Relations",
        rtlName: "ارتباط ها",
        icon: AccountTreeRoundedIcon,
        component: Relations,
        layout: "/admin",
        accessRole: 1,
        exact: true
    },
    {
        path: "/relations/:relationId",
        name: "Edit relation",
        rtlName: "ویرایش ارتباط",
        icon: AccountTreeRoundedIcon,
        component: Relations,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 1,
        exact: true
    },
    {
        path: "/targets/editor",
        name: "Create target",
        rtlName: "افزودن هدف",
        icon: TrackChangesRoundedIcon,
        component: TargetsEditor,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 1,
        exact: true
    },
    {
        path: "/targets/editor/:targetId",
        name: "Edit target",
        rtlName: "ویرایش هدف",
        icon: TrackChangesRoundedIcon,
        component: TargetsEditor,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 1,
        exact: true
    },
    {
        path: "/people-relation",
        name: "People Relations",
        rtlName: "گراف ارتباط",
        icon: BubbleChartRoundedIcon,
        component: RelationPeople,
        layout: "/admin",
        hideInSidebar: false,
        accessRole: 2,
        exact: true
    },
    {
        path: "/accessibility",
        name: "Accessibility",
        rtlName: "دسترسی ها",
        icon: SupervisorAccountSharpIcon,
        component: Accessibility,
        layout: "/admin",
        hideInSidebar: false,
        accessRole: 0,
        exact: true
    },
    {
        path: "/accessibility/editor/:userId",
        name: "Accessibility Editor",
        rtlName: "ویرایش دسترسی ها",
        icon: SupervisorAccountSharpIcon,
        component: AccessibilityEditor,
        layout: "/admin",
        hideInSidebar: true,
        accessRole: 0,
        exact: true
    },
    {
        path: "/other/",
        name: "Other sections",
        rtlName: "دیگر بخش ها",
        icon: DevicesOtherIcon,
        component: OtherSections,
        layout: "/admin",
        hideInSidebar: false,
        accessRole: 1,
        exact: true
    }
];

export default dashboardRoutes;
