import * as React from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "../CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import back from "../../assets/img/electromagnetic_spectrum_17180.jpg";
import GridItem from "../Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Axios from "axios";
import {apiToken} from "../../appConfig";
import {baseUrl} from "../../appConfig";
import {sendNotification} from "../../functions";
import {useSnackbar} from "notistack";
import {useState} from "react";
import Slide from "@material-ui/core/Slide";
import {connect} from "react-redux";
import {SetUserDialog} from "../../redux/actions/actions";
import {makeStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
    appBar: {
        position: 'relative',
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddUserDialog({onAdd, ...props}) {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = React.useState({
        userName: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        role: 2,
    });

    const handleChangeInput = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickClose = () => {
        props.dispatch(SetUserDialog(null))
    };

    const addUser = () => {
        Axios.post(baseUrl + `/users/add`, values, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "کاربر مورد نظر اضافه شد");
                    setValues({
                        userName: '',
                        firstName: '',
                        lastName: '',
                        password: '',
                        confirmPassword: '',
                        role: 2,
                    });
                    if(typeof props.UserDialogHandler === "function")
                        props.UserDialogHandler(res.data.data);
                }
            })
            .catch(err => {
                    if (err.response && err.response.status === 400) {
                        sendNotification(enqueueSnackbar, err.response.data.message, "error");
                    }
                }
            )
    };

    return (
        <Dialog
            fullScreen
            open={props.UserDialogHandler !== null}
            onClose={handleClickClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar
                    className="d-flex justify-content-between"
                    style={{background: 'linear-gradient(-60deg, #051349, #197bce)'}}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClickClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                    >
                        اضافه کردن کاربر
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid
                className="h-100"
                style={{background: `url(${back})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
            >
                <GridItem
                    sm={3}
                    style={{
                        boxShadow: "0 1rem 10px #0000009a",
                        height: "100%",
                        background: "#ffffffab",
                    }}
                >
                    <div
                        className="d-flex flex-column justify-content-center h-100 px-4"
                    >
                        <FormControl
                            variant="standard"
                        >
                            <TextField
                                value={values.firstName}
                                variant="standard"
                                className="mb-4"
                                label="نام"
                                onChange={handleChangeInput("firstName")}
                            />
                            <TextField
                                value={values.lastName}
                                variant="standard"
                                className="mb-4"
                                label="نام خانوادگی"
                                onChange={handleChangeInput("lastName")}
                            />
                            <TextField
                                value={values.userName}
                                variant="standard"
                                className="mb-4"
                                label="نام کاربری"
                                onChange={handleChangeInput("userName")}
                            />
                        </FormControl>
                        <FormControl className="mb-3">
                            <InputLabel id="role-selector">نقش</InputLabel>
                            <Select
                                labelId="role-selector"
                                value={values.role}
                                onChange={handleChangeInput("role")}
                            >
                                <MenuItem value={0}>مدیر</MenuItem>
                                <MenuItem value={1}>نویسنده</MenuItem>
                                <MenuItem value={2}>مشترک</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            className="mb-4"
                        >
                            <InputLabel
                                className="px-2"
                                htmlFor="outlined-adornment-password"
                            >
                                رمز عبور
                            </InputLabel>
                            <Input
                                value={values.password}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChangeInput('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl
                            className="mb-5"
                        >
                            <InputLabel
                                className="px-2"
                                htmlFor="outlined-adornment-confirmPassword"
                            >
                                تکرار رمز عبور
                            </InputLabel>
                            <Input
                                value={values.confirmPassword}
                                id="outlined-adornment-confirmPassword"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChangeInput('confirmPassword')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button
                            variant="contained"
                            color="rose"
                            onClick={() => addUser()}
                        >
                            ثبت
                        </Button>
                    </div>
                </GridItem>
                <GridItem sm={9}> </GridItem>
            </Grid>
        </Dialog>
    );
}

const mapState = states => ({
    UserDialogHandler: states.userDialogHandler
});

export default connect(mapState)(AddUserDialog);