import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Table from "../../../components/Table/Table";
import {useNavigate} from "react-router-dom";
import {SetSiteLoading} from "../../../redux/actions/actions";
import Axios from "axios";
import {baseUrl} from "../../../appConfig";
import {apiToken} from "../../../appConfig";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import Select from "react-select";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};
const useStyles = makeStyles(styles);
const RelationList = ({reference, ...props}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [tableData, setTableData] = useState({});
    const [relationTypeData, setRelationTypeData] = useState([]);

    useImperativeHandle(reference, () => ({
        setRelationTypeData,
        setTableData,
        tableData
    }), [tableData]);

    const getRelations = async (index = 1, typeId = null) => {
        await props.dispatch(SetSiteLoading(true));
        await Axios.get(baseUrl + "/Relations?" + (typeId !== null ? ("typeId=" + typeId + "&") : "") + "pageIndex=" + index, {headers: {Authorization: apiToken}})
            .then(res => {
                const values = res.data.data.map(data => ({
                    id: data.relationId,
                    data: [data.name, data.typeName]
                }));
                setTableData({
                    values,
                    countAll : res.data.countAll
                });
            });
        await props.dispatch(SetSiteLoading(false));
    };

    const removeRelation = async ID => {
        await props.dispatch(SetSiteLoading(true));
        await Axios.post(baseUrl + "/relations/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    setTableData({
                        values : tableData.values.filter(val => val.id !== ID),
                        countAll: tableData.countAll - 1
                    });
                    sendNotification(enqueueSnackbar, "ارتباط با موفقیت حذف شد");
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
            });
        await props.dispatch(SetSiteLoading(false));
    };

    useEffect(() => {
        getRelations().then();
        // eslint-disable-next-line
    }, []);

    return (
        <Card>
            <CardHeader className="d-flex justify-content-between align-items-center" color="info">
                <div>
                    <h4 className={classes.cardTitleWhite}>جدول ارتباط ها</h4>
                    <p className="m-0">جدولی از ارتباط های ثبت شده به ترتیب زمان ثبت شدن</p>
                </div>
                <div className="d-flex align-items-center">
                    <Select
                        isRtl
                        cacheOptions
                        defaultOptions
                        placeholder="فیلتر نوع ارتباط"
                        noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                        loadingMessage={() => "در حال بارگزاری .."}
                        styles={{
                            menu: base => ({...base, zIndex: 555555, color: "#000"}),
                            container: base => ({...base, width: "15rem", marginLeft: "1rem"})
                        }}
                        options={
                            [
                                {
                                    value: null,
                                    label: "همه"
                                },
                                ...relationTypeData.map(data => ({
                                    value: data.relationTypeId,
                                    label: data.title
                                }))
                            ]
                        }
                        onChange={async res => {
                            await getRelations(1, res.value)
                        }}
                    />
                    {/*<AsyncSelect*/}
                    {/*    isRtl*/}
                    {/*    cacheOptions*/}
                    {/*    defaultOptions*/}
                    {/*    isClearable*/}
                    {/*    value={value}*/}
                    {/*    placeholder="جستجوی سوژه..."*/}
                    {/*    noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}*/}
                    {/*    loadingMessage={() => "در حال بارگزاری .."}*/}
                    {/*    styles={{*/}
                    {/*        menu: base => ({...base, zIndex: 555555, color: "#000"}),*/}
                    {/*        container: base => ({...base, width: "15rem", marginLeft: "1rem"})*/}
                    {/*    }}*/}
                    {/*    loadOptions={inputValue =>*/}
                    {/*        promiseOptions(inputValue, "targets", 0)*/}
                    {/*    }*/}
                    {/*    onChange={res => {*/}
                    {/*        onValueChange(res);*/}
                    {/*        getSingleTarget(res)*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            </CardHeader>
            <CardBody>
                <Table
                    onPaging={(event, value) => getRelations(value)}
                    limitViews={12}
                    tableHeaderColor="info"
                    tableHead={["نام", "نوع"]}
                    tableData={tableData.values || []}
                    totalAmount={tableData.countAll || 0}
                    actions={{
                        onDelete: async (key, ID) => {
                            await removeRelation(ID)
                        },
                        onEdit: (key, ID) => {
                            navigate("/admin/relations/" + ID);
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

const TheComponent = connect(() => ({}))(RelationList);

export default forwardRef((props, ref) => (
    <TheComponent reference={ref} {...props} />
));