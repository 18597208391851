import React, {useState} from 'react';

// Core component
import {Link, useNavigate} from "react-router-dom";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardFooter from "../../Card/CardFooter";
import Card from "../../Card/Card";
import axios from "axios";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";

// @material-ui/core
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import HelpIcon from '@material-ui/icons/HelpOutline';
import {apiToken, baseUrl} from "../../../appConfig";
import {sendNotification} from "../../../functions";
import {SetMediaDrawer, SetSiteLoading} from "../../../redux/actions/actions";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import Fab from "@material-ui/core/Fab";
import CreateIcon from '@material-ui/icons/Create';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Publish = ({
                     onPublish,
                     onChoose,
                     addPDF,
                     removePDF,
                     pdfs = [],
                     targetType,
                     id,
                     editMode,
                     previousPageAddress = "",
                     ...props
                 }) => {
    const navigate = useNavigate();
    const [openModalAddFolder, setOpenModalAddMedia] = useState(false);
    const [name, setName] = useState('');
    const [chosenPicture, setChosenPicture] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    const addMedia = (file) => {
        props.dispatch(SetSiteLoading(true));
        const formData = new FormData();
        formData.append('file', file);
        axios.post(baseUrl + "/FileManagers", formData, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    props.dispatch(SetSiteLoading(false));
                    sendNotification(enqueueSnackbar, "فایل مورد نظر آپلود شد");
                    setOpenModalAddMedia(false);
                    addPDF({
                        title: name,
                        fileManagerId: res.data.data[0].fileManagerId
                    });
                    setName("");
                }
            })
            .catch(r => {
                props.dispatch(SetSiteLoading(false));
                sendNotification(enqueueSnackbar, r.message, "error");
                setOpenModalAddMedia(false)
            })
    };

    return (
        <Card>
            <CardHeader icon>
                <CardIcon color="danger">
                    <Tooltip title="بازگشت">
                        <IconButton color="inherit" onClick={() => navigate(previousPageAddress)}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Tooltip>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3 m-0" style={{color: "#000"}}>امکانات</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    برای انتشار و یا ذخیره ی تغییرات روی دکمه ی انتشار یا اعمال تغییرات کلیک کنید.
                                </span>
                                <span>
                                    با کلیک بر روی دکمه ی نمایش میتوانید تغییرات اعمال شده را در صفحه ی نمایش مشاهده کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardFooter stats>
                <Box
                    className="w-100"
                    display="flex"
                    justifyContent="flex-end"
                >
                    {
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => setOpenModalAddMedia(true)}
                        >
                            آپلود PDF
                        </Button>
                    }
                    {
                        editMode &&
                        <Link to={previousPageAddress + "/study/" + id} className="mx-2">
                            <Button
                                variant="outlined"
                                color="inherit"
                            >
                                نمایش
                            </Button>
                        </Link>
                    }
                    <Button className={editMode ? "" : "mr-2"} variant="outlined" color="primary" onClick={onPublish}>
                        {editMode ? `اعمال تغییرات` : `انتشار`}
                    </Button>
                </Box>
            </CardFooter>
            <Dialog
                keepMounted
                open={openModalAddFolder}
                onClose={() => setOpenModalAddMedia(false)}
                style={{zIndex: 2002}}
                TransitionComponent={Transition}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">pdf مورد نظر را آپلود کنید.</DialogTitle>
                <div className="p-4 bordered">
                    {
                        pdfs.length > 0 && <span className="text-bold b-">pdf های آپلود شده:</span>
                    }
                    {
                        pdfs.map((pdf, i) =>
                            <div key={i} className="w-100 d-flex justify-content-between">
                                <span>{pdf.title.length > 0 ? pdf.title : "بدون عنوان"}</span>
                                <DeleteForeverRoundedIcon
                                    className="btn-outline-danger cursor-pointer b-circle"
                                    onClick={() => removePDF(pdf)}
                                />
                            </div>
                        )
                    }
                </div>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        className="w-100 my-3"
                        label="ابتدا عنوان pdf را وارد کنید"
                        style={{minWidth: "20rem"}}
                    />
                    {
                        name.length > 0 &&
                        <input
                            type="file"
                            onChange={(e) => addMedia(e.target.files[0])}
                            accept="application/pdf"
                            className="w-100 my-3"
                        />
                    }
                    {
                        name.length > 0 &&
                        <Fab
                            variant="extended"
                            color="secondary"
                            style={{borderRadius: '.3rem'}}
                            className="w-100 mt-3"
                            onClick={() => props.dispatch(SetMediaDrawer({
                                type: "application",
                                action: value => {
                                    addPDF({
                                        title: name,
                                        fileManagerId: value
                                    });
                                    setName("")
                                }
                            }))}
                        >
                            انتخاب از رسانه ها
                        </Fab>
                    }
                </DialogContent>
            </Dialog>
        </Card>
    );
};

Publish.propTypes = {
    editMode: PropTypes.bool,
    onPublish: PropTypes.func.isRequired,
    previousPageAddress: PropTypes.string.isRequired
};

export default connect()(Publish);