import React, {useEffect, useState} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {sendErrorNotification, sendNotification} from "../../functions";
import {makeStyles} from "@material-ui/core";
import {SetSiteLoading} from "../../redux/actions/actions";
import {apiToken} from "../../appConfig";
import {baseUrl} from "../../appConfig";
import Axios from "axios";
import {connect} from "react-redux";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Select from "react-select";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);
const TargetsList = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [tableData, setTableData] = useState({});
    const [value, onValueChange] = useState(null);
    const [targetType, setTargetType] = useState("");

    const isSubscriber = props.UserDetails.role === 2;

    const getTargets = async (index = 1 ,type = "") => {
        await props.dispatch(SetSiteLoading(true));
        await Axios.get(
            baseUrl + "/targets" + (isSubscriber ? "/forsubscriber" : "") + "?pageIndex=" + index + `&targetType=${type}`,
            {headers: {Authorization: apiToken}}
            )
            .then(res => {
                const values = res.data.data.map(data => ({
                    id: data.targetId,
                    data: [data.avatarId, data.name, data.targetType === 1 ? "حقوقی" : "حقیقی"]
                }));
                setTableData({
                    values: values,
                    countAll: res.data.countAll
                });
            }).catch(()=> props.dispatch(SetSiteLoading(false)));
        await props.dispatch(SetSiteLoading(false));
    };

    const getSingleTarget = async (index) => {
        if (index !== null) {
            await props.dispatch(SetSiteLoading(true));
            await Axios.get(baseUrl + "/targets/" + index.value, {headers: {Authorization: apiToken}})
                .then(res => {
                    const values = [{
                        id: res.data.data.targetId,
                        data: [res.data.data.avatarId, res.data.data.name]
                    }];
                    setTableData({
                        values: values,
                        countAll: 1
                    });

                });
            await props.dispatch(SetSiteLoading(false));
        } else {
            getTargets()
        }
    };

    const removeTarget = async ID => {
        return await Axios.post(baseUrl + "/targets/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "هدف با موفقیت حذف شد");
                    return true;
                }
                return false;
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
                return false;
            })
    };

    useEffect(() => {
        getTargets().then();
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    const promiseOptions = async (inputValue = "", url, type) => {
        return new Promise(resolve => {
            Axios.get(baseUrl + `/${url}/search${(isSubscriber ? "/forsubscriber" : "")}?query=` + inputValue + `&relationType=${type === 0 ? type : 1}`, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data.data);
                    }
                })
        });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center" color="warning">
                        <div>
                            <h4 className={classes.cardTitleWhite}>جدول سوژه ها</h4>
                            <p className="m-0">جدولی از سوژه ها به ترتیب زمان به وجود آمدن</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <Select
                                isRtl
                                cacheOptions
                                defaultOptions
                                isClearable
                                placeholder="نوع هویت ..."
                                noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                                loadingMessage={() => "در حال بارگزاری .."}
                                styles={{
                                    menu: base => ({...base, zIndex: 555555, color: "#000"}),
                                    container: base => ({...base, width: "15rem", marginLeft: "1rem"})
                                }}
                                options={[
                                    {value: 0, label: 'حقیقی'},
                                    {value: 1, label: 'حقوقی'}
                                ]}
                                onChange={res =>{
                                    res ? getTargets(1 , res.value) : getTargets();
                                    res ? setTargetType(res.value) : setTargetType("")
                                }}
                            />
                            <AsyncSelect
                                isRtl
                                cacheOptions
                                defaultOptions
                                isClearable
                                value={value}
                                placeholder="جستجوی سوژه..."
                                noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                                loadingMessage={() => "در حال بارگزاری .."}
                                styles={{
                                    menu: base => ({...base, zIndex: 555555, color: "#000"}),
                                    container: base => ({...base, width: "15rem", marginLeft: "1rem"})
                                }}
                                loadOptions={inputValue =>
                                    promiseOptions(inputValue, "targets", 0)
                                }
                                onChange={res => {
                                    onValueChange(res);
                                    getSingleTarget(res)
                                }}
                            />
                            <Tooltip title="افزودن شخص">
                                <IconButton
                                    color="inherit"
                                    onClick={() => navigate('/admin/targets/editor')}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Table
                            onPaging={(event, value) => getTargets(value,targetType)}
                            limitViews={12}
                            tableHeaderColor="warning"
                            tableHead={["آواتار", "نام", "هویت"]}
                            tableData={tableData.values || []}
                            totalAmount={tableData.countAll}
                            actions={{
                                onRead: (key, ID) => {
                                    navigate("/admin/targets/study/" + ID);
                                },
                                onDelete: isSubscriber ? null : async (key, ID) => {
                                    removeTarget(ID).then(res => {
                                        if (res) {
                                            setTableData({
                                                values: tableData.values.filter(val => val.id !== ID),
                                                countAll: tableData.countAll - 1
                                            })
                                        }
                                    });
                                },
                                onEdit: isSubscriber ? null : (key, ID) => {
                                    navigate("/admin/targets/editor/" + ID);
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

const mapState = states => ({
    UserDetails : states.userDetails
});
export default connect(mapState)(TargetsList);