import React, {useState} from 'react';

const PdfViewer = ({file, className, children}) => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const drawerCollapsation = (bool = true) => {
        setDrawerVisible(bool);
    };

    return (
        <div
            id='viewer'
            className={className}
            style={{width: '100%', height: '100%', borderRadius: ".5rem"}}
        >
            {children}
            {/*<Drawer*/}
            {/*    closable*/}
            {/*    title={__("List of documents")}*/}
            {/*    placement={this.props.RtlDir ? "right" : "left"}*/}
            {/*    className={this.props.RtlDir ? "dir-rtl" : "dir-ltr"}*/}
            {/*    onClose={() => this.drawerCollapsation(false)}*/}
            {/*    visible={drawerVisible}*/}
            {/*    width={250}*/}
            {/*    style={{backgroundColor: "rgba(0, 0, 0, .2)"}}*/}
            {/*>*/}
            {/*    {*/}
            {/*        processedFiles === null*/}
            {/*            ?*/}
            {/*            ""*/}
            {/*            :*/}
            {/*            processedFiles.map((data, index) => (*/}
            {/*                <div key={index} className="list-item mb-3 position-relative">*/}
            {/*                    <PDFtoImage file={data.actFileDoc}>*/}
            {/*                        {({pages}) => {*/}
            {/*                            if (!pages.length) return <SpinLoading loading mode="loading"*/}
            {/*                                                                   className="w-100 h-100"/>;*/}
            {/*                            return pages.slice(0, 1).map((page, index) =>*/}
            {/*                                <img*/}
            {/*                                    onClick={() => this.changeReaderContent(data.actFileDoc)}*/}
            {/*                                    className="w-100 h-auto"*/}
            {/*                                    key={index}*/}
            {/*                                    src={page}*/}
            {/*                                    alt="pdf-file"*/}
            {/*                                />*/}
            {/*                            );*/}
            {/*                        }}*/}
            {/*                    </PDFtoImage>*/}
            {/*                    <div className="title-overlay w-100 position-absolute">*/}
            {/*                        <p>{data.actFileName}</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))*/}
            {/*    }*/}
            {/*</Drawer>*/}
            {
                <iframe
                    title="pdf viewer"
                    src={"/web/viewer.html?url=" + file + ""}
                    style={{width : "100%", height : "100%"}}
                    frameBorder="none"
                />
            }

        </div>
    );
};

export default PdfViewer;