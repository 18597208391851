import React from 'react';
import routes from "./../routes";
import {Route, Routes} from "react-router-dom";

const Login = ()  => {
    return (
        <Routes>
            {
                routes.map((data, key) => {
                    if (data.layout === "/login") {
                        return <Route
                            path={data.path}
                            element={<data.component/>}
                            key={key}
                        />
                    }
                    return null;
                })
            }
        </Routes>
    )
};

export default Login;