import React, {forwardRef} from 'react';
import Diagram, {
    Command,
    ContextToolbox,
    Group,
    HistoryToolbar, MainToolbar,
    PropertiesPanel,
    Tab, Toolbox,
    ViewToolbar
} from "devextreme-react/diagram";
import dialog from "devextreme/ui/dialog";

const GraphDiagram = (props, ref) => {
    const onCustomCommand = (e) => {
        if (e.name === 'clear') {
            let result = dialog.confirm(
                'آیا برای پاک کردن نمودار مطمئن هستید؟',
                'هشدار'
            );
            result.then(
                function (dialogResult) {
                    if (dialogResult) {
                        e.component.import('');
                    }
                }
            );
        }
    };
    return (
        <Diagram
            ref={ref}
            id="diagram"
            readOnly={props.readOnly}
            onCustomCommand={onCustomCommand}
            on
        >
            <Toolbox visibility={false}>
                <Group category="general" title="General"/>
                <Group category="orgChart" title="Organizational Chart" expanded/>
            </Toolbox>
            <ContextToolbox
                enabled={!props.readOnly}
                category="flowchart"
            />
            <PropertiesPanel visibility={!props.readOnly}>
                <Tab>
                    <Group
                        title="Page Properties"
                        commands={['pageSize', 'pageOrientation', 'pageColor']}
                        expanded
                    />
                </Tab>
            </PropertiesPanel>
            <HistoryToolbar visible={false}/>
            <ViewToolbar visible={!props.readOnly} className="test"/>
            <MainToolbar visible={!props.readOnly}>
                <Command name="undo"/>
                <Command name="redo"/>
                <Command name="separator"/>
                <Command name="fontName"/>
                <Command name="fontSize"/>
                <Command name="separator"/>
                <Command name="bold"/>
                <Command name="italic"/>
                <Command name="underline"/>
                <Command name="separator"/>
                <Command name="fontColor"/>
                <Command name="lineColor"/>
                <Command name="fillColor"/>
                <Command name="separator"/>
                <Command name="clear" icon="clearsquare" text="Clear Diagram"/>
            </MainToolbar>
        </Diagram>
    );
};

export default forwardRef(GraphDiagram);