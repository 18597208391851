import React, {useEffect, useState} from 'react';
// Core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {Editor as TextEditor} from '@tinymce/tinymce-react';
import CategorySelector from "../../../components/Editor/CategorySelector/CategorySelector";
import Publish from "../../../components/Editor/Publish/Publish";
import FeatureImage from "../../../components/Editor/FeatureImage/FeatureImage";
import MoreDetailsTable from "../../../components/Editor/MoreDetailsTable/MoreDetailsTable";
import TitleBar from "../../../components/Editor/TitleBar/TitleBar";
import {getUrl, sendErrorNotification, sendNotification} from "../../../functions";
import {useNavigate, useParams} from "react-router-dom";
import {SetMediaDrawer, SetSiteLoading} from "../../../redux/actions/actions";
import {useSnackbar} from "notistack";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {apiToken} from "../../../appConfig";
import {baseUrl} from '../../../appConfig';
import Axios from "axios";
// @material-ui/core components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import RelationsTable from "../../../components/Editor/RelationsTable/RelationsTable";
import GraphDiagram from "../../../components/Diagram/GraphDiagram";
import MultimediaUploaders from "../../Targets/MultimediaUploaders/MultimediaUploaders";

let diagramRef;
const ProjectsEditor = ({...props}) => {
    const navigate = useNavigate();
    const {projectId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    let [params, setParams] = useState({
        name: "",
        avatarId: "",
        description: '<p style="text-align : right">در این قسمت می توانید توضیحات مربوط به پروژه را وارد کنید</p>',
        details: "",
        categoryId: null,
        relations: [],
        projectDetails: {
            picturs: [],
            videos: [],
            audios: [],
            pdf: [],
        },
        graphData: "",
        projectType: 1,
        legalTargetDetails: [],
    });

    useEffect(() => {
        // let diagram = diagramRef.current.instance;
        if (projectId) {
            props.dispatch(SetSiteLoading(true));
            Axios.get(
                baseUrl +
                "/projects/" +
                projectId,
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    if (res.status === 200) {
                        setParams({
                            ...res.data.data,
                            categoryId: res.data.data.category ? res.data.data.category.categoryId : null
                        });
                        diagramRef.instance.import(res.data.data.graphData);
                    } else if (res.status === 204) {
                        sendErrorNotification(enqueueSnackbar, "محتوای مورد نظر وجود ندارد");
                        history.goBack();
                    }
                })
                .catch(() => {
                    sendErrorNotification(enqueueSnackbar)
                });
            props.dispatch(SetSiteLoading(false));
        }
        // eslint-disable-next-line
    }, [history.location]);

    useEffect(() => {
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    const createPost = async () => {
        await props.dispatch(SetSiteLoading(true));
        params.graphData = diagramRef.instance.export();
        await Axios.post(
            baseUrl +
            "/projects",
            params,
            {headers: {Authorization: apiToken}}
        )
            .then(async res => {
                if (res.status === 200) {
                    await sendNotification(enqueueSnackbar, "پست مورد نظر شما اضافه شد");
                    navigate("/admin/projects");
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                }
                props.dispatch(SetSiteLoading(false));
            })
    };

    const editPost = async () => {
        await props.dispatch(SetSiteLoading(true));
        params.graphData = diagramRef.instance.export();
        await Axios
            .post(
                baseUrl +
                "/projects/edit/" +
                projectId,
                params,
                {headers: {Authorization: apiToken}}
            )
            .then(async res => {
                if (res.status === 200) {
                    await sendNotification(enqueueSnackbar, "پست مورد نظر شما ویرایش شد");
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                }
            });
        await props.dispatch(SetSiteLoading(false));
    };

    const onSelectType = (type) => {
        if (type === 0) {
            params.details = {
                graphData: ""
            };
        }
        params.projectType = type.target.value;
        setParams({...params})
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Paper className="bg-transparent">
                    <Box p={2}>
                        <GridContainer className="flex-wrap d-flex">
                            <GridItem
                                xs={12}
                                md={8}
                                xl={9}
                            >
                                <div className="d-flex flex-column flex-md-row TitleBar-p">
                                    <GridItem
                                        xs={12}
                                    >
                                        <TitleBar
                                            label="عنوان پروژه"
                                            defaultValue={params.name}
                                            onChange={input => {
                                                params.name = input.target.value
                                            }}
                                        />
                                    </GridItem>
                                </div>
                                <Box mt={2}>
                                    <TextEditor
                                        id="full-featured-non-premium"
                                        value={params.description}
                                        init={{
                                            height: '800px',
                                            selector: 'textarea#full-featured-non-premium',
                                            menubar: 'file edit view insert format tools table help',
                                            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
                                            imagetools_cors_hosts: ['picsum.photos'],
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                            image_advtab: true,
                                            file_picker_callback: function (callback, value, meta) {
                                                if (meta.filetype === 'file') {
                                                    props.dispatch(SetMediaDrawer({
                                                        type : "application",
                                                        action: value => {
                                                            callback(getUrl(value), { alt: 'p-chosen' });
                                                        }
                                                    }))
                                                }
                                                if (meta.filetype === 'image') {
                                                    props.dispatch(SetMediaDrawer({
                                                        type : "image",
                                                        action: value => {
                                                            callback(getUrl(value), { alt: 'p-chosen' });
                                                        }
                                                    }))
                                                }
                                                if (meta.filetype === 'media') {
                                                    props.dispatch(SetMediaDrawer({
                                                        type : "video",
                                                        action: value => {
                                                            callback(getUrl(value), { alt: 'p-chosen' });
                                                        }
                                                    }))
                                                }
                                            },
                                            image_caption: true,
                                            toolbar_mode: 'sliding',
                                        }}
                                        onEditorChange={text => {
                                            params.description = text
                                        }}
                                    />
                                </Box>
                            </GridItem>
                            <GridItem xs={12} md={4} xl={3}>
                                <Publish
                                    id={projectId}
                                    editMode={projectId !== undefined}
                                    onPublish={projectId ? editPost : createPost}
                                    previousPageAddress="/admin/projects"
                                    addPDF={(pdf) => {
                                        params.projectDetails.pdf ?
                                            params.projectDetails.pdf = [...params.projectDetails.pdf, pdf]
                                            :
                                            params.projectDetails.pdf = [pdf];
                                        setParams({...params})
                                    }}
                                    pdfs={params.projectDetails.pdf ? params.projectDetails.pdf : []}
                                    removePDF={(pdf) => {
                                        params.projectDetails.pdf = params.projectDetails.pdf.filter(x => x.fileManagerId !== pdf.fileManagerId);
                                        setParams({...params})
                                    }}
                                />
                                <CategorySelector
                                    defaultValue={params.categoryId}
                                    onChange={value => {
                                        params.categoryId = parseInt(value.value)
                                    }}
                                />
                                <FeatureImage defaultValue={params.avatarId} onChoose={value => {
                                    params.avatarId = value
                                }}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <RelationsTable
                                    defaultValue={params.relations ? params.relations.map(data => ({
                                        target: {value: data.targetId, label: data.targetName},
                                        relation: {value: data.relationId, label: data.relationName},
                                        relationType: {value: data.relationTypeId, label: data.relationTypeName},
                                        targetRelation: {value: data.targetRelationId, label: data.targetRelationName},
                                        description: data.description,
                                    })) : []}
                                    onChange={value => {
                                        params.relations = value.map(data => {
                                            return {
                                                targetId: parseInt(data.target.value),
                                                targetName: data.target.label,
                                                relationId: parseInt(data.relation.value),
                                                relationName: data.relation.label
                                            }
                                        });
                                    }}
                                    relationType={0}
                                    project
                                />
                                <MultimediaUploaders
                                    onAddImage={value => {
                                        if(params.projectDetails && params.projectDetails.picturs)
                                            params.projectDetails.picturs = [...params.projectDetails.picturs, value];
                                        else
                                            params.projectDetails.picturs = [value];
                                    }}
                                    onAddVideo={value => {
                                        if(params.projectDetails && params.projectDetails.videos)
                                            params.projectDetails.videos = [...params.projectDetails.videos, value]
                                        else
                                            params.projectDetails.videos = [value];
                                    }}
                                    onAddSound={value => {
                                        if(params.projectDetails && params.projectDetails.audios)
                                            params.projectDetails.audios = [...params.projectDetails.audios, value]
                                        else
                                            params.projectDetails.audios = [value];
                                    }}
                                    imageData={params.projectDetails && params.projectDetails.picturs}
                                    soundData={params.projectDetails && params.projectDetails.audios}
                                    videoData={params.projectDetails && params.projectDetails.videos}
                                    onDeleteImage={(index) =>
                                        params.projectDetails.picturs.splice(index, 1)
                                    }
                                    onDeleteSound={(index) => params.projectDetails.audios.splice(index, 1)}
                                    onDeleteVideo={(index) => params.projectDetails.videos.splice(index, 1)}
                                />
                                {/*<MoreDetailsTable*/}
                                {/*    defaultValue={params.details ? JSON.parse(params.details) : []}*/}
                                {/*    onChange={value => {*/}
                                {/*        params.details = JSON.stringify(value)*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </GridItem>
                        </GridContainer>
                    </Box>
                </Paper>
            </GridItem>

            <GridItem xs={12}>
                <div className="dx-viewport mt-4">
                    <div className="demo-container">
                        <GraphDiagram
                            ref={ref => {
                                diagramRef = ref
                            }}
                        />
                    </div>
                </div>
            </GridItem>
        </GridContainer>
    );
};

ProjectsEditor.propTypes = {
    dispatch: PropTypes.func
};

const mapState = () => ({});
export default connect(mapState)(ProjectsEditor);