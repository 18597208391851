import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import CastConnectedRoundedIcon from '@material-ui/icons/CastConnectedRounded';
import PropTypes from 'prop-types';
import './SocialTable.css';
import {connect} from "react-redux";
import Axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import {getUrl} from "../../../functions";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '2px solid #fff',
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {},
    cardBody: {
        maxHeight: "15rem",
        overflow: "auto"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));



const SocialItem = ({onChange, title, defaultValue, avatar, socialMedia}) => {
    const [value, setValue] = useState("");
    const classes = useStyles();
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue]);
    return (
        <ListItem>
            <ListItemIcon>
                <Tooltip placement="top" title={title}>
                    <Avatar
                        className={classes.small}
                        sizes="small"
                        variant="square"
                        src={avatar && getUrl(avatar)}
                    />
                </Tooltip>
            </ListItemIcon>
            <TextField
                className="w-100"
                placeholder="آیدی"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(socialMedia, e.target.value);
                }}
            />
        </ListItem>
    )
};

const SocialTable = ({onChange, defaultValues}) => {
    const [data, setData] = useState([]);
    const classes = useStyles();
    let [socialMedias, setSocialMedias] = useState([]);

    useEffect(() => {
        Axios.get(
            baseUrl + "/socialmedias/",
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                setData(res.data.data);
            })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (defaultValues.length > 0) {
            setSocialMedias(defaultValues);
            defaultValues.map((d) => {
                const find = data.filter(x => d.socialPlatformId === x.socialMediaId);
                const index = data.indexOf(find[0]);
                if (data && data[index]) {
                    data[index].value = d.id;
                    setData([...data]);
                }
            })
        }
        // eslint-disable-next-line
    }, [defaultValues]);

    const setId = (socialId, value) => {
        socialMedias = (socialMedias.filter(x => x.socialPlatformId !== socialId));

        const newSocials =  [
            ...socialMedias,
            {
                socialPlatformId: socialId,
                id: value
            }
        ];

        setSocialMedias(newSocials);
        onChange(newSocials);
    };

    return (
        <Card className="pb-3 ">
            <CardHeader stats icon>
                <CardIcon color="heliotrope">
                    <CastConnectedRoundedIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3" style={{color: "#000"}}>شبکه های اجتماعی و راه های ارتباطی</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    آیدی شبکه ی اجتماعی سوژه را در فیلد مخصوص آیدی جلوی شبکه ی اجتماعی مورد نظر وارد کنید.
                                </span>
                                <span>
                                    اگر شبکه ی اجتماعی مورد نظر در این بخش وجود ندارد میتوانید از طریق صفحه دیگر بخش ها شبکه ی اجتماعی مورد نظر را اضافه کنید و سپس به این صفحه بازگردید و آیدی شبکه اجتماعی مورد نظر خود را وارد کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardBody className={classes.cardBody}>
                <List>
                    {
                        data.map((d, i) =>
                            <SocialItem
                                key={i}
                                title={d.name}
                                defaultValue={d.value}
                                avatar={d.avatarId}
                                socialMedia={d.socialMediaId}
                                onChange={(socialMedia, value) => setId(socialMedia, value)}
                            />
                        )
                    }
                </List>
            </CardBody>
        </Card>
    );
};

SocialTable.propTypes = {
    defaultValues: PropTypes.array,
    onChange: PropTypes.func
};

const mapState = () => ({});
export default connect(mapState)(SocialTable);