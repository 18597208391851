import React, {useEffect, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Axios from "axios";
import {apiToken} from "../../appConfig";
import {baseUrl} from "../../appConfig";
import GridItem from "../../components/Grid/GridItem";
import {Grid, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {getUrl} from "../../functions";
import {SetSiteLoading, SetUserInformationDrawer} from "../../redux/actions/actions";
import {connect} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import './Study.css'
import CommentStudy from "../../components/CommentStudy/CommentStudy";
import SocialStudy from "../../components/study/SocialStudy/SocialStudy";
import StudyGraphs from "../../components/study/StudyGraphs/StudyGraphs";
import TargetRelations from "../../components/TargetRelations/TargetRelations";
import PdfViewer from "../../components/PDFViewer/PDFViewer";
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '2px solid #fff',
    },
}))(Tooltip);

const CustomButton = withStyles({
    root: {
        borderColor: '#e551ff',
        color: "#d54af1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
        direction: "ltr",
        whiteSpace: "nowrap",
        width: "100%",
        display: "block",
        textTransform: "lowercase",
        '&:hover': {
            color: '#e551ff',
        },
        '& $span': {
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: "#ffffffca",
    },
    gridItemPadding: {
        padding: 0
    },
    appBar: {
        backgroundColor: '#000000cd'
    },
    descriptionBox: {
        "& *": {
            lineHeight: "2rem"
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Study = (props) => {
    const route = useLocation();
    const checkProjectPage = route.pathname.includes("projects");
    const ID = route.pathname.split("/")[4];
    const [detailsData, setDetailsData] = useState([]);
    const [projectData, setProjectData] = useState({});
    const [diagramData, setDiagramData] = useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const [openPDFList, setOpenPDFList] = React.useState(false);
    const [fileId, setFileId] = React.useState(false);
    const [open, setOpenPDF] = React.useState(false);
    const [heightInformation, setHeightInformation] = React.useState(350);
    const anchorRef = React.useRef(null);
    const classes = useStyles();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleToggle = () => {
        setOpenPDFList((prevOpen) => !prevOpen);
    };

    const handleClosePDFList = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenPDFList(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenPDFList(false);
        }
    }

    async function getForStudy() {
        await props.dispatch(SetSiteLoading(true));
        await Axios.get(
            baseUrl + `/${checkProjectPage ? "projects" : "targets"}/ForStudy/` + ID,
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    setDiagramData(res.data.data.relations);
                    setProjectData(res.data.data);
                    res.data.data.details.moreDetails ?
                        setDetailsData(JSON.parse(res.data.data.details.moreDetails))
                        :
                        setDetailsData([]);
                }
            });

        await props.dispatch(SetSiteLoading(false));
    }

    const handleClickOpen = (fileId) => {
        setFileId(fileId);
        setOpenPDF(true);
    };

    const handleClose = () => {
        setOpenPDF(false);
    };

    useEffect(() => {
        getForStudy().then();
        setHeightInformation(350)
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, [ID]);

    useEffect(() => {
        am4core.ready(() => {
            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("chartdiv", am4plugins.ForceDirectedTree);

            let networkSeries = chart.series.push(new am4plugins.ForceDirectedSeries());
            networkSeries.dataFields.linkWith = "linkWith";
            networkSeries.dataFields.name = "name";
            networkSeries.dataFields.id = "name";
            networkSeries.dataFields.relationName = "id";
            networkSeries.dataFields.value = "value";
            networkSeries.dataFields.children = "children";
            networkSeries.nodes.template.label.text = "{name}";
            networkSeries.fontSize = 10;
            networkSeries.linkWithStrength = 0;

            let nodeTemplate = networkSeries.nodes.template;
            nodeTemplate.tooltipText = " {name} ({relationName}) ";
            nodeTemplate.fillOpacity = .8;
            nodeTemplate.label.hideOversized = true;
            nodeTemplate.label.truncate = true;

            let linkTemplate = networkSeries.links.template;
            linkTemplate.strokeWidth = 1;
            let linkHoverState = linkTemplate.states.create("hover");
            linkHoverState.properties.strokeOpacity = 1;
            linkHoverState.properties.strokeWidth = 2;

            nodeTemplate.events.on("hit", (event) => {
                let dataItem = event.target.dataItem;
                if (dataItem._dataContext.name !== projectData.name) {
                    props.dispatch(SetUserInformationDrawer({
                        type: "person",
                        value: "chosenUser",
                        id: dataItem._id,
                    }));
                } else {
                    props.dispatch(SetUserInformationDrawer(null));
                }
            });

            nodeTemplate.events.on("over", (event) => {
                let dataItem = event.target.dataItem;
                dataItem.childLinks.each(function (link) {
                    link.isHover = true;
                });
            });

            nodeTemplate.events.on("out", function (event) {
                let dataItem = event.target.dataItem;
                dataItem.childLinks.each(function (link) {
                    link.isHover = false;
                })
            });
            if (diagramData.length >= 1) {
                networkSeries.data = diagramData;
            }
        });
        // eslint-disable-next-line
    }, [diagramData, projectData]);
    // eslint-disable-next-line
    const getChildrenNames = (data) => {
        let names = [];
        data.forEach(val => {
            names.push({
                name: val.name,
                image: val.fileManagerId
            });
            if (val.children)
                names = [...names, ...getChildrenNames(val.children)]
        });
        return names;
    };

    const handleHeightInformation = () => {
        setHeightInformation("initial")
    }

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="mt-5 pt-5 h-100">
                    <PdfViewer file={getUrl(fileId)}/>
                </div>
            </Dialog>
            <Grid className="d-flex flex-wrap px-0">
                <GridItem
                    xs={12}
                    lg={8}
                >
                    <div
                        style={{
                            border: "1px solid",
                            borderRadius: ".5rem",
                        }}
                        className="h-100 p-4 "
                    >
                        <div
                            className="d-flex align-items-center justify-content-between mb-4"
                        >
                            <h1 className="title-study m-0">{projectData.name}</h1>
                            <div className="d-flex">
                                <Link to={`/admin/${checkProjectPage ? "projects" : "targets"}/editor/` + ID}
                                      className="mx-2">
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                    >
                                        ویرایش
                                    </Button>
                                </Link>
                                {
                                    projectData.details &&
                                    projectData.details.pdf &&
                                    projectData.details.pdf.length > 0 &&
                                    <div className="d-flex align-items-center position-relative">
                                        <Button
                                            ref={anchorRef}
                                            variant="outlined"
                                            color="inherit"
                                            onClick={handleToggle}
                                        >
                                            مطالعه PDF
                                        </Button>
                                        <Popper
                                            open={openPDFList}
                                            anchorEl={anchorRef}
                                            role={undefined}
                                            transition
                                            disablePortal
                                        >
                                            {({TransitionProps, placement}) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClosePDFList}>
                                                            <MenuList
                                                                autoFocusItem={open}
                                                                id="menu-list-grow"
                                                                onKeyDown={handleListKeyDown}

                                                            >
                                                                {
                                                                    projectData.details.pdf.map((p, i) =>
                                                                        <MenuItem
                                                                            key={i}
                                                                            onClick={() => handleClickOpen(p.fileManagerId)}
                                                                        >
                                                                            {p.title}
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                        <HtmlTooltip
                                            placement="top"
                                            title={
                                                <div className="d-flex flex-column">
                                                    <span>
                                                        برای مطالعه آنلاین pdf باید حتما idm غیر فعال باشد.
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <HelpIcon
                                                className="cursor-pointer mx-2"
                                                style={{
                                                    width: '1.5rem',
                                                    color: '#bc7d27'
                                                }}
                                            />
                                        </HtmlTooltip>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{maxHeight: heightInformation, overflow: "hidden"}}>
                            <div
                                id="description-box"
                                className={classes.descriptionBox + " pt-2"}
                                style={{lineHeight:'3rem'}}
                                dangerouslySetInnerHTML={{__html: projectData.description}}
                            />
                        </div>
                        <div className="w-100 d-flex justify-content-end">
                            {
                                document.getElementById("description-box") &&
                                document.getElementById("description-box").offsetHeight > 350 &&
                                heightInformation !== "initial" &&
                                <Button
                                    ref={anchorRef}
                                    variant="outlined"
                                    color="inherit"
                                    onClick={handleHeightInformation}
                                >
                                    مطالعه ی بیشتر
                                </Button>
                            }
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} lg={4}>
                    <div className="w-100 d-flex align-items-start overflow-hidden image-box-study  mt-xl-0 mt-4">
                        {
                            projectData.avatarId &&
                            <img
                                className="w-100"
                                src={getUrl(projectData.avatarId)}
                                alt={projectData.name}
                            />
                        }
                    </div>
                    {
                        projectData && projectData.socialMedias && projectData.socialMedias.length > 0 &&
                        <SocialStudy
                            className="mb-0"
                            data={projectData.socialMedias}
                        />
                    }
                </GridItem>
            </Grid>
            {/*<Grid className="d-flex" container>*/}
            {/*<GridItem*/}
            {/*    sm={12}*/}
            {/*    lg={(projectData.targetType === 0 || projectData.projectType === 0) ? 8 : 0}*/}
            {/*>*/}
            {/*    {*/}
            {/*        (*/}
            {/*            projectData.targetType === 0 ||*/}
            {/*            projectData.projectType === 0*/}
            {/*        ) &&*/}
            {/*        <>*/}
            {/*            {*/}
            {/*                projectData &&*/}
            {/*                projectData.politicalAttitudes &&*/}
            {/*                projectData.politicalAttitudes.length > 0 &&*/}
            {/*                <PoliticalTable*/}
            {/*                    defaultValue={*/}
            {/*                        projectData.politicalAttitudes*/}
            {/*                            ?*/}
            {/*                            projectData.politicalAttitudes.map(data => ({*/}
            {/*                                politicalParties: {*/}
            {/*                                    value: data.politicalPartyId,*/}
            {/*                                    label: data.politicalPartyName*/}
            {/*                                },*/}
            {/*                                title: data.title,*/}
            {/*                                description: data.description,*/}
            {/*                                targetPoliticalAttitudeId: data.targetPoliticalAttitudeId*/}
            {/*                            }))*/}
            {/*                            :*/}
            {/*                            []*/}
            {/*                    }*/}
            {/*                    showAddCommand={false}*/}
            {/*                    showEditCommand={false}*/}
            {/*                    showDeleteCommand={false}*/}
            {/*                />*/}
            {/*            }*/}
            {/*            {*/}
            {/*                projectData &&*/}
            {/*                projectData.details &&*/}
            {/*                projectData.details.jobTitles &&*/}
            {/*                projectData.details.jobTitles.length > 0 &&*/}
            {/*                <JobInformationTable*/}
            {/*                    defaultValue={projectData.details.jobTitles}*/}
            {/*                    targetId={targetId}*/}
            {/*                    forStudy*/}
            {/*                />*/}
            {/*            }*/}
            {/*        </>*/}
            {/*    }*/}
            {/*</GridItem>*/}
            {/*<GridItem*/}
            {/*    sm={12}*/}
            {/*    lg={(*/}
            {/*        (*/}
            {/*            projectData.targetType === 0 ||*/}
            {/*            projectData.projectType === 0*/}
            {/*        ) &&*/}
            {/*        (*/}
            {/*            projectData &&*/}
            {/*            projectData.politicalAttitudes &&*/}
            {/*            (*/}
            {/*                projectData.politicalAttitudes.length > 0 || projectData.details.jobTitles.length > 0*/}
            {/*            )*/}
            {/*        )*/}
            {/*    )*/}
            {/*        ?*/}
            {/*        4*/}
            {/*        :*/}
            {/*        12*/}
            {/*    }*/}
            {/*    className="d-flex px-0"*/}
            {/*>*/}
            {/*    {*/}
            {/*        projectData.targetType || projectData.targetType === 0 ?*/}
            {/*            (*/}
            {/*                (*/}
            {/*                    projectData.targetType === 0 ||*/}
            {/*                    projectData.projectType === 0*/}
            {/*                ) &&*/}
            {/*                (*/}
            {/*                    projectData &&*/}
            {/*                    projectData.politicalAttitudes &&*/}
            {/*                    (*/}
            {/*                        projectData.politicalAttitudes.length > 0 || projectData.details.jobTitles.length > 0*/}
            {/*                    )*/}
            {/*                )*/}
            {/*            ) ?*/}
            {/*                <>*/}
            {/*                    <PermIdentity*/}
            {/*                        data={*/}
            {/*                            projectData &&*/}
            {/*                            projectData.details &&*/}
            {/*                            projectData.details.identity*/}
            {/*                        }*/}
            {/*                    />*/}
            {/*                    <LivingPlace*/}
            {/*                        defaultValue={projectData && projectData.details && projectData.details.livingPlace}*/}
            {/*                        targetId={null}*/}
            {/*                        forStudy*/}
            {/*                        targetType={projectData.targetType}*/}
            {/*                    />*/}
            {/*                </>*/}
            {/*                :*/}
            {/*                <div className="d-flex">*/}
            {/*                    <LivingPlace*/}
            {/*                        defaultValue={projectData && projectData.details && projectData.details.livingPlace}*/}
            {/*                        targetId={null}*/}
            {/*                        forStudy*/}
            {/*                        targetType={projectData.targetType}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            :*/}
            {/*    }*/}
            {/*</GridItem>*/}
            {/*</Grid>*/}
            <Grid className="pt-5">
                <GridItem xs={12}>
                    {
                        checkProjectPage &&
                        <TargetRelations
                            defaultValue={projectData && projectData.targetProjectRelations}
                        />
                    }
                    {
                        projectData &&
                        <StudyGraphs
                            targetType={projectData.targetType}
                            personalData={projectData.targetType ? projectData.details && projectData.details.graphData : projectData.graphData}
                            workData={projectData.details && projectData.details.legalTargetDetails}
                            generalData={{all: projectData.relations, others: projectData.relationsBaseOnType}}
                            gavelData={projectData.politicalAttitudes}
                            jobData={projectData.details && projectData.details.jobTitles}
                            locationData={projectData.details && projectData.details.livingPlace}
                            identityData={
                                projectData.details &&
                                projectData.details.identity
                            }
                            audioData={
                                checkProjectPage ?
                                    projectData.projectDetails &&
                                    projectData.projectDetails.audios
                                    :
                                    projectData.details &&
                                    projectData.details.audios
                            }
                            videoData={
                                checkProjectPage ?
                                    projectData.projectDetails &&
                                    projectData.projectDetails.videos
                                    :
                                    projectData.details &&
                                    projectData.details.videos
                            }
                            pictureData={
                                checkProjectPage ?
                                    projectData.projectDetails &&
                                    projectData.projectDetails.picturs
                                    :
                                    projectData.details &&
                                    projectData.details.picturs
                            }
                            onChangeDiagramData={(d) => setDiagramData(d)}
                        />
                    }
                    {
                        detailsData.length > 0 &&
                        <h3 className="px-3">جزئیات</h3>
                    }
                    <Grid className="d-flex">
                        <div className="w-100 px-3">
                            {
                                detailsData && detailsData.map((d, i) =>
                                    d.type !== "file" &&
                                    <ExpansionPanel
                                        key={i}
                                        style={{
                                            background: "hsla(0, 0%, 0%, 0.60)",
                                            color: "#ffffff"
                                        }}
                                        expanded={expanded === d.title}
                                        onChange={handleChange(d.title)}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={classes.heading}>{d.title}</Typography>
                                            <Typography
                                                className={classes.secondaryHeading}
                                            >
                                                {d.value && d.value.slice(0, 30) + "..."}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid className="d-flex w-100">
                                                {
                                                    (
                                                        d.picture || d.file || d.source) &&
                                                    <GridItem
                                                        xs={6}
                                                    >
                                                        {
                                                            d.picture &&
                                                            <img
                                                                className="w-100"
                                                                src={getUrl(d.picture)}
                                                                alt=""
                                                            />
                                                        }
                                                        {
                                                            d.file &&
                                                            <div className="d-flex">
                                                                <Tooltip title="دانلود فایل" placement="top-end">
                                                                    <CustomButton
                                                                        variant="outlined"
                                                                        href={getUrl(d.file)}
                                                                        color="#78fffd"
                                                                    >
                                                                        {getUrl(d.file)}
                                                                    </CustomButton>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                        {
                                                            d.source &&
                                                            <Tooltip title={d.source} placement="top-end">
                                                                <div
                                                                    className="w-100"
                                                                    style={{
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        textAlign: "left",
                                                                        direction: "ltr",
                                                                        whiteSpace: "nowrap"
                                                                    }}
                                                                >
                                                                    <CustomButton
                                                                        variant="outlined"
                                                                        style={{
                                                                            color: "#78fffd",
                                                                            borderColor: '#78fffd'
                                                                        }}
                                                                        onClick={() =>
                                                                            window.open(d.source.split("http").length > 1
                                                                                ?
                                                                                d.source
                                                                                :
                                                                                "http://" + d.source
                                                                            )
                                                                        }
                                                                    >
                                                                        {d.source}
                                                                    </CustomButton>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </GridItem>
                                                }
                                                <GridItem
                                                    className="d-flex align-items-center"
                                                    xs={d.source || d.file || d.picture ? 6 : 12}
                                                >
                                            <pre style={{whiteSpace: "pre-line", margin: "4px 0"}}>
                                                {d.value}
                                            </pre>
                                                </GridItem>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                        </div>
                    </Grid>
                    <CommentStudy
                        id={ID}
                    />
                </GridItem>
            </Grid>
        </>
    );
};

const mapState = states => ({
    Authorization: states.userDetails,
});

export default connect(mapState)(Study);