import React, {useEffect, useState} from 'react';
import Drawer from "@material-ui/core/Drawer";
import Axios from "axios";
import {baseUrl} from "../../appConfig";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {getUrl, sendErrorNotification, sendNotification} from "../../functions";
import {makeStyles} from "@material-ui/core/styles";
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {apiToken} from "../../appConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/RadioButtonUncheckedTwoTone';
import 'react-dropzone/examples/theme.css';
import Uploader from "../Uploaders/MediaDrawerUploader/Uploader";
import Box from "@material-ui/core/Box";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import Popover from "@material-ui/core/Popover";
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {connect} from "react-redux";
import {SetSiteLoading} from "../../redux/actions/actions";
import LoyaltyRoundedIcon from '@material-ui/icons/LoyaltyRounded';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";
import TextField from "@material-ui/core/TextField";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        direction: "ltr",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: "1rem",
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        width: "100%",
        height: "100%",
        margin: "0 !important"
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
        "&:hover, &.selected": {
            color: "#01d83c"
        }
    },
    iconTrash: {
        color: 'rgba(255, 255, 255, 0.54)',
        "&:hover, &.selectTrash": {
            color: "#d82219"
        }
    },
}));

const loadingBoxStyle = {background: "rgba(0, 0, 0, .5)", zIndex: 5555};
let requested = false;
const MediaDrawer = (
    {
        open,
        onClose,
        onChange,
        value,
        isMulti,
        tagHandler = true,
        type = "All",
        anchor = "left",
        ...props
    }
) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [selected, select] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [media, setMedia] = useState([]);
    const [removePhysically, setRemovePhysically] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [moreButton, setMoreButton] = useState(true);
    const [data, setData] = useState({
        nameForTarget: "",
        fileManagerId: "",
        pictureArchiveId: "",
        selectedTags: [],
        Repetitious: false
    });

    useEffect(() => {
        getFiles(false, pageIndex).then();
        // eslint-disable-next-line
    }, [type]);

    useEffect(() => {
        if (value && typeof value === "object") {
            select(value)
        } else if (value) {
            select([value])
        }
        // eslint-disable-next-line
    }, [value, media]);

    const getFiles = async (processPageIndex = true, pageIndex) => {
        if (!requested && type) {
            if (!loading)
                await setLoading(true);
            await Axios.get(baseUrl + "/FileManagers?type=" + type + "&pageIndex=" + pageIndex, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        setPageIndex(pageIndex);
                        res.data.data.length === 0 && setMoreButton(false);
                        // requested = true;
                        setMedia([...media, ...res.data.data]);
                        if (processPageIndex) {
                            requested = false;
                            setPageIndex(pageIndex + 1);
                        }
                    }
                });
            await setLoading(false);
        }
    };

    const getTags = (keyword = "") => {
        return Axios.get(
            baseUrl + `/picturearchives/search/tags?keyword=${keyword}&returnValue=15`,
            {headers: {Authorization: apiToken}}
        )
            .then(async res => {
                    return [
                        {
                            label: 'دسته بندی',
                            options: res.data.data.categories.map((d) => {
                                return {value: d.value, label: d.label, type: 0}
                            }),
                        },
                        {
                            label: 'سوژه ها',
                            options: res.data.data.targets.map((d) => {
                                return {value: d.value, label: d.label, type: 1}
                            }),
                        },
                        {
                            label: 'مکان ها',
                            options: res.data.data.locations.map((d) => {
                                return {value: d.value, label: d.label, type: 2}
                            }),
                        },
                    ]
                }
            );

    };

    let chosenNumbers = [];
    let isTheBigOneChosen = false;
    const generateRandomNumber = (fileSize) => {
        if (chosenNumbers.length === 3) {
            chosenNumbers = [];
            if (isTheBigOneChosen) {
                isTheBigOneChosen = false;
                chosenNumbers.push(1);
                return 1;
            } else if (fileSize > 500000 && !isTheBigOneChosen) {
                isTheBigOneChosen = true;
                chosenNumbers.push(2);
                return 2;
            }
            isTheBigOneChosen = false;
            chosenNumbers.push(1);
            return 1;
        } else if (chosenNumbers.length === 2) {
            if (chosenNumbers.includes(1) && chosenNumbers.includes(2)) {
                chosenNumbers = [];
                if (isTheBigOneChosen) {
                    isTheBigOneChosen = false;
                    chosenNumbers.push(1);
                    return 1;
                } else if (fileSize > 500000 && !isTheBigOneChosen) {
                    isTheBigOneChosen = true;
                    chosenNumbers.push(2);
                    return 2;
                }
                isTheBigOneChosen = false;
                chosenNumbers.push(1);
                return 1;
            }
            chosenNumbers.push(1);
            return 1;
        } else if (chosenNumbers.length === 1) {
            if (chosenNumbers[0] === 1) {
                if (fileSize > 500000) {
                    chosenNumbers.push(2);
                    return 2;
                }
                chosenNumbers.push(1);
                return 1;
            } else {
                chosenNumbers.push(1);
                return 1;
            }
        } else if (chosenNumbers.length === 0) {
            if (isTheBigOneChosen) {
                isTheBigOneChosen = false;
                chosenNumbers.push(1);
                return 1;
            } else if (fileSize > 500000 && !isTheBigOneChosen) {
                isTheBigOneChosen = true;
                chosenNumbers.push(2);
                return 2;
            }
            isTheBigOneChosen = false;
            chosenNumbers.push(1);
            return 1;
        }
    };

    const selectFile = (fileManagerId) => {
        if (isMulti) {
            if (selected.includes(fileManagerId))
                select(selected.filter(val => val !== fileManagerId));
            else
                select([...selected, fileManagerId])
        } else {
            if (selected.includes(fileManagerId))
                select(selected.filter(val => val !== fileManagerId));
            else
                select([fileManagerId])
        }
    };

    const onDoneUpload = async (file) => {
        await setLoading(true);
        setMedia([file, ...media]);
        await setLoading(false);
    };

    const addPicture = () => {
        props.dispatch(SetSiteLoading(true));
        Axios.post(
            baseUrl + "/picturearchives",
            {
                title: data.nameForTarget,
                fileManagerId: data.fileManagerId,
                tags: data.selectedTags.map(item => {
                    return {itemId: item.value, tagType: item.type}
                })
            },
            {headers: {Authorization: apiToken}}
        )
            .then(() => {
                sendNotification(enqueueSnackbar, "تصویر به گالری اضافه شد");
                setOpenDialog(false);
                props.dispatch(SetSiteLoading(false))
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            )
    };

    const editPicture = () => {
        props.dispatch(SetSiteLoading(true));
        Axios.post(
            baseUrl + `/picturearchives/edit/${data.pictureArchiveId}`,
            {
                title: data.nameForTarget,
                tags: data.selectedTags.map(item => {
                    return {itemId: item.value, tagType: item.type}
                })
            },
            {headers: {Authorization: apiToken}}
        )
            .then(() => {
                sendNotification(enqueueSnackbar, "تصویر با موفقیت تغییر یافت");
                setOpenDialog(false);
                props.dispatch(SetSiteLoading(false))
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            )
    };

    const onDeletePicture = async (id) => {
        await props.dispatch(SetSiteLoading(true));
        return await Axios.post(
            baseUrl + `/filemanagers/remove/${id}?removePhysically=${removePhysically}`,
            {},
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    setMedia(media.filter(val => val.fileManagerId !== id));
                    props.dispatch(SetSiteLoading(false));
                    return true;
                }
                props.dispatch(SetSiteLoading(false));
                return false;
            })
    };

    const checkImageInGallery = async (id) => {
        await props.dispatch(SetSiteLoading(true));
        Axios.get(
            baseUrl + `/picturearchives/byfilemanager/${id}`,
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    setData({
                        fileManagerId: id,
                        pictureArchiveId: res.data.data.pictureArchiveId,
                        nameForTarget: res.data.data.title,
                        selectedTags: res.data.data.tags.map(d => {
                            return {value: d.itemId, label: d.name, type: d.tagType, itemId: d.itemId}
                        }),
                        Repetitious: true
                    });
                    setOpenDialog(true);
                }
                if (res.status === 204) {
                    setData({
                        fileManagerId: id,
                        pictureArchiveId: "",
                        nameForTarget: "",
                        selectedTags: [],
                        Repetitious: false
                    });
                    setOpenDialog(true);
                }
                props.dispatch(SetSiteLoading(false));
            })
            .catch()
    };

    return (
        <>
            <Drawer
                PaperProps={{style: {maxWidth: 500}, className: "p-2"}}
                onClose={() => {
                    setMedia([]);
                    setPageIndex(1);
                    setMoreButton(true);
                    onClose();
                }}
                anchor={anchor}
                open={open}
                elevation={3}
                style={{zIndex: 2003}}
            >
                <Uploader onDoneUpload={onDoneUpload} type={type}/>
                <div className={classes.root + " h-100"}>
                    <Box
                        style={loadingBoxStyle}
                        display={loading ? "flex" : "none"}
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        minHeight="100%"
                        width="100%"
                        height="100%"
                    >
                        <CircularProgress/>
                    </Box>
                    <GridList cellHeight={160} className={classes.gridList} cols={3}>
                        {media.filter(val => type !== "All" ? val.fileType === type : true).map((tile, index) => (
                            <GridListTile key={index} cols={generateRandomNumber(tile.size)}>
                                {
                                    tile.fileType === "image" &&
                                    <Tooltip placement="top-end" title={tile.fileName}>
                                        <img src={getUrl(tile.fileManagerId, 300)} alt={tile.fileName}/>
                                    </Tooltip>
                                }
                                {
                                    tile.fileType === "application" &&
                                    <div
                                        className="w-100 h-100 d-flex justify-content-center align-items-center"
                                        style={{
                                            backgroundImage: "url(/web/images/pdf.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "5rem",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <span className="bg-dark text-white px-2">{tile.fileName}</span>
                                    </div>
                                }
                                {
                                    tile.fileType === "audio" &&
                                    <div
                                        className="w-100 h-100 d-flex justify-content-center align-items-center"
                                        style={{
                                            backgroundImage: "url(/web/images/audio.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "5rem",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <span className="bg-dark text-white px-2">{tile.fileName}</span>
                                    </div>
                                }
                                <GridListTileBar
                                    actionIcon={
                                        <div className="d-flex">
                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                {(popupState) => (
                                                    <div className="d-flex">
                                                        <IconButton
                                                            className={classes.iconTrash}
                                                            variant="contained"
                                                            color="primary"
                                                            {...bindTrigger(popupState)}
                                                        >
                                                            <DeleteForeverRoundedIcon/>
                                                        </IconButton>
                                                        {
                                                            tagHandler && (
                                                                <IconButton
                                                                    className={classes.iconTrash}
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => checkImageInGallery(tile.fileManagerId)}
                                                                >
                                                                    <LoyaltyRoundedIcon/>
                                                                </IconButton>
                                                            )
                                                        }
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                            style={{zIndex: 9999}}
                                                        >
                                                            <Box p={2}>
                                                                <Typography>آیا مطمئن هستید؟</Typography>
                                                                {
                                                                    props.UserDetails.role === 0 &&
                                                                    (
                                                                        <div className="d-flex align-items-center">
                                                                            <Typography>حذف کامل</Typography>
                                                                            <Checkbox
                                                                                onChange={() => setRemovePhysically(!removePhysically)}
                                                                                value={removePhysically}
                                                                                size="small"
                                                                                inputProps={{'aria-label': 'checkbox with small size'}}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                <Button
                                                                    onClick={async () => {
                                                                        await onDeletePicture(tile.fileManagerId)
                                                                            .then(res => {
                                                                                if (res)
                                                                                    bindPopover(popupState).onClose();
                                                                            });
                                                                    }}
                                                                >
                                                                    بله
                                                                </Button>
                                                                <Button
                                                                    onClick={() => bindPopover(popupState).onClose()}>
                                                                    خیر
                                                                </Button>
                                                            </Box>
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                            <IconButton
                                                className={classes.icon + (selected.includes(tile.fileManagerId) ? " selected" : "")}
                                                onClick={async () => {
                                                    await onChange(tile.fileManagerId);
                                                    await selectFile(tile.fileManagerId)
                                                }}
                                            >
                                                {
                                                    selected.includes(tile.fileManagerId) ?
                                                        <CheckCircleTwoToneIcon/>
                                                        :
                                                        <RadioButtonUncheckedTwoToneIcon/>
                                                }
                                            </IconButton>
                                        </div>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                    <div className={loading ? "d-flex justify-content-center w-100 h-100" : "d-none"}>
                        <CircularProgress/>
                    </div>
                </div>
                <Dialog
                    keepMounted
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    style={{zIndex: "99999"}}
                >
                    <DialogTitle id="alert-dialog-slide-title">تصویر مورد نظر را تگ گذاری کنید</DialogTitle>
                    <DialogContent
                        style={{
                            width: "30rem",
                            maxWidth: "100vw",
                            height: "90vh",
                            overflow: "visible"
                        }}
                    >
                        <Card>
                            <CardBody>
                                <TextField
                                    variant="outlined"
                                    label="نام"
                                    type="text"
                                    value={data.nameForTarget}
                                    className="my-2 w-100"
                                    onChange={(e) =>
                                        setData({...data, nameForTarget: e.target.value})
                                    }
                                />
                                <AsyncSelect
                                    isRtl
                                    isMulti
                                    value={data.selectedTags}
                                    defaultOptions
                                    isClearable
                                    noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                                    loadingMessage={() => "در حال بارگزاری .."}
                                    loadOptions={inputValue =>
                                        getTags(inputValue)
                                    }
                                    placeholder="تگ ها را انتخاب کنید"
                                    onChange={(e) => setData({...data, selectedTags: e})}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: "3rem",
                                        })
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpenDialog(false)}
                            color="primary"
                        >
                            لغو
                        </Button>
                        <Button
                            onClick={data.Repetitious ? editPicture : addPicture}
                            color="primary"
                        >
                            تایید
                        </Button>
                    </DialogActions>
                </Dialog>

                <div className=" d-flex w-100 justify-content-center pt-5">
                    {
                        moreButton &&
                        <Button
                            onClick={() => getFiles(false, pageIndex + 1)}
                            variant='contained'
                            color="primary"
                            size="large"
                        >
                            بیشتر
                        </Button>
                    }
                    <Button
                        onClick={() => {
                            setMedia([]);
                            setPageIndex(1);
                            setMoreButton(true);
                            onClose();
                        }}
                        variant='contained'
                        color="secondary"
                        size="large"
                        className="mr-3"
                    >
                        بستن
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

const mapState = states => ({
    UserDetails: states.userDetails
});
export default connect(mapState)(MediaDrawer);