import React, {useEffect, useMemo, useState} from 'react';
import Gallery from 'react-grid-gallery';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import axios from "axios";

import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';

import {makeStyles} from '@material-ui/core/styles';
import {apiToken, baseUrl} from "../../appConfig";

import {getThumb, getUrl, sendErrorNotification, sendNotification} from "../../functions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {SetSiteLoading} from "../../redux/actions/actions";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import Slide from "@material-ui/core/Slide";
import moment from 'moment-jalali';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import './Multimedia.css';
import FoldersMedia from "../../components/FoldersMedia/FoldersMedia";
import VideoGallery from "../../components/VideoGallery/VideoGallery";

moment.loadPersian();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    DeleteForeverRoundedIcon: {
        color: "#ffffff"
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function a11yProps(index) {
    return {
        id: `action-tab-${index}`,
        'aria-controls': `action-tabpanel-${index}`,
    };
}

const captionStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    maxHeight: "240px",
    overflow: "hidden",
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "100%",
    color: "white",
    padding: "2px",
    fontSize: "90%",
    transition: "1s"
};

const customTagStyle = {
    wordWrap: "break-word",
    display: "inline-block",
    backgroundColor: "white",
    height: "auto",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: "1",
    padding: ".2em .6em .3em",
    borderRadius: ".25em",
    color: "black",
    verticalAlign: "baseline",
    margin: "2px",
    transition: "2s"
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Multimedia = (props) => {
    const classes = useStyles();
    const [imageData, setImageData] = useState([]);
    const [searchImageData, setSearchImageData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setEditOpenDialog] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [commentsOpenDialog, setCommentsOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [folderMode, setFolderMode] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [moreButton, setMoreButton] = useState(true);
    const {enqueueSnackbar} = useSnackbar();

    const isSubscriber = props.UserDetails.role === 2;

    const setCustomTags = (i) => {
        return (
            i.tags.map((t, i) => {
                return (
                    <div
                        key={i}
                        style={customTagStyle}>
                        {t.title}
                    </div>
                );
            })
        );
    };

    useEffect(() => {
        getImages(pageIndex);
        // eslint-disable-next-line
    }, [pageIndex]);

    useEffect(() => {
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    const getImages = (pageIndex, returnValue = 45) => {
        props.dispatch(SetSiteLoading(true));
        axios.get(baseUrl + "/fileManagers" + "?pageIndex=" + pageIndex + "&returnValue=" + returnValue, {headers: {Authorization: apiToken}})
            .then(async res => {
                res.data.data.length < returnValue && setMoreButton(false);
                setImageData([...imageData, ...res.data.data.map(item => (
                    {
                        id: item.fileManagerId,
                        folderId: item.folderId,
                        fileFormat: item.fileFormat,
                        src: getThumb(item.fileFormat, item.fileManagerId),
                        thumbnail: getThumb(item.fileFormat, item.fileManagerId),
                        thumbnailWidth: 320 * item.pictureRatio,
                        thumbnailHeight: 320,
                        caption: item.title,
                        tags: item.tags ? item.tags.map(it => {
                            return {value: it.itemId, title: it.name, tagType: it.tagType}
                        }) : [],
                    }
                ))]);
                props.dispatch(SetSiteLoading(false));
            })
            .catch(() =>
                props.dispatch(SetSiteLoading(false))
            );
    };

    const addPicture = ({nameForTarget, chosenPicture, selectedTags}) => {
        props.dispatch(SetSiteLoading(true));
        axios.post(
            baseUrl + "/picturearchives",
            {
                title: nameForTarget,
                fileManagerId: chosenPicture,
                tags: selectedTags.map(item => {
                    return {itemId: item.value, tagType: item.type}
                })
            },
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    const {data} = res.data;
                    setImageData([
                        ...imageData,
                        {
                            id: data.pictureArchiveId,
                            src: getUrl(data.fileManagerId),
                            thumbnail: getUrl(data.fileManagerId, 320),
                            thumbnailWidth: 320 * data.pictureRatio,
                            thumbnailHeight: 320,
                            caption: data.title,
                            tags: data.tags ? data.tags.map(it => {
                                return {value: it.itemId, title: it.name, tagType: it.tagType}
                            }) : [],
                        }
                    ]);
                    sendNotification(enqueueSnackbar, "تصویر به گالری اضافه شد");
                    setOpenDialog(false);
                    props.dispatch(SetSiteLoading(false))
                }
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            )
    };


    const updatePicture = ({editItemId, nameForTarget, chosenPicture, selectedTags}) => {
        props.dispatch(SetSiteLoading(true));
        axios.post(
            baseUrl + "/picturearchives/edit/" + editItemId,
            {
                title: nameForTarget,
                fileManagerId: chosenPicture,
                tags: selectedTags.map(item => {
                    return {itemId: item.value, tagType: item.type}
                })
            },
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    const {data} = res.data;
                    const image = imageData.filter(val => val.id === data.pictureArchiveId)[0];
                    if (image) {
                        const index = imageData.indexOf(image);
                        imageData[index] = {
                            id: data.pictureArchiveId,
                            src: getUrl(data.fileManagerId),
                            thumbnail: getUrl(data.fileManagerId, 320),
                            thumbnailWidth: 320 * data.pictureRatio,
                            thumbnailHeight: 320,
                            caption: data.title,
                            tags: data.tags ? data.tags.map(it => {
                                return {value: it.itemId, title: it.name, tagType: it.tagType}
                            }) : [],
                        };
                        setImageData([...imageData]);
                        setEditOpenDialog(false);
                    }
                    sendNotification(enqueueSnackbar, "تصویر با موفقیت ویرایش شد");
                    setOpenDialog(false);
                    props.dispatch(SetSiteLoading(false))
                }
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            )
    };

    const images = useMemo(() =>
        imageData.map((i) => {
            i.customOverlay = (
                <div
                    className="d-flex align-items-center justify-content-center flex-column"
                    style={captionStyle}
                >
                    <div className="text-center mx-2">{i.caption}</div>
                    <div className="d-flex justify-content-center flex-wrap">
                        {
                            i.hasOwnProperty('tags') &&
                            setCustomTags(i)
                        }
                    </div>
                </div>
            );
            return i;
        }), [imageData]);

    const currentItemId = images[currentImage] && images[currentImage].id;

    const removePictureArchive = async () => {
        if ((currentImage || currentImage === 0) && currentItemId) {
            await props.dispatch(SetSiteLoading(true));
            await axios.post(baseUrl + "/filemanagers/remove/" + currentItemId + "?removePhysically=true", {}, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        sendNotification(enqueueSnackbar, " عکس مورد نظر با موفقیت حذف شد");
                        setImageData(imageData.filter(x => x.id !== currentItemId));
                        props.dispatch(SetSiteLoading(false));
                        setOpenDeleteDialog(false);
                        setOpenGallery(false)
                    }
                })
                .catch(err => {
                        props.dispatch(SetSiteLoading(false));
                        if (err.response && err.response.status === 400)
                            sendErrorNotification(enqueueSnackbar, err.response.data.message);
                        else
                            sendErrorNotification(enqueueSnackbar);
                        return false;
                    }
                )
        }
    };

    const searchPictureArchive = async keyword => {
        if (keyword.length > 0) {
            await axios.get(baseUrl + "/pictureArchives/search" + (isSubscriber ? "/forSubscriber" : "") + "?keyword=" + keyword, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        setSearchImageData(res.data.data.map(item => (
                            {
                                id: item.pictureArchiveId,
                                src: getUrl(item.fileManagerId),
                                thumbnail: getUrl(item.fileManagerId, 320),
                                thumbnailWidth: 320 * item.pictureRatio,
                                thumbnailHeight: 320,
                                caption: item.title,
                                tags: item.tags ? item.tags.map(it => {
                                    return {value: it.itemId, title: it.name, tagType: it.tagType}
                                }) : [],
                            }
                        )))
                    }
                })
        }
    };

    const searchedImages = useMemo(() => searchImageData && searchImageData.map((i) => {
        i.customOverlay = (
            <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={captionStyle}
            >
                <div className="text-center mx-2">{i.caption}</div>
                <div className="d-flex justify-content-center flex-wrap">
                    {
                        i.hasOwnProperty('tags') &&
                        setCustomTags(i)
                    }
                </div>
            </div>
        );
        return i;
        // eslint-disable-next-line
    }), [searchImageData]);

    return (
        <>
            <div
                className="d-flex mb-2"
            >
                <Paper
                    onSubmit={res => res.preventDefault()}
                    component="form"
                    className={classes.root}
                >
                    <IconButton
                        disabled
                        className={classes.iconButton}
                        aria-label="menu"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <InputBase
                        id="search-input"
                        className={classes.input}
                        placeholder="جستجوی عکس"
                        inputProps={{'aria-label': 'search google maps'}}
                        onChange={data => searchPictureArchive(data.target.value)}
                        onClick={() => setSearchImageData([])}
                    />
                    {
                        searchImageData && searchImageData.length >= 0 ?
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="search"
                                onClick={() => {
                                    setSearchImageData(null);
                                    document.getElementById("search-input").value = "";
                                }}
                            >
                                <HighlightOffRoundedIcon/>
                            </IconButton>
                            :
                            <IconButton
                                className={classes.iconButton}
                                aria-label="search"
                                onClick={() => {
                                    setSearchImageData([]);
                                    document.getElementById("search-input").focus();
                                }}
                            >
                                <SearchIcon/>
                            </IconButton>
                    }
                </Paper>
            </div>
            {
                <div className="my-4 bg-white">
                    <Tabs
                        value={folderMode}
                        onChange={(event, newValue) => setFolderMode(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="action tabs example"
                    >
                        <Tab
                            label="فولدر ها"
                            {...a11yProps(1)}
                        />
                        <Tab
                            label="عکس ها"
                            {...a11yProps(0)}
                        />
                        <Tab
                            label="فیلم ها"
                            {...a11yProps(2)}
                        />
                    </Tabs>
                    {/*<ButtonGroup*/}
                    {/*    color="primary"*/}
                    {/*    aria-label="text primary button group"*/}
                    {/*    className="dir-ltr"*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*    >*/}
                    {/*        */}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*    >*/}
                    {/*        */}
                    {/*    </Button>*/}
                    {/*</ButtonGroup>*/}
                </div>
            }
            {
                searchedImages ?
                    searchedImages.length > 0 ?
                        <Gallery
                            images={searchedImages}
                            enableImageSelection={false}
                            tagStyle={{display: "none"}}
                            customControls={
                                [
                                    <div className="d-flex bg-dark w-100">
                                        <IconButton
                                            key="deleteImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        >
                                            <DeleteForeverRoundedIcon/>
                                        </IconButton>,
                                        <IconButton
                                            key="comments"
                                            className={classes.DeleteForeverRoundedIcon + " comments-button"}
                                            onClick={() => setCommentsOpenDialog(true)}
                                        >
                                            <CommentRoundedIcon/>
                                        </IconButton>,
                                        <IconButton
                                            key="editImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() => {
                                                setEditOpenDialog(true);
                                                setOpenDialog(false)
                                            }}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </div>
                                ]
                            }
                            currentImageWillChange={id => setCurrentImage(id)}
                        />
                        :
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <ImageSearchRoundedIcon style={{fontSize: "10vw"}}/>
                        </div>
                    :
                    folderMode === 1 ?
                        <Gallery
                            id="image-gallery"
                            isOpen={openGallery}
                            images={images}
                            onClose={() => setOpenGallery(false)}
                            enableImageSelection={false}
                            tagStyle={{display: "none"}}
                            backdropClosesModal
                            showImageCount={false}
                            showLightboxThumbnails={false}
                            customControls={
                                [
                                    <div className="d-flex bg-dark w-100">
                                        <IconButton
                                            key="deleteImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        >
                                            <DeleteForeverRoundedIcon/>
                                        </IconButton>
                                    </div>
                                ]
                            }
                            currentImageWillChange={id => setCurrentImage(id)}
                        />
                        :
                        (
                            folderMode === 0 ?
                                <FoldersMedia
                                    imageData={imageData}
                                    onClickMore={() => setPageIndex(pageIndex + 1)}
                                    moreButtonProp={moreButton}
                                />
                                :
                                <VideoGallery

                                />
                        )
            }
            <div className=" d-flex w-100 justify-content-center pt-5">
                {
                    folderMode === 1 &&
                    moreButton &&
                    <Button
                        onClick={() => setPageIndex(pageIndex + 1)}
                        variant='contained'
                        color="primary"
                        size="large"
                    >
                        بیشتر
                    </Button>
                }
            </div>
            <Dialog
                keepMounted
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                style={{zIndex: 2002}}
            >
                <DialogTitle id="alert-dialog-slide-title">حذف تصویر</DialogTitle>
                <DialogContent>
                    آیا از حذف این تصویر مطمئن هستید؟
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDeleteDialog(false)}
                        color="primary"
                        variant="outlined"
                        className="mx-2"
                    >
                        انصراف
                    </Button>
                    <Button
                        onClick={removePictureArchive}
                        color="secondary"
                        variant="outlined"
                    >
                        بله
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                keepMounted
                open={commentsOpenDialog}
                onClose={() => setCommentsOpenDialog(false)}
                style={{zIndex: 2002, maxHeight: '100vh'}}
                fullScreen
                TransitionComponent={Transition}
            >
                <IconButton
                    onClick={() => setCommentsOpenDialog(false)}
                    color="primary"
                    variant="outlined"
                    className="mx-2 position-fixed"
                    style={{left: '1rem', top: '1rem', zIndex: 99999}}
                >
                    <CloseIcon/>
                </IconButton>
            </Dialog>
        </>
    )
};

const mapState = states => ({
    UserDetails: states.userDetails
});
export default connect(mapState)(Multimedia);