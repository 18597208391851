import {combineReducers} from "redux";
import {authorization} from "./reducers/Athorization";
import {userDetails} from "./reducers/UserDetails";
import {siteLoading} from "./reducers/SiteLoading";
import {mediaDrawerHandler} from "./reducers/MediaDrawerHnadler";
import {userInformationDrawer} from "./reducers/UserInformationDrawer";
import {userDialogHandler} from "./reducers/UserDialogHandler";
import {CreateLocationHandler} from "./reducers/CreateLocationHandler";

export default combineReducers({
    authorization,
    userDetails,
    siteLoading,
    mediaDrawerHandler,
    CreateLocationHandler,
    userInformationDrawer,
    userDialogHandler
})