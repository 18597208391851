import React, {useEffect, useState} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {sendErrorNotification, sendNotification} from "../../functions";
import {makeStyles} from "@material-ui/core";
import {SetSiteLoading, SetUserDialog} from "../../redux/actions/actions";
import {apiToken} from "../../appConfig";
import {baseUrl} from "../../appConfig";
import Axios from "axios";
import {connect} from "react-redux";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

const Accessibility = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [tableData, setTableData] = useState({});

    const getTargets = async (index = 1) => {
        await props.dispatch(SetSiteLoading(true));
        await Axios.get(baseUrl + "/users/all?userRole=2&pageIndex=" + index + "&returnValue=12", {headers: {Authorization: apiToken}})
            .then(res => {
                const values = res.data.data.map(data => ({
                    id: data.userId,
                    data: [data.avatarId, data.userName, data.firstName + " " + data.lastName, data.role === 0 ? "admin" : (data.role === 1 ? "editor" : "subscriber")]
                }));
                setTableData({
                    values: values,
                    countAll: res.data.countAll
                });

            });
        await props.dispatch(SetSiteLoading(false));
    };

    const removeUser = async ID => {
        return await Axios.post(baseUrl + "/users/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "کاربر با موفقیت حذف شد");
                    return true;
                }
                return false;
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
                return false;
            })
    };

    const afterAdd = data => {
        setTableData({
            values : [{
                id: data.userId,
                data: [data.avatarId, data.userName, data.firstName + " " + data.lastName, "subscriber"]
            }, ...tableData.values],
            countAll : tableData.countAll + 1
        });
        props.dispatch(SetUserDialog(null));
    };

    useEffect(() => {
        getTargets().then();
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center" color="warning">
                        <div>
                            <h4 className={classes.cardTitleWhite}>جدول دسترسی ها</h4>
                            <p className="m-0">جدولی از دسترسی ها به ترتیب زمان به وجود آمدن</p>
                        </div>
                        <Tooltip title="افزودن شخص">
                            <IconButton
                                color="inherit"
                                onClick={() => props.dispatch(SetUserDialog(afterAdd))}
                            >
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                    </CardHeader>
                    <CardBody>
                        <Table
                            onPaging={(event, value) => getTargets(value)}
                            limitViews={12}
                            tableHeaderColor="warning"
                            tableHead={["آواتار", "نام کاربری", "نام", "هویت"]}
                            tableData={tableData.values || []}
                            totalAmount={tableData.countAll}
                            actions={{
                                onDelete: async (key, ID) => {
                                    removeUser(ID).then(res => {
                                        if (res) {
                                            setTableData({
                                                values: tableData.values.filter(val => val.id !== ID),
                                                countAll: tableData.countAll - 1
                                            })
                                        }
                                    });
                                },
                                onEdit: (key, ID) => {
                                    navigate("/admin/accessibility/editor/" + ID);
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default connect()(Accessibility);