import React, {useState} from 'react';
import './FolderItem.css'
import {makeStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import FolderThumb from '../../../assets/img/folder-thumbnail.png'
import DefaultThumb from '../../../assets/img/picture-folder-icon-52.png'
import LevelUpThumb from '../../../assets/img/upload-documents-icon-43257.png'
import {mouseX, mouseY, sendErrorNotification, sendNotification} from "../../../functions";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import {useSnackbar} from "notistack";
import {apiToken, baseUrl} from "../../../appConfig";
import {SetSiteLoading} from "../../../redux/actions/actions";
import axios from "axios";
import {connect} from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '2rem'
    },
    image: {
        position: 'relative',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: '100%',
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.3,
            },
            '& $imageMarked': {
                opacity: 0,
            }
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
}));

const FolderItem = ({add, all, title, deleteFromImageData, folderId, onDeleteFolder, selectedFolder, ...props}) => {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const [renaming, setRenaming] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [folderName, setFolderName] = useState('');

    const allowDrop = (ev) => {
        ev.preventDefault();
    };

    const onDrop = async (ev) => {
        ev.preventDefault();
        let id = ev.dataTransfer.getData("id");
        await props.dispatch(SetSiteLoading(true));
        await axios.post(
            baseUrl + `/filemanagers/folder/${id}?folderId=${folderId ? folderId : ''}`,
            {},
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, ` عکس مورد نظر به ${title}  اضافه شد `);
                    deleteFromImageData(id);
                    props.dispatch(SetSiteLoading(false));
                }
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            );
        // ev.target.appendChild(document.getElementById(data));
    };

    const openMenu = (ev) => {
        ev.preventDefault();
        setShowMenu(true);
        document.getElementById("customMenu" + folderId).style.top = mouseY(ev) + 'px';
        document.getElementById("customMenu" + folderId).style.left = mouseX(ev) + 'px';
    };

    const closeMenu = (ev) => {
        ev.preventDefault();
        setTimeout(
            () => setShowMenu(false),
            200
        )
    };

    const editFolderName = () => {
        setRenaming(false)
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            editFolderName();
        }
    };

    return (
        <div
            className="FolderItem m-4"
            onDrop={(event) => !add && onDrop(event)}
            onDragOver={(event) => !add && allowDrop(event)}
            onContextMenu={!all && !add && openMenu}
            onBlur={closeMenu}
            {...props}
        >
            <ButtonBase
                focusRipple
                key='dgdfgdf'
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                    width: '100%',
                    backgroundColor: folderId && (selectedFolder === folderId && '#00000034')
                }}
            >
                <span
                    className={classes.imageSrc}
                    style={{
                        backgroundImage: `url(${all ? LevelUpThumb : (add ? DefaultThumb : FolderThumb)})`,
                        backgroundSize: '80%',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <span className={classes.imageBackdrop}/>
                <span className={classes.imageButton}>
                    {
                        renaming ?
                            !add && !all &&
                            <TextField
                                autoFocus
                                variant='outlined'
                                label="نام فولدر"
                                value={folderName}
                                onChange={(e) => setFolderName(e.target.value)}
                                style={{backgroundColor: '#fff'}}
                                onBlur={editFolderName}
                                onKeyDown={handleKeyDown}
                            />
                            :
                            !add &&
                            <Typography
                                component="span"
                                variant="h6"
                                color="inherit"
                                className={classes.imageTitle}
                            >
                                {folderName.length > 0 ? folderName : title}
                                <span className={classes.imageMarked}/>
                            </Typography>
                    }
                </span>
            </ButtonBase>
            <div
                className={showMenu ? 'show' : "hide"}
                id={"customMenu" + folderId}
            >
                <MenuItem onClick={props.onDoubleClick}>Open</MenuItem>
                <MenuItem onClick={() => setRenaming(true)}>Rename</MenuItem>
                <MenuItem
                    onClick={() => {
                        setDeleting(true);
                        setShowMenu(false)
                    }}
                >
                    Delete {title}
                </MenuItem>
            </div>
            <Dialog
                keepMounted
                open={deleting}
                onClose={() => setDeleting(false)}
                style={{zIndex: 2002}}
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-slide-title">حذف فولدر</DialogTitle>
                <DialogContent>
                    آیا از حذف این فولدر مطمئن هستید؟
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleting(false)}
                        color="primary"
                        variant="outlined"
                        className="mx-2"
                    >
                        انصراف
                    </Button>
                    <Button
                        onClick={() => {
                            onDeleteFolder(folderId);
                            setDeleting(false);
                        }}
                        color="secondary"
                        variant="outlined"
                    >
                        بله
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect()(FolderItem);