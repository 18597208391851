import React, {useState} from 'react';
import {TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";

function PasswordInput(props) {
    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChange = (prop, event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const useStyles = makeStyles(theme => ({
        button: {
            position: "absolute",
            right: theme.direction === "ltr" ? 0 : null,
            left: theme.direction === "rtl" ? 0 : null,
            top: props.error ? "2.7rem" : null,
            bottom: props.error ? null : ".4rem",
        }
    }));
    const classes = useStyles();

    const {onChange} = props;
    const buttonStyle = {marginTop: props.helperText ? -11 : ""};
    return (
        <div
            className={"position-relative d-flex " + (props.variant === "standard" ? "align-items-end" : "align-items-center")}
        >
            <TextField
                {...props}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={
                    onChange ?
                        input => {
                            handleChange('password', input);
                            onChange(input);
                        }
                        :
                        input => handleChange('password', input)
                }
            />
            <div className={classes.button} style={buttonStyle}>
                <IconButton
                    style={{padding: props.variant === "standard" ? 6 : ""}}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                >
                    {values.showPassword ? <Visibility fontSize="small"/> : <VisibilityOff fontSize="small"/>}
                </IconButton>
            </div>
        </div>
    );
}

export default PasswordInput;