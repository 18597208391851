import {host, baseUrl} from './appConfig'
import videoThumb from "./assets/img/video-thumbnail.jpg";
import audioThumb from "./assets/img/mp3-logo.jpg";
import UnknownThumb from "./assets/img/Unknown_toxicity_icon.svg.png";

export const getUrl = (id, size = 0) => {
    return  id && (size === 0 ? host + "/download/" + id : baseUrl + "/FileManagers/ratio/" + id + "/" + size)
};

export const sendNotification = (enqueueSnackbar, message = "", variant = "success", time = 3000) => {
    enqueueSnackbar(message, {
        variant: variant,
        autoHideDuration: time
    });
    return null;
};

export const sendErrorNotification = (enqueueSnackbar, message = "خطایی صورت گرفته، دوباره امتحان کنید") => {
    enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 3000
    });
    return null;
};

export const concatArraysInObj = (object = {}) => {
    let newValue = [];
    const values = Object.values(object);
    values.forEach(value => {
        newValue = newValue.concat(value);
    });
    return newValue;
};

export const groupBy = (array = [], moreThanOne = false) => {
    const value = array.reduce((total, value) => {
        total[value] = (total[value] || 0) + 1;
        return total;
    }, {});
    if (moreThanOne && typeof moreThanOne === "boolean") {
        return Object.keys(value).filter(val => value[val] > 1);
    } else if (moreThanOne && typeof moreThanOne === "number") {
        return Object.keys(value).filter(val => value[val] >= moreThanOne);
    }
    return value;
};

export const generateRandomNumber = (B, A = 0) => {
    if (A === 0)
        return Math.random() * B;
    else
        return Math.floor(Math.random() * B) + A;
};

export const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const pauseAllAudios = () => {
    const audioElements = document.getElementsByTagName('audio');
    ([...Array(audioElements.length)]).map((d, i)=> {
        audioElements[i].pause();
    });
};



export function mouseX(evt) {
    if (evt.pageX) {
        return evt.pageX;
    } else if (evt.clientX) {
        return evt.clientX + (document.documentElement.scrollLeft ?
            document.documentElement.scrollLeft :
            document.body.scrollLeft);
    } else {
        return null;
    }
}

export function mouseY(evt) {
    if (evt.pageY) {
        return evt.pageY;
    } else if (evt.clientY) {
        return evt.clientY + (document.documentElement.scrollTop ?
            document.documentElement.scrollTop :
            document.body.scrollTop);
    } else {
        return null;
    }
}

export const getThumb = (format, id) => {
    const videoFormats = ['mp4', 'mkv'];
    const audioFormats = ['mp3'];
    const imageFormats = ['png', 'jpg', 'jpeg'];
    if (videoFormats.includes(format)) {
        return videoThumb;
    } else if (audioFormats.includes(format)) {
        return audioThumb;
    } else if (imageFormats.includes(format)) {
        return getUrl(id)
    } else {
        return UnknownThumb;
    }
};