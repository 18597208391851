import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CardBody from "../../Card/CardBody";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import './LivingPlace.css'
import Grid from "@material-ui/core/Grid";
import GridItem from "../../Grid/GridItem";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Collapse} from "@material-ui/core";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';
import GridContainer from "../../Grid/GridContainer";

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let dataDetails = {
    livingPlace: {
        previous: "",
        current: "",
        moreDetails: []
    }
};

const LivingPlace = ({onChange, targetType, defaultValue, forStudy}) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [size, setSize] = React.useState("1");
    const [rtlNameForSetDetails, setRtlNameForSetDetails] = React.useState("");
    const [typeForSetDetails, setTypeForSetDetails] = React.useState("text");
    let [livingData, setLivingData] = useState(null);
    let [collapses, setCollapses] = useState('');

    useEffect(() => {
        dataDetails = {
            livingPlace: {
                previous: "",
                current: "",
                moreDetails: []
            }
        };
        if (defaultValue) {
            dataDetails.livingPlace = defaultValue;
            const valueData = [
                {
                    name: "current",
                    rtlName: 'محل اقامت فعلی',
                    type: 'textarea',
                    size: 4,
                    value: defaultValue.current
                },
                {
                    name: "previous",
                    rtlName: 'محل اقامت سابق',
                    type: 'textarea',
                    size: 4,
                    value: defaultValue.previous
                }
            ];
            setLivingData(valueData)
        } else {
            setLivingData([
                {
                    name: "current",
                    rtlName: `${targetType === 0 ? "محل اقامت" : "مکان"} فعلی`,
                    type: 'textarea',
                    size: 4,
                    value: ""
                },
                {
                    name: "previous",
                    rtlName: `${targetType === 0 ? "محل اقامت" : "مکان"} سابق`,
                    type: 'textarea',
                    size: 4,
                    value: ""
                }
            ])
        }
        // if (targetId) {
        //     Axios.get(baseUrl + "/targets/" + targetId, {headers: {Authorization: apiToken}})
        //         .then(res => {
        //             if (res.status === 200) {
        //                 const x = res.data.data.details;
        //                 // dataDetails.livingPlace = x.livingPlace;
        //                 setData([{
        //                     name: "current",
        //                     rtlName: 'محل اقامت فعلی',
        //                     type: 'textarea',
        //                     size: 4,
        //                     value: x.livingPlace && x.livingPlace.current
        //                 },
        //                     {
        //                         name: "previous",
        //                         rtlName: 'محل اقامت سابق',
        //                         type: 'textarea',
        //                         size: 4,
        //                         value: x.livingPlace && x.livingPlace.previous
        //                     },
        //                 ]);
        //             }
        //         })
        //         .catch(() => {
        //             setData([
        //                 {
        //                     name: "current",
        //                     rtlName: 'محل اقامت فعلی',
        //                     type: 'textarea',
        //                     size: 4,
        //                     value: ""
        //                 },
        //                 {
        //                     name: "previous",
        //                     rtlName: 'محل اقامت سابق',
        //                     type: 'textarea',
        //                     size: 4,
        //                     value: ""
        //                 },
        //             ]);
        //         });
        // }
        // eslint-disable-next-line
    }, [defaultValue]);

    function handleChangeDialog() {
        setOpenDialog(!openDialog)
    }

    async function setDataPost(d, value) {
        if (d.name === "current" || d.name === "previous") {
            dataDetails.livingPlace[d.name] = value
        } else {
            dataDetails.livingPlace.moreDetails = dataDetails.livingPlace.moreDetails.filter(object => object.rtlName !== d.rtlName);
            await dataDetails.livingPlace.moreDetails.push({
                rtlName: d.rtlName,
                type: d.type,
                value: value,
                size: d.size
            });
        }
        onChange(dataDetails);
    }

    function removeItem(item) {
        dataDetails.livingPlace.moreDetails = dataDetails.livingPlace.moreDetails.filter(object =>
            object.rtlName !== item.rtlName
        );
        setLivingData(livingData.filter(object =>
            object.rtlName !== item.rtlName
        ));
        onChange(dataDetails);
    }

    return (
        <Card className="mb-3">
            <CardHeader stats icon>
                <CardIcon color="primary">
                    <LocationOnRoundedIcon/>
                </CardIcon>
                {
                    forStudy ?
                        <h3 className="p-3" style={{color: "#000"}}>{targetType === 0 ? "محل اقامت" : "مکان"}</h3>
                        :
                        <div className="d-flex align-items-center">
                            <h3 className="p-3" style={{color: "#000"}}>{targetType === 0 ? "محل اقامت" : "مکان"}</h3>
                            <HtmlTooltip
                                placement="top"
                                title={
                                    <div className="d-flex flex-column">
                                        <span>
                                             اطلاعات محل اقامت سوژه را وارد کنید.
                                        </span>
                                        <span>
                                            برای اضافه کردن فیلد مورد نیاز از دکمه ی مثبت زیر استفاده کنید.
                                        </span>
                                    </div>
                                }
                            >
                                <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                            </HtmlTooltip>
                        </div>
                }
            </CardHeader>
            <CardBody>
                {
                    !forStudy ?
                        <>
                            <Grid
                                className="d-flex flex-wrap align-items-center"
                            >
                                {
                                    livingData &&
                                    livingData.map((item, key) =>
                                        <GridItem
                                            key={key}
                                            xs={12}
                                            sm={6}
                                            lg={item.size * 3}
                                        >
                                            <TextField
                                                variant="outlined"
                                                value={item.value}
                                                label={item.rtlName}
                                                type={item.type}
                                                className="my-2 text-single-information"
                                                onChange={(e) => {
                                                    setDataPost(item, e.target.value).then();
                                                    livingData[key].value = e.target.value;
                                                    setLivingData([...livingData])
                                                }}
                                            />
                                        </GridItem>
                                    )
                                }
                                {
                                    dataDetails.livingPlace.moreDetails.map((data, i) =>
                                        <GridItem
                                            key={i}
                                            style={{position: "relative"}}
                                            className="text-field-parent"
                                            xs={12}
                                            sm={6}
                                            lg={data.size * 3}
                                        >
                                            <TextField
                                                variant="outlined"
                                                defaultValue={data.value}
                                                label={data.rtlName}
                                                type={data.type}
                                                className="my-2 text-single-information"
                                                onChange={(e) =>
                                                    setDataPost(data, e.target.value)
                                                }
                                            />
                                            <IconButton
                                                onClick={() => removeItem(data)}
                                                className="icon-button-mini"
                                            >
                                                <CloseRoundedIcon/>
                                            </IconButton>
                                        </GridItem>
                                    )
                                }
                                <GridItem sm={3}>
                                    <IconButton
                                        color="primary"
                                        aria-label="add an alarm"
                                        onClick={handleChangeDialog}
                                    >
                                        <AddRoundedIcon/>
                                    </IconButton>
                                </GridItem>
                            </Grid>
                            <Dialog
                                open={openDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleChangeDialog}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">{"فیلد مورد نظر خود را بسازید"}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        variant="outlined"
                                        label="عنوان"
                                        type="text"
                                        className="my-2 w-100"
                                        value={rtlNameForSetDetails}
                                        onChange={(e) =>
                                            setRtlNameForSetDetails(e.target.value)
                                        }
                                    />
                                    <div className="d-flex justify-content-between align-items-end">
                            <span className="mb-2">
                                طول:
                            </span>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            name="position"
                                            defaultValue="1"
                                            onChange={(e) => setSize(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio color="primary"/>}
                                                label="1"
                                                labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="2"
                                                control={<Radio color="primary"/>}
                                                label="2"
                                                labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="3"
                                                control={<Radio color="primary"/>}
                                                label="3"
                                                labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="4"
                                                control={<Radio color="primary"/>}
                                                label="4"
                                                labelPlacement="top"
                                            />
                                        </RadioGroup>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleChangeDialog} color="primary">
                                        لغو
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            handleChangeDialog();
                                            dataDetails.livingPlace.moreDetails = [
                                                ...dataDetails.livingPlace.moreDetails,
                                                {
                                                    rtlName: rtlNameForSetDetails,
                                                    type: typeForSetDetails,
                                                    size: parseInt(size),
                                                    value: ""
                                                }
                                            ];
                                            setRtlNameForSetDetails("");
                                            setTypeForSetDetails("");
                                        }}
                                        color="primary"
                                    >
                                        تایید
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                        :
                        <List>
                            <ListItem
                                className="d-flex justify-content-center cursor-pointer"
                                onClick={() => {
                                    collapses === "current"
                                        ?
                                        setCollapses("")
                                        :
                                        setCollapses("current")
                                }}
                            >
                                <span
                                    className="p-3 m-0"
                                    style={{
                                        color: "#8e24aa",
                                        fontSize: "1rem",
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {targetType === 0 ? "محل اقامت" : "مکان"} فعلی
                                </span>
                                <IconButton>
                                    {
                                        collapses === "current"
                                            ?
                                            <ExpandLessRoundedIcon/>
                                            :
                                            <KeyboardArrowDownRoundedIcon/>
                                    }
                                </IconButton>
                            </ListItem>
                            <Collapse
                                in={collapses === "current"}
                                style={{
                                    backgroundColor: "#e0e0e0"
                                }}
                            >
                                <Divider className="w-100"/>
                                <div
                                    className="p-3"
                                    style={{
                                        minHeight: '3rem'
                                    }}
                                >
                                    <Typography>
                                        {defaultValue ? defaultValue.current : 'ندارد'}
                                    </Typography>
                                </div>
                            </Collapse>
                            <ListItem
                                className="d-flex justify-content-center cursor-pointer"
                                onClick={() => {
                                    collapses === "prev"
                                        ?
                                        setCollapses("")
                                        :
                                        setCollapses("prev")
                                }}
                            >
                                <span
                                    className="p-3 m-0"
                                    style={{
                                        color: "#8e24aa",
                                        fontSize: "1rem",
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {targetType === 0 ? "محل اقامت" : "مکان"} سابق
                                </span>
                                <IconButton>
                                    {
                                        collapses === "prev"
                                            ?
                                            <ExpandLessRoundedIcon/>
                                            :
                                            <KeyboardArrowDownRoundedIcon/>
                                    }
                                </IconButton>
                            </ListItem>
                            <Collapse
                                in={collapses === "prev"}
                                style={{
                                    backgroundColor: "#e0e0e0"
                                }}
                            >
                                <Divider className="w-100"/>
                                <div
                                    className="p-3"
                                    style={{
                                        minHeight: '3rem'
                                    }}
                                >
                                    <Typography>
                                        {defaultValue ? defaultValue.previous : 'ندارد'}
                                    </Typography>
                                </div>
                            </Collapse>
                            {
                                defaultValue && defaultValue.moreDetails && defaultValue.moreDetails.map((d) =>
                                    <>
                                        <ListItem
                                            className="d-flex justify-content-center cursor-pointer"
                                            onClick={() => {
                                                collapses === d.rtlName
                                                    ?
                                                    setCollapses("")
                                                    :
                                                    setCollapses(d.rtlName);
                                            }}
                                        >
                                            <span
                                                className="p-3 m-0"
                                                style={{
                                                    color: "#8e24aa",
                                                    fontSize: "1rem",
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {d.rtlName}
                                            </span>
                                            <IconButton>
                                                {
                                                    collapses === d.rtlName
                                                        ?
                                                        <ExpandLessRoundedIcon/>
                                                        :
                                                        <KeyboardArrowDownRoundedIcon/>
                                                }
                                            </IconButton>
                                        </ListItem>
                                        <Collapse
                                            in={collapses === d.rtlName}
                                            style={{
                                                backgroundColor: "#e0e0e0"
                                            }}
                                        >
                                            <Divider className="w-100"/>
                                            <div
                                                className="p-3"
                                                style={{
                                                    minHeight: '3rem'
                                                }}
                                            >
                                                <Typography>
                                                    {d ? d.value : 'ندارد'}
                                                </Typography>
                                            </div>
                                        </Collapse>
                                    </>
                                )
                            }
                        </List>
                }
            </CardBody>
        </Card>
    );
};

LivingPlace.propTypes = {
    defaultValue: PropTypes.array,
    onChange: PropTypes.func
};

export default LivingPlace;