import React, {useEffect, useState} from 'react';
import {SetSiteLoading} from "../../redux/actions/actions";
import {apiToken, baseUrl} from "../../appConfig";
import {DefaultPlayer as Video} from 'react-html5video';
import axios from "axios";
import {connect} from "react-redux";
import {getThumb, getUrl, sendNotification} from "../../functions";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import "./VideoGallery.css"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VideoGallery = (props) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [moreButton, setMoreButton] = useState(true);
    const {enqueueSnackbar} = useSnackbar();
    const [videoData, setVideoData] = useState([]);
    const [currentID, setCurrentID] = useState([]);
    const [openModalAddFolder, setOpenModalAddFolder] = useState(false);

    const getVideoFiles = (pageIndex, returnValue = 45) => {
        props.dispatch(SetSiteLoading(true));
        axios.get(baseUrl + "/fileManagers" + "?type=video&pageIndex=" + pageIndex + "&returnValue=" + returnValue, {headers: {Authorization: apiToken}})
            .then(async res => {
                res.data.data.length < returnValue && setMoreButton(false);
                setVideoData([...videoData, ...res.data.data.map(item => (
                    {
                        id: item.fileManagerId,
                        fileFormat: item.fileFormat,
                        src: getThumb(item.fileFormat, item.fileManagerId),
                        thumbnail: getThumb(item.fileFormat, item.fileManagerId),
                        thumbnailWidth: 320 * item.pictureRatio,
                        thumbnailHeight: 320,
                        caption: item.title,
                        tags: item.tags ? item.tags.map(it => {
                            return {value: it.itemId, title: it.name, tagType: it.tagType}
                        }) : [],
                    }
                ))]);
                props.dispatch(SetSiteLoading(false));
            })
            .catch(() =>
                props.dispatch(SetSiteLoading(false))
            );
    };

    useEffect(() => {
        getVideoFiles(pageIndex)
    }, [pageIndex]);

    const removeMedia = () => {
        axios.post(baseUrl + `/filemanagers/remove/${currentID}`, {},{headers: {Authorization: apiToken}})
            .then(r => {
                if (r.status === 200) {
                    sendNotification(enqueueSnackbar, "رسانه حذف شد");
                    setOpenModalAddFolder(false);
                    setVideoData(videoData.filter(x => x.id !== currentID))
                }
            })
            .catch(r => {
                    sendNotification(enqueueSnackbar, r.message, "error");
                    setOpenModalAddFolder(false)
            })
    };

    return (
        <div className="d-flex justify-content-center dir-ltr">
            {
                videoData.length > 0 &&
                videoData.map((video) =>
                    <div
                        style={{height: "30rem", width: "30rem"}}
                        className="m-1 position-relative"
                    >
                        <Video
                            loop
                            muted
                            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                            poster="http://sourceposter.jpg"
                            onCanPlayThrough={() => {
                                // Do stuff
                            }}
                            style={{height: "30rem", width: "30rem"}}
                        >
                            <source src={getUrl(video.id)} type="video/webm"/>
                            <track
                                label="English"
                                kind="subtitles"
                                srcLang="en"
                                src="http://source.vtt"
                                default
                            />
                        </Video>
                        <div
                            style={{top: ".5rem", left: ".5rem"}}
                            className="position-absolute"
                            onClick={() => {
                                setCurrentID(video.id);
                                setOpenModalAddFolder(true)
                            }}
                        >
                            <DeleteForeverRoundedIcon/>
                        </div>
                    </div>
                )

            }
            <Dialog
                keepMounted
                open={openModalAddFolder}
                onClose={() => setOpenModalAddFolder(false)}
                style={{zIndex: 2002}}
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-slide-title">آیا از حذف این رسانه مطمئن هستید؟</DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenModalAddFolder(false)}
                        color="primary"
                        variant="outlined"
                        className="mx-2"
                    >
                        انصراف
                    </Button>
                    <Button
                        onClick={() => removeMedia()}
                        color="secondary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect()(VideoGallery);