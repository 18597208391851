import React, {useEffect} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import PoliticalPartyTable from "./PoliticalPartyTable/PoliticalPartyTable";
import RelationTypeTable from "./RelationTypeTable/RelationTypeTable";
import LocationTypeTable from "./LocationTypeTable/LocationTypeTable";
import SocialMediaTable from "./SocialMediaTable/SocialMediaTable";


const OtherSections = () => {
    useEffect(() => {
        document.getElementById('mainPanel').scrollTop = 0;
    }, []);
    return (
        <GridContainer>
            <SocialMediaTable/>
            <PoliticalPartyTable/>
            <RelationTypeTable/>
            <LocationTypeTable/>
        </GridContainer>
    );
};

export default OtherSections;