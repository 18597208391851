import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import {DataTypeProvider, EditingState} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import Chip from "@material-ui/core/Chip";
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import GavelIcon from '@material-ui/icons/Gavel';
import HelpIcon from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Axios from "axios";
import {apiToken} from "../../../appConfig";
import {baseUrl} from "../../../appConfig";
import './PoliticalTable.css'
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {getUrl, sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import {connect} from "react-redux";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';
import PoliticalPartyHandleDialog
    from "../../../views/OtherSections/PoliticalPartyTable/PoliticalPartyHandleDialog/PoliticalPartyHandleDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddPoliticalDialog = ({handleClose, open, loading, selectImage, chosenPicture}) => {
    let title = "";
    let description = "";
    let avatarId = null;
    const {enqueueSnackbar} = useSnackbar();

    async function CreateTarget() {
        loading(true);
        await Axios.post(
            baseUrl + "/politicalparties",
            {
                title: title,
                description: description,
                avatarId: avatarId
            },
            {headers: {Authorization: apiToken}}
        )
            .then(async res => {
                if (res.status === 200) {
                    await sendNotification(enqueueSnackbar, "حزب مورد نظر شما اضافه شد");
                    loading(false);
                    handleClose()
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                }
                loading(false);
            })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"حزب مورد نظر را اضافه کنید"}</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="عنوان"
                    type="text"
                    className="my-2 w-100"
                    onChange={(e) =>
                        title = e.target.value
                    }
                />
                <TextField
                    variant="outlined"
                    label="توضیحات"
                    rows={3}
                    multiline
                    type="text"
                    className="my-2 w-100"
                    onChange={(e) =>
                        description = e.target.value
                    }
                />
                {
                    !chosenPicture &&
                    <Fab
                        className="w-100 mt-2"
                        variant="extended"
                        color="secondary"
                        onClick={selectImage}
                    >
                        <span>انتخاب تصویر</span>
                    </Fab>
                }
                {
                    chosenPicture &&
                    (
                        <div
                            className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light">
                            <img
                                className="h-auto"
                                style={{maxWidth: "100%", maxHeight: "20rem"}}
                                src={getUrl(chosenPicture, 500)}
                                alt="feature"
                            />
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    لغو
                </Button>
                <Button
                    onClick={() =>
                        CreateTarget()
                    }
                    color="primary"
                >
                    تایید
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const formatterComponent = ({value, ...rest}) => {
    if (rest.column.type === "select" && value)
        return (
            <Chip
                style={{
                    backgroundColor: "#35909a",
                    borderRadius: ".3rem",
                    maxWidth: "100%"
                }}
                color="secondary"
                label={value.label}
            />
        );
};

const promiseOptions = (inputValue = "", url) => {
    return new Promise(resolve => {
        Axios.get(
            baseUrl + `/${url}/search/${inputValue}`,
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    resolve(
                        res.data.data.map((x) => {
                            return {value: x.politicalPartyId, label: x.title}
                        })
                    );
                }
            })
    });
};

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

let dialogRef;
const PoliticalTable = (
    {
        onChange,
        defaultValue = [],
        relationType,
        showAddCommand = true,
        showEditCommand = true,
        showDeleteCommand = true,
        ...props
    }
) => {
    // const [chosenPicture, setChosenPicture] = useState(null);
    const [columns] = useState([
        {name: 'politicalParties', title: 'حزب سیاسی', type: 'select', url: 'politicalparties'},
        {name: 'description', title: 'توضیحات', type: 'text'}
    ]);
    const [rows, setRows] = useState([]);
    const booleanColumns = ['politicalParties'];
    const [tableData, setTableData] = useState({
        data: [],
        countAll: 0
    });

    useEffect(() => {
        if (defaultValue.length > 0) {
            setRows(defaultValue)
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    const editColumnMessages = {
        addCommand: <AddCircleTwoToneIcon/>,
        editCommand: <EditTwoToneIcon/>,
        deleteCommand: <DeleteForeverTwoToneIcon color="secondary"/>,
        commitCommand: <SaveTwoToneIcon/>,
        cancelCommand: <CancelPresentationTwoToneIcon color="secondary"/>,
    };

    const editorComponent = ({value, onValueChange, column}) => {
        if (column.type === "select")
            return (
                <div className="position-relative">
                    <AsyncSelect
                        isRtl
                        cacheOptions
                        defaultOptions
                        value={value}
                        placeholder="انتخاب کنید ..."
                        noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                        loadingMessage={() => "در حال بارگزاری .."}
                        styles={{menu: base => ({...base, zIndex: 555555})}}
                        loadOptions={inputValue =>
                            promiseOptions(inputValue, column.url, relationType)
                        }
                        onChange={res => {
                            onValueChange(res);
                        }}
                    />
                    {column.name === "politicalParties" &&
                    <PlaylistAddRoundedIcon
                        className="position-absolute cursor-pointer"
                        style={{
                            top: ".59rem",
                            left: "3rem",
                            width: "1.5rem",
                            height: "1.5rem"
                        }}
                        onClick={() => dialogRef.setCreateOrEditDialog(true)}
                    />
                    }
                </div>
            );
    };

    const TypeProvider = props => (
        <DataTypeProvider
            formatterComponent={formatterComponent}
            editorComponent={editorComponent}
            {...props}
        />
    );

    const onCreatePoliticalParty = (data, isEditMode) => {
        if (isEditMode) {
            const currentData = tableData.data.filter(val => val.id === data.politicalPartyId)[0];
            if (currentData) {
                const index = tableData.data.indexOf(currentData);
                tableData.data[index].data = [data.politicalPartyId, data.avatarId, data.title, data.description];
                setTableData({...tableData});
            }
        } else
            setTableData(
                {
                    data: [{
                        id: data.politicalPartyId,
                        data: [data.politicalPartyId, data.avatarId, data.title, data.description]
                    }, ...tableData.data],
                    countAll: tableData.countAll + 1
                }
            )
    };

    const commitChanges = ({added, changed, deleted}) => {
        let changedRows;
        if (added && added[0].politicalParties) {
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    targetPoliticalAttitudeId: null,
                    ...row,
                })),
            ];
        }
        if (changed) {
            changedRows = rows.map((row, index) => (changed[index] ? {...row, ...changed[index]} : row));
        }
        if (deleted) {
            changedRows = rows.filter(row => row !== rows[deleted[0]]);
        }
        showAddCommand && onChange(changedRows);
        setRows(changedRows);
    };

    return (
        <Card  className="relation-table">
            <CardHeader stats icon>
                <CardIcon color="green">
                    <GavelIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3" style={{color: "#000"}}>نگرش سیاسی</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    در این قسمت نگرش سیاسی سوژه را مشخص میکنیم.
                                </span>
                                <span>
                                    برای اضافه کردن حذب سیاسی میتوانید به صفحه ی دیگر بخش ها مراجعه کنید و یا در فیلد حذب سیاسی روی آیکون افزودن حذب سیاسی کلیک کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardBody>
                {/*eslint-disable-next-line*/}
                <Grid
                    rows={rows}
                    columns={columns}
                >
                    <TypeProvider
                        for={booleanColumns}
                    />
                    <EditingState
                        onCommitChanges={commitChanges}
                    />
                    <Table/>
                    <TableHeaderRow/>
                    <TableEditRow/>
                    <TableEditColumn
                        showAddCommand={showAddCommand}
                        showEditCommand={showEditCommand}
                        showDeleteCommand={showDeleteCommand}
                        messages={editColumnMessages}
                    />
                </Grid>
            </CardBody>
            <PoliticalPartyHandleDialog
                ref={ref => {
                    dialogRef = ref
                }}
                onSubmit={onCreatePoliticalParty}
            />
        </Card>
    );
};

PoliticalTable.propTypes = {
    defaultValue: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    showAddCommand: PropTypes.bool,
    showEditCommand: PropTypes.bool,
    showDeleteCommand: PropTypes.bool
};

const mapState = () => ({});
export default connect(mapState)(PoliticalTable);