import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from 'react-router-dom'
import {getUrl} from "../../functions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    name: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    TargetStudyItem: {
        width: '13rem',
        height: '13rem',
        borderRadius: '1rem',
        border: '2px solid #ab47bc70',
        cursor: 'pointer',
        ' :hover': {
            background: '#26c6da15',
        },
        boxShadow: '0 0 16px 5px #ab47bc37'
    },
}));

const TargetStudyItem = ({avatarId, name, targetId, relation}) => {
    const classes = useStyles();
    return (
        <Tooltip
            title={relation}
            aria-label="add"
        >
            <div
                onClick={()=> window.open(`/admin/targets/study/${targetId}`)}
                className={"d-flex flex-column justify-content-center align-items-center m-4 p-4 " + classes.TargetStudyItem}
            >
                <Avatar
                    alt={name}
                    src={getUrl(avatarId)}
                    className={classes.large}
                />
                <Typography
                    className={"mt-3 text-center " + classes.name}
                    variant="subtitle1"
                    gutterBottom
                >
                    {name}
                </Typography>
            </div>
        </Tooltip>
    );
};

export default TargetStudyItem;