import React, {forwardRef, useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import CardBody from "../../Card/CardBody";
import {DataTypeProvider, EditingState} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import Chip from "@material-ui/core/Chip";
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import PropTypes from 'prop-types';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Axios from "axios";
import {apiToken} from "../../../appConfig";
import {baseUrl} from "../../../appConfig";
import './RelationTable.css'
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {SetSiteLoading} from "../../../redux/actions/actions";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import {connect} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

import {withStyles} from '@material-ui/core/styles';

import HelpIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '2px solid #fff',
    },
}))(Tooltip);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddTargetDialog = ({handleClose, open, loading}) => {
    let [nameForTarget, setNameForTarget] = useState("");
    let [typeForTarget, setTypeForTarget] = useState("0");
    const {enqueueSnackbar} = useSnackbar();

    async function CreateTarget() {
        loading(true);
        await Axios.post(
            baseUrl + "/targets",
            {
                name: nameForTarget,
                details: {},
                familyRelations: [],
                relations: [],
                targetType: parseInt(typeForTarget)
            },
            {headers: {Authorization: apiToken}}
        )
            .then(async res => {
                if (res.status === 200) {
                    await sendNotification(enqueueSnackbar, "سوژه مورد نظر شما اضافه شد");
                    loading(false);
                    handleClose()
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                }
                loading(false);
            })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"سوژه ی مورد نظر را اضافه کنید"}</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="نام"
                    type="text"
                    className="my-2 w-100"
                    onChange={(e) =>
                        setNameForTarget(e.target.value)
                    }
                />
                <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="0"
                    onChange={(e) => setTypeForTarget(e.target.value)}
                >
                    <FormControlLabel
                        value="0"
                        control={<Radio color="primary"/>}
                        label="حقیقی"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        value="1"
                        control={<Radio color="primary"/>}
                        label="حقوقی"
                        labelPlacement="top"
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    لغو
                </Button>
                <Button
                    onClick={() =>
                        CreateTarget()
                    }
                    color="primary"
                >
                    تایید
                </Button>
            </DialogActions>
        </Dialog>
    )
};


const formatterComponent = ({value, ...rest}) => {
    if (rest.column.type === "select" && value)
        return (
            <Chip
                style={{
                    backgroundColor: "#ef8a00",
                    borderRadius: ".3rem",
                    maxWidth: "100%"
                }}
                color="primary"
                label={value.label}
            />
        );
};

const RelationsTable = ({onChange, defaultValue = [], relationType, project, ...props}) => {
    const [columns] = useState([
        {name: 'target', title: 'سوژه', type: 'select', url: 'targets'},
        {name: 'relationType', title: 'نوع ارتباط', type: 'select', url: 'relationtypes'},
        {name: 'relation', title: 'ارتباط', type: 'select', url: 'relations'},
        !project && {name: 'targetRelation', title: 'ارتباط متقابل', type: 'select', url: 'relations'},
        {name: 'description', title: 'توضیحات', type: 'text'}
    ]);
    const [rows, setRows] = useState([]);
    const [relationTypeSelected, setRelationType] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const booleanColumns = ['target', 'relation', 'relationType', 'targetRelation'];
    useEffect(() => {
        if (defaultValue.length > 0)
            setRows(defaultValue);
        // eslint-disable-next-line
    }, [defaultValue]);

    const editColumnMessages = {
        addCommand: <AddCircleTwoToneIcon/>,
        editCommand: <EditTwoToneIcon/>,
        deleteCommand: <DeleteForeverTwoToneIcon color="secondary"/>,
        commitCommand: <SaveTwoToneIcon/>,
        cancelCommand: <CancelPresentationTwoToneIcon color="secondary"/>,
    };

    const handleChangeDialog = () => setOpenDialog(!openDialog);

    const promiseOptions = (inputValue = "", url, type) => {
        return new Promise((resolve, reject) => {
            Axios.get(
                baseUrl + `/${url}/search?query=` + inputValue + (type ? `&typeId=${type}` : ""),
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    if (res.status === 200)
                        resolve(res.data.data);
                    else
                        reject(res)
                })
                .catch(reject)
        });
    };

    const checkField = (column, row) => {
        if (column.name === "relationType") {
            return (!row.target);
        }
        if (column.name === "relation") {
            return (!row.relationType);
        }
        if (column.name === "targetRelation") {
            return (!row.relation);
        }
        if (column.name === "target") {
            return false
        }
    };

    const editorComponent = ({value, onValueChange, column, row}) => {
        if (column.type === "select")
            return (
                <div className="position-relative">
                    <AsyncSelect
                        isRtl
                        cacheOptions
                        defaultOptions
                        value={value}
                        placeholder="انتخاب کنید ..."
                        noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                        loadingMessage={() => "در حال بارگزاری .."}
                        styles={{menu: base => ({...base, zIndex: 555555})}}
                        loadOptions={async inputValue =>
                            await promiseOptions(
                                inputValue,
                                column.url,
                                (column.name === "relation" || column.name === "targetRelation") ? (
                                    row.target &&
                                    relationTypeSelected[`${row.target.value}`] &&
                                    relationTypeSelected[`${row.target.value}`]
                                ) : null
                            )
                        }
                        onChange={async res => {
                            if (column.name === "relationType" && row.target) {
                                relationTypeSelected[row.target.value] = parseInt(res.value);
                                await setRelationType({...relationTypeSelected});
                            }
                            if (column.name === "target") {
                                await setRelationType({
                                    [res.value]: 1
                                });
                            }
                            onValueChange(res);
                        }}
                        isDisabled={checkField(column, row)}
                    />
                    {
                        column.name === "target" &&
                        <PlaylistAddRoundedIcon
                            className="position-absolute cursor-pointer"
                            style={{
                                top: ".59rem",
                                left: "3rem",
                                width: "1.5rem",
                                height: "1.5rem"
                            }}
                            onClick={handleChangeDialog}
                        />
                    }
                </div>
            );
    };

    const TypeProvider = props => (
        <DataTypeProvider
            formatterComponent={formatterComponent}
            editorComponent={editorComponent}
            {...props}
        />
    );

    const commitChanges = ({added, changed, deleted}) => {
        let changedRows;
        if (added && added[0].relation && added[0].target) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            changedRows = rows.map((row, index) => (changed[index] ? {...row, ...changed[index]} : row));
        }
        if (deleted) {
            changedRows = rows.filter(row => row !== rows[deleted[0]]);
        }
        if (added) {
            if (added[0].relation && added[0].target) {
                onChange(changedRows);
                setRows(changedRows);
            }
        } else {
            onChange(changedRows);
            setRows(changedRows);
        }
    };

    return (
        <Card className="relation-table">
            <CardHeader stats icon>
                <CardIcon color="warning">
                    <AccountTreeRoundedIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3" style={{color: "#000"}}>ارتباط ها</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    در این بخش میتوانید افرادی را که سوژه با انها در ارتباط است را اضافه کنید به عنوان مثال (پدر، مادر، همکار، کارمندان، مدیران و...)
                                </span>
                                <span className="my-1">
                                    هر ارتباط شامل چهار بخش می باشد (سوژه، نوع ارتباط، ارتباط و توضیحات) حتما فیلد ها به ترتیب پر شوند
                                </span>
                                <span className="my-1">
                                    سوژه: می توانید سوژه ی دلخواه را جستجو و انتخاب کنید، اگر سوژه از قبل وجود نداشت، می توانید آن را از طریق آیکون کنار فیلد انتخاب سوژه اضافه کنید.
                                </span>
                                <span className="my-1">
                                    ارتباط: برای اضافه کردن ارتباط و نوع ارتباط می توانید به صفحه ی ارتباط ها مراجعه کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardBody>
                <Grid
                    rows={rows}
                    columns={columns.filter(x => x !== false)}
                >
                    <TypeProvider
                        for={booleanColumns}
                    />
                    <EditingState
                        onCommitChanges={commitChanges}
                    />
                    <Table/>
                    <TableHeaderRow/>
                    <TableEditRow/>
                    <TableEditColumn
                        showAddCommand
                        showEditCommand
                        showDeleteCommand
                        messages={editColumnMessages}
                    />
                </Grid>
            </CardBody>
            <AddTargetDialog
                open={openDialog}
                handleClose={handleChangeDialog}
                loading={(bool) => props.dispatch(SetSiteLoading(bool))}
            />
        </Card>
    );
};

RelationsTable.propTypes = {
    defaultValue: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default connect()(RelationsTable);