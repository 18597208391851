import React, {useCallback, useState} from 'react';
import GridItem from "../../../components/Grid/GridItem";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import Tasks from "../../../components/Tasks/Tasks";
import AssessmentRoundedIcon from "@material-ui/icons/AssessmentRounded";
import TrackChangesRoundedIcon from '@material-ui/icons/TrackChangesRounded';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import {baseUrl} from "../../../appConfig";
import {apiToken} from "../../../appConfig";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";


const LastOnesTable = ({data}) => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const removeProject = async ID => {
        return await Axios.post(baseUrl + "/projects/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "پروژه با موفقیت حذف شد");
                    data.lastProjects = data.lastProjects.filter(value => value.postId !== ID);
                    forceUpdate();
                    return true;
                }
                return false;
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
                return false;
            })
    };

    const removeTarget = async ID => {
        return await Axios.post(baseUrl + "/targets/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "هدف با موفقیت حذف شد");
                    data.lastTargets = data.lastTargets.filter(value => value.postId !== ID);
                    forceUpdate();
                    return true;
                }
                return false;
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
                return false;
            })
    };

    return (
        <GridItem xs={12} sm={12} md={6}>
            <CustomTabs
                rtlActive
                title="آخرین ها:"
                headerColor="primary"
                tabs={[
                    {
                        tabName: "پروژه",
                        tabIcon: AssessmentRoundedIcon,
                        tabContent: (
                            <Tasks
                                rtlActive
                                tasks={data.lastProjects || []}
                                onEdit={res => navigate("/admin/projects/editor/" + res)}
                                onRemove={ID => removeProject(ID)}
                            />
                        )
                    },
                    {
                        tabName: "هدف",
                        tabIcon: TrackChangesRoundedIcon,
                        tabContent: (
                            <Tasks
                                rtlActive
                                tasks={data.lastTargets || []}
                                onEdit={res => navigate("/admin/targets/editor/" + res)}
                                onRemove={ID => removeTarget(ID)}
                            />
                        )
                    }
                ]}
            />
        </GridItem>
    );
};

LastOnesTable.propTypes = {
    data : PropTypes.object.isRequired
};

export default LastOnesTable;