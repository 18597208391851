import React, {useState} from 'react';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Card from "../Card/Card";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Slider from '@material-ui/core/Slider';
import thumbnail from '../../assets/img/mp3-logo.jpg';
import './MediaControlCard.css'
import {pauseAllAudios} from "../../functions";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#9c075e',
    },
    content: {
        flex: '1 0 auto',
        minWidth: '18rem'
    },
    cover: {
        width: '100%',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: 'center'
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const PrettoSlider = withStyles({
    root: {
        color: '#a2005e !important',
        height: '8px !important',
        padding: '0 !important'
    },
    thumb: {
        display: 'none '
    },
    track: {
        height: '8px !important',
        backgroundColor: '#a2005e98 !important',
    },
    rail: {
        height: '8px !important',
        backgroundColor: '#fff !important',
    },
})(Slider);

const MediaControlCard = ({title, subTitle, audioSource, thumb, id, selectedDiagram}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [isPlayed, setPlayed] = useState(false);
    const [progress, setProgress] = useState(0);
    return (
        <Card className={classes.root + " MediaControlCard-class "}>
            <div className="d-flex h-100">
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {subTitle}
                        </Typography>
                    </CardContent>
                    <div className={classes.controls}>
                        {/*<IconButton aria-label="previous">*/}
                        {/*    {*/}
                        {/*        theme.direction === 'rtl' ?*/}
                        {/*            <SkipNextIcon*/}
                        {/*                onClick={() => {*/}
                        {/*                    const element = document.getElementById("media-control-card-study-" + id);*/}
                        {/*                    element.currentTime = (element.currentTime - 2);*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*            :*/}
                        {/*            <SkipPreviousIcon*/}
                        {/*                onClick={() => {*/}
                        {/*                    const element = document.getElementById("media-control-card-study-" + id);*/}
                        {/*                    element.oncanplay = function () {*/}
                        {/*                        element.currentTime = (element.currentTime + 2);*/}
                        {/*                    };*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*    }*/}
                        {/*</IconButton>*/}
                        <IconButton aria-label="play/pause">
                            {
                                isPlayed ?
                                    <PauseIcon
                                        onClick={() => {
                                            document.getElementById("media-control-card-study-" + id).pause();
                                        }}
                                        className={classes.playIcon}
                                    />
                                    :
                                    <PlayArrowIcon
                                        onClick={() => {
                                            pauseAllAudios();
                                            document.getElementById("media-control-card-study-" + id).play();
                                        }}
                                        className={classes.playIcon}
                                    />
                            }
                        </IconButton>
                        {/*<IconButton aria-label="next">*/}
                        {/*    {*/}
                        {/*        theme.direction === 'rtl'*/}
                        {/*            ?*/}
                        {/*            <SkipPreviousIcon*/}
                        {/*                onClick={() => {*/}
                        {/*                    const element = document.getElementById("media-control-card-study-" + id);*/}
                        {/*                    element.oncanplay = function () {*/}
                        {/*                        element.currentTime = (element.currentTime + 2);*/}
                        {/*                    };*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*            :*/}
                        {/*            <SkipNextIcon*/}
                        {/*                onClick={() => {*/}
                        {/*                    const element = document.getElementById("media-control-card-study-" + id);*/}
                        {/*                    element.oncanplay = function () {*/}
                        {/*                        element.currentTime = (element.currentTime - 2);*/}
                        {/*                    };*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*    }*/}
                        {/*</IconButton>*/}
                        <IconButton className="mx-3" aria-label="downloader">
                            <GetAppRoundedIcon
                                onClick={() => document.getElementById('download-study-audio-button-' + id).click()}
                            />
                            <a
                                id={"download-study-audio-button-" + id}
                                href={audioSource}
                                download
                                className="d-none"
                            />
                        </IconButton>
                    </div>
                </div>
                <CardMedia
                    className={classes.cover}
                    image={thumb ? thumb : thumbnail}
                    title={title}
                >
                    <audio
                        id={"media-control-card-study-" + id}
                        controls
                        className="d-none"
                        onTimeUpdate={(el) => {
                            selectedDiagram === "sound" && setProgress((el.currentTarget.currentTime / el.currentTarget.duration) * 100)
                        }}
                        onPlay={() => setPlayed(true)}
                        onPause={() => setPlayed(false)}
                    >
                        <source src={audioSource} type="audio/ogg"/>
                        <source src={audioSource} type="audio/mpeg"/>
                    </audio>

                </CardMedia>
            </div>
            <PrettoSlider
                value={progress}
                onChange={(d, s) => {
                    const element = document.getElementById('media-control-card-study-' + id);
                    if (audioSource) {
                        element.oncanplay = function () {
                            element.currentTime = ((s / 100) * element.duration);
                        };
                    }
                }}
                aria-labelledby="input-slider"
            />
        </Card>
    );
};

export default MediaControlCard;