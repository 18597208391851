import React, {useState} from "react";
import PropTypes from "prop-types";
// @material-ui/icons components
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import userPng from '../../assets/img/user.png';
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button, Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/lab components
import Pagination from '@material-ui/lab/Pagination';
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle";
import {getUrl} from "../../functions";


const useStyles = makeStyles(styles);

export default function CustomTable({totalAmount, tableHead, tableData, tableHeaderColor, actions, onPaging, limitViews}) {
    const classes = useStyles();
    const [deleteModal, setDeleteModal] = useState(false);

    const thereIsAvatar = tableHead.indexOf("آواتار");
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                            {
                                actions &&
                                (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                    >
                                        ابزار
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.slice(0, limitViews).map((data, key) => {
                        return (
                            <TableRow key={key} className={classes.tableBodyRow}>
                                {data.data.map((prop, key) => {
                                    if (thereIsAvatar > -1 && data.data[thereIsAvatar] === prop) {
                                        return (
                                            <TableCell className={classes.tableCell} key={key}>
                                                <Avatar src={prop?getUrl(prop, 80) :userPng} alt="logo"/>
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell className={classes.tableCell} key={key}>
                                                <Typography
                                                    className="d-block"
                                                    variant="inherit"
                                                >
                                                    {prop}
                                                </Typography>
                                            </TableCell>
                                        );
                                    }
                                })}
                                {
                                    actions &&
                                    (
                                        <TableCell className={classes.tableCell}>
                                            {
                                                actions.onRead && (
                                                    <Tooltip title="مطالعه" placement="top">
                                                        <IconButton
                                                            onClick={()=> actions.onRead(key, data.id)}
                                                            size="small"
                                                            color="primary"
                                                        >
                                                            <VisibilityRoundedIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                actions.onEdit && (
                                                    <Tooltip title="ویرایش" placement="top">
                                                        <IconButton
                                                            onClick={()=> actions.onEdit(key, data.id)}
                                                            size="small"
                                                            color="primary"
                                                        >
                                                            <EditIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                actions.onDelete && (
                                                    <Tooltip title="حذف" placement="top">
                                                        <IconButton
                                                            onClick={() => setDeleteModal({key, id: data.id})}
                                                            size="small"
                                                            color="secondary"
                                                        >
                                                            <CloseIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
                <Dialog
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    PaperProps={{style: {width: 320}}}
                >
                    <DialogTitle>
                        حذف آیتم
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="caption">
                            آیا از این اقدام خود مطمئن هستید؟
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="mx-2"
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => setDeleteModal(false)}
                        >
                            انصراف
                        </Button>
                        <Button
                            className="mx-2"
                            size="small"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                actions.onDelete(deleteModal.key, deleteModal.id);
                                setDeleteModal(false)
                            }}
                        >
                            حذف
                        </Button>
                    </DialogActions>
                </Dialog>
            </Table>
            <div className="mt-3">
                {
                    onPaging && Math.ceil(totalAmount / limitViews) > 1 &&
                    (
                        <Pagination
                            count={Math.ceil(totalAmount / limitViews)} defaultPage={1} boundaryCount={3}
                            onChange={onPaging} variant="outlined" color="secondary"
                        />
                    )
                }
            </div>
        </div>
    );
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray",
    limitViews: 12
};

CustomTable.propTypes = {
    totalAmount : PropTypes.number,
    actions: PropTypes.object,
    onPaging: PropTypes.func,
    limitViews: PropTypes.number,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.array
};
