import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Flowpoint, Flowspace} from 'flowpoints';
import {concatArraysInObj, generateRandomColor, groupBy} from "../../../functions";

const dataProcessor = (data, subscriptionPointAmount) => {
    if(subscriptionPointAmount > 0 ){
        return groupBy(concatArraysInObj(data), subscriptionPointAmount);
    } else {
        return []
    }
};
const indexProcessor = (data, target) => {
    return Object.keys(data).map((value, index) => {
        if (data[value].includes(target))
            return "point-" + index;
        else
            return ''
    }).filter(val => !!val)
};
const FlowPointChart = (props, ref) => {
    const [data, setData] = useState([]),
        [subscriptionPointAmount, setSubscriptionPointAmount] = useState(4),
        [selectedPoint, selectPoint] = useState(null),
        shouldBeInChildes = dataProcessor(data, subscriptionPointAmount),
        {availWidth, availHeight} = window.screen ;
    let left = 1, right = 1;

    useImperativeHandle(ref, () => ({
        setData,
        setSubscriptionPointAmount
    }), []);

    useEffect(() => {
        left = 1;
        right = 1;
        // eslint-disable-next-line
    }, [subscriptionPointAmount]);

    return (
        <div style={{height: "100%"}}>
            <Flowspace
                style={{height: "100%", overflow: "auto", width: "78vw", minWidth: "100%"}}
                theme="indigo"
                variant="outlined"
                background="transparent"
                className="FlowSpace"
                onClick={() => {
                    selectPoint(null)
                }}
                selected={selectedPoint}
            >
                {
                    Object.keys(data).map((parent, parentIndex) => {
                        return (
                            <Flowpoint
                                key={"point-" + parentIndex}
                                style={{backgroundColor: 'red'}}
                                startPosition={{x: (availWidth / 2 * (availWidth > 900 && 5/6)) - 100, y: 100 * (parentIndex + 1)}}
                                dragX={false}
                                dragY={false}
                                height={25}
                                onHover={() => {
                                    let selected_point = selectedPoint;
                                    if (selected_point === parentIndex)
                                        selected_point = null;
                                    else
                                        selected_point = "point-" + parentIndex;
                                    selectPoint(selected_point);
                                }}
                            >
                                {parent}
                            </Flowpoint>
                        )
                    })
                }
                {shouldBeInChildes.length &&
                     shouldBeInChildes.map((val, index) => {
                        const isLeft = Math.round(shouldBeInChildes.length / 2) < index,
                            totalLength = availHeight < shouldBeInChildes.length * 30 ? 30 : availHeight / shouldBeInChildes.length,
                            randomColor = generateRandomColor(),
                            outputs = indexProcessor(data, val),
                            outputViewModel = {};
                        if (selectedPoint === null)
                            outputs.forEach(data => {
                                outputViewModel[data] = {
                                    inputColor: randomColor,
                                    outputColor: randomColor
                                }
                            });
                        else if (selectedPoint.substr(0, 5) === "child" && selectedPoint === ("child-" + index))
                            outputs.forEach(data => {
                                outputViewModel[data] = {
                                    inputColor: randomColor,
                                    outputColor: randomColor
                                }
                            });
                        else if (selectedPoint && outputs.includes(selectedPoint)) {
                            outputs.forEach(data => {
                                if (data === selectedPoint)
                                    outputViewModel[data] = {
                                        inputColor: randomColor,
                                        outputColor: randomColor
                                    }
                            });
                        } else
                            outputs.forEach(data => {
                                outputViewModel[data] = {
                                    inputColor: "transparent",
                                    outputColor: "transparent"
                                }
                            });
                        if (isLeft)
                            left++;
                        else
                            right++;
                        return (
                            <Flowpoint
                                key={"child-" + index}
                                outputs={outputViewModel}
                                style={{backgroundColor: 'lightblue'}}
                                startPosition={{
                                    x: isLeft ? 50 : (availWidth * (availWidth > 900 && 5/6)) - 300,
                                    y: totalLength * (isLeft ? left : right)
                                }}
                                onHover={() => {
                                    let selected_point = selectedPoint;
                                    if (selected_point === index)
                                        selected_point = null;
                                    else
                                        selected_point = "child-" + index;
                                    selectPoint(selected_point);
                                }}
                                dragX={false}
                                dragY={false}
                                height={25}
                            >
                                {val}
                            </Flowpoint>
                        )
                    })
                }
            </Flowspace>
        </div>
    );
};

export default forwardRef(FlowPointChart);