import React from 'react';
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import LibraryAddRoundedIcon from '@material-ui/icons/LibraryAddRounded';
import TargetStudyItem from "../TargetStudyItem/TargetStudyItem";

const TargetRelations = ({defaultValue = []}) => {
    return (
        <Card>
            <CardHeader stats icon>
                <CardIcon color="primary">
                    <LibraryAddRoundedIcon/>
                </CardIcon>
                <h3 className="p-3 text-bold" style={{color: "#000"}}>سوژه های مرتبط:  {defaultValue.length}</h3>
            </CardHeader>
            <CardBody>
                <div className="d-flex justify-content-center flex-wrap">
                    {
                        defaultValue.map((target, key) =>
                            <TargetStudyItem
                                key={key}
                                avatarId={target.avatarId}
                                name={target.name}
                                targetId={target.targetId}
                                targetType={target.targetType}
                                relation={target.projectRelations[0].relationName}
                            />
                        )
                    }
                </div>
            </CardBody>
        </Card>
    );
};

export default TargetRelations;