import React from 'react';
import Axios from "axios";
import {baseUrl, apiToken} from "../../../appConfig";
//css
import './CreateLocation.css'
import 'leaflet/dist/leaflet.css'
//
import leaflet from 'leaflet';
import {Map, TileLayer, Marker, Popup} from "react-leaflet";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import {connect} from "react-redux";
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide
} from "@material-ui/core";
import {CreateLocationHandler, SetSiteLoading} from "../../../redux/actions/actions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const iconMarker = new leaflet.Icon({
    iconUrl: require("../../../assets/img/marker.png"),
    iconRetinaUrl: require("../../../assets/img/marker.png"),
    iconSize: new leaflet.Point(30, 35),
    clickable: true,
    draggable: true,
});

const CreateLocation = ({open, onClose, loading, dataType, ...props}) => {
    let [position, setPosition] = React.useState([0, 0]);
    const [address, setAddress] = React.useState("");
    const [locType, setLocType] = React.useState("");
    const [addressName, setAddressName] = React.useState("");
    const {enqueueSnackbar} = useSnackbar();

    const postLocation = async () => {
        await props.dispatch(SetSiteLoading(true));
        if (address && addressName) {
            await Axios.post(
                baseUrl + "/locations",
                {
                    name: addressName,
                    locationTypeId: locType,
                    address: address,
                    positions: {
                        lat: position[0],
                        lng: position[1]
                    }
                },
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    if (res.status === 200) {
                        setAddress("");
                        setAddressName("");
                        setPosition([0, 0]);
                        sendNotification(enqueueSnackbar, "مکان مورد نظر ایجاد شد");
                        props.dispatch(CreateLocationHandler(false));
                        props.dispatch(SetSiteLoading(false));
                    }
                })
                .catch((err) => {
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                        props.dispatch(CreateLocationHandler(false));
                        props.dispatch(SetSiteLoading(false));
                    }
                )
        } else {
            sendErrorNotification(enqueueSnackbar, "وارد کردن نام مکان و آدرس الزامی است");
            props.dispatch(CreateLocationHandler(false));
            props.dispatch(SetSiteLoading(false));
        }
    };

    return (
        <Dialog
            open={props.CreateLocationHandler}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.dispatch(CreateLocationHandler(false))}
            fullWidth
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"فیلد مورد نظر خود را بسازید"}</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="نام مکان"
                    type="text"
                    className="my-2 w-100"
                    value={addressName}
                    onChange={(e) =>
                        setAddressName(e.target.value)
                    }
                />
                <TextField
                    variant="outlined"
                    label="آدرس"
                    type="text"
                    className="my-2 w-100"
                    value={address}
                    onChange={(e) =>
                        setAddress(e.target.value)
                    }
                />
                <FormControl variant="outlined" className="w-100 my-2">
                    <InputLabel id="demo-simple-select-outlined-label">نوع مکان</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={locType}
                        onChange={(event) => setLocType(event.target.value)}
                        label="نوع مکان"
                    >
                        {
                            dataType.map((type, i) =>
                                <MenuItem key={i} value={type.locationTypeId}>{type.title}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <div className="w-100">
                    <Map
                        id="map"
                        style={{height: "50vh", minHeight: "30rem"}}
                        center={position}
                        zoom={1}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                            ref={(ref) => {
                                const lat = ref && ref.leafletElement._latlng.lat;
                                const lng = ref && ref.leafletElement._latlng.lng;
                                position = [lat, lng];
                            }}
                            icon={iconMarker}
                            position={position}
                            draggable
                        >
                            <Popup>
                                مکان مورد نظر خود را مشخص کنید
                            </Popup>
                        </Marker>
                    </Map>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => props.dispatch(CreateLocationHandler(false))}
                    color="primary"
                >
                    لغو
                </Button>
                <Button
                    onClick={postLocation}
                    color="primary"
                >
                    تایید
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const mapState = (states) => ({
    CreateLocationHandler: states.CreateLocationHandler,
});
export default connect(mapState)(CreateLocation);