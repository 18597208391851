import * as React from 'react';
import {connect} from "react-redux";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CardBody from "../Card/CardBody";
import CommentsGallery from "../../views/ImageGallery/CommentGallery/CommentGallery";

function CommentStudy(props) {
    return (
        <Card style={{minHeight: '20rem'}}>
            <CardHeader stats icon>
                <CardIcon
                    color="danger"
                >
                    <QuestionAnswerIcon/>
                </CardIcon>
                <h3
                    className="p-3"
                    style={{color: "#000"}}
                >
                    نظرات
                </h3>
            </CardHeader>
            <CardBody>
                <CommentsGallery
                    dispatch={props.dispatch}
                    currentItemId={props.id}
                    UserDetails={props.UserDetails}
                    commentPlatform={0}
                />
            </CardBody>
        </Card>
    );
}

const mapState = states => ({
    UserDetails: states.userDetails
});

export default connect(mapState)(CommentStudy);