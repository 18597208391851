import React from "react";
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";

import styles from "../../assets/jss/material-dashboard-react/components/rtlHeaderLinksStyle";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function RTLNavbarLinks() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [openProfile, setOpenProfile] = React.useState(null);

    const handleClickProfile = event => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };
    const handleCloseProfile = () => {
        localStorage.removeItem("OsintArchiveAccessKey");
        navigate('/login');
        setOpenProfile(null);
    };

    return (
        <div>
            {/*<div className={classes.searchWrapper}>*/}
            {/*    <CustomInput*/}
            {/*        formControlProps={{*/}
            {/*            className: classes.margin + " " + classes.search*/}
            {/*        }}*/}
            {/*        inputProps={{*/}
            {/*            placeholder: "جستجو...",*/}
            {/*            inputProps: {*/}
            {/*                "aria-label": "Search"*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <Button color="white" aria-label="edit" justIcon round>*/}
            {/*        <Search/>*/}
            {/*    </Button>*/}
            {/*</div>*/}
            {/*<div className={classes.manager}>*/}
                {/*<Button*/}
                {/*    color={window.innerWidth > 959 ? "transparent" : "white"}*/}
                {/*    justIcon={window.innerWidth > 959}*/}
                {/*    simple={!(window.innerWidth > 959)}*/}
                {/*    aria-owns={open ? "menu-list-grow" : null}*/}
                {/*    aria-haspopup="true"*/}
                {/*    onClick={handleToggle}*/}
                {/*    className={classes.buttonLink}*/}
                {/*>*/}
                {/*    <Notifications className={classes.icons}/>*/}
                {/*    <span className={classes.notifications}>۵</span>*/}
                {/*    <Hidden mdUp implementation="css">*/}
                {/*        <p onClick={handleToggle} className={classes.linkText}>*/}
                {/*            اعلان‌ها*/}
                {/*        </p>*/}
                {/*    </Hidden>*/}
                {/*</Button>*/}
                {/*<Poppers*/}
                {/*    open={Boolean(open)}*/}
                {/*    anchorEl={open}*/}
                {/*    transition*/}
                {/*    disablePortal*/}
                {/*    className={*/}
                {/*        classNames({[classes.popperClose]: !open}) +*/}
                {/*        " " +*/}
                {/*        classes.popperNav*/}
                {/*    }*/}
                {/*>*/}
                {/*    {({TransitionProps, placement}) => (*/}
                {/*        <Grow*/}
                {/*            {...TransitionProps}*/}
                {/*            id="menu-list-grow"*/}
                {/*            style={{*/}
                {/*                transformOrigin:*/}
                {/*                    placement === "bottom" ? "center top" : "center bottom"*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <Paper>*/}
                {/*                <ClickAwayListener onClickAway={handleClose}>*/}
                {/*                    <MenuList role="menu">*/}
                {/*                        <MenuItem*/}
                {/*                            onClick={handleClose}*/}
                {/*                            className={classes.dropdownItem}*/}
                {/*                        >*/}
                {/*                            محمدرضا به ایمیل شما پاسخ داد*/}
                {/*                        </MenuItem>*/}
                {/*                        <MenuItem*/}
                {/*                            onClick={handleClose}*/}
                {/*                            className={classes.dropdownItem}*/}
                {/*                        >*/}
                {/*                            شما ۵ وظیفه جدید دارید*/}
                {/*                        </MenuItem>*/}
                {/*                        <MenuItem*/}
                {/*                            onClick={handleClose}*/}
                {/*                            className={classes.dropdownItem}*/}
                {/*                        >*/}
                {/*                            از حالا شما با علیرضا دوست هستید*/}
                {/*                        </MenuItem>*/}
                {/*                        <MenuItem*/}
                {/*                            onClick={handleClose}*/}
                {/*                            className={classes.dropdownItem}*/}
                {/*                        >*/}
                {/*                            اعلان دیگر*/}
                {/*                        </MenuItem>*/}
                {/*                        <MenuItem*/}
                {/*                            onClick={handleClose}*/}
                {/*                            className={classes.dropdownItem}*/}
                {/*                        >*/}
                {/*                            اعلان دیگر*/}
                {/*                        </MenuItem>*/}
                {/*                    </MenuList>*/}
                {/*                </ClickAwayListener>*/}
                {/*            </Paper>*/}
                {/*        </Grow>*/}
                {/*    )}*/}
                {/*</Poppers>*/}
            {/*</div>*/}
            <div className={classes.manager}>
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openProfile ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={classes.buttonLink}
                >
                    <Person className={classes.icons}/>
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>پروفایل</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className={
                        classNames({[classes.popperClose]: !openProfile}) +
                        " " +
                        classes.popperNav
                    }
                >
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={()=> setOpenProfile(null)}>
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={handleCloseProfile}
                                            className={classes.dropdownItem}
                                        >
                                            خروج
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
            </div>
        </div>
    );
}
