import React, {useState} from 'react';
import './PoliticalItem.css'
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {getUrl} from "../../../functions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    name: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    description: {
        lineHeight: '2rem',
        textAlign: "justify"
    }
}));

const PoliticalItem = ({title, avatar, description, politicalPartyDescription}) => {
    const classes = useStyles();
    const [isClick, setIsClick] = useState(false);
    return (
        <div
            onClick={()=> setIsClick(!isClick)}
            className={"PoliticalItem p-4 cursor-pointer " + (isClick && " PoliticalItem-hover ")}
        >
            <Avatar
                alt="Political Item Avatar"
                src={getUrl(avatar)}
                className={classes.large}
            />
            {
                isClick ?
                    <>
                        <Typography
                            variant="body1"
                            className={classes.description + " my-4 PoliticalItem-content"}
                        >
                            {politicalPartyDescription}
                        </Typography>
                    </>
                    :
                    <>
                        <Typography
                            className="my-4"
                            variant="h4"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.description}
                        >
                            {description}
                        </Typography>
                    </>
            }
        </div>
    );
};

export default PoliticalItem;