import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import Fab from "@material-ui/core/Fab";
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import {SetMediaDrawer} from "../../../redux/actions/actions";
import {connect} from "react-redux";
import {getUrl} from "../../../functions";
import PropTypes from "prop-types";
import './FeatureImage.css';
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

const FeatureImage = ({onChoose, defaultValue = "", ...props}) => {
    const [chosenPicture, setChosenPicture] = useState(null);

    useEffect(()=> {
        if(defaultValue && defaultValue.length === 36)
            setChosenPicture(defaultValue);
        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        <Card>
            <CardHeader color="warning" stats icon>
                <CardIcon color="rose">
                    <PermMediaOutlinedIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3" style={{color: "#000"}}>تصویر شاخص</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    برای انتخاب تصویر میتوانید با باز کردن کتابخانه ی رسانه تصویر دلخواه خود را آپلود و سپس انتخاب کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardBody>
                <Fab
                    variant="extended"
                    color="secondary"
                    onClick={() => props.dispatch(SetMediaDrawer({
                        type : "image",
                        value: chosenPicture,
                        action: value => {
                            setChosenPicture(value);
                            onChoose(value);
                        }
                    }))}
                    style={{borderRadius: '.3rem'}}
                >
                    <span>بازکردن کتابخانه رسانه</span>
                </Fab>
                {
                    chosenPicture &&
                    (
                        <div className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light">
                            <img
                                className="h-auto"
                                style={{maxWidth: "100%", maxHeight: "20rem"}}
                                src={getUrl(chosenPicture, 500)}
                                alt="feature"
                            />
                        </div>
                    )
                }
            </CardBody>
        </Card>
    );
};

FeatureImage.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    onChoose : PropTypes.func.isRequired,
    dispatch : PropTypes.func
};


const mapState = () => ({});
export default connect(mapState)(FeatureImage);