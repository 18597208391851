import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import CastConnectedRoundedIcon from '@material-ui/icons/CastConnectedRounded';
import PropTypes from 'prop-types';
import './SocialStudy.css';
import {connect} from "react-redux";
import Axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import Avatar from "@material-ui/core/Avatar";
import {getUrl} from "../../../functions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    root: {},
    cardBody: {
        maxHeight: "15rem",
        overflow: "auto"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const SocialStudy = ({className, data}) => {
    const classes = useStyles();

    return (
            <Card className={className}>
                <CardHeader stats icon>
                    <CardIcon color="heliotrope">
                        <CastConnectedRoundedIcon/>
                    </CardIcon>
                    <h3
                        className="p-3"
                        style={{color: "#000"}}
                    >
                        شبکه های اجتماعی و راه های ارتباطی
                    </h3>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <div className="d-flex flex-wrap">
                        {
                            data &&
                            data.map((d, i) =>
                                d.id.length > 1 &&
                                <div className="m-3" key={i}>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            window.open(d.id)
                                        }}
                                    >
                                        <Tooltip
                                            placement="top"
                                            title={d.id}
                                        >
                                            <Avatar
                                                className={classes.small}
                                                sizes="small"
                                                variant="square"
                                                src={d.socialMediaAvatarId && getUrl(d.socialMediaAvatarId)}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </CardBody>
            </Card>
    );
};

SocialStudy.propTypes = {
    defaultValues: PropTypes.array,
};

const mapState = () => ({});
export default connect(mapState)(SocialStudy);