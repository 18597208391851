import React, {useCallback, useEffect} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import RelationEditor from "./RelationEditor/RelationEditor";
import RelationList from "./RelationList/RelationList";
import Axios from "axios";
import {apiToken, baseUrl} from "../../appConfig";

let relationEditorRef;
let relationListRef;
const Relations = () => {
    useEffect(() => {
        if (relationEditorRef.inputsRef)
            Axios.get(baseUrl + "/relationTypes", {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        relationEditorRef.inputsRef.setRelationTypeData(res.data.data);
                        relationListRef.setRelationTypeData(res.data.data);
                    }
                });
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, [relationEditorRef]);

    const onSubmit = (viewModel, createMode = false) => {
        const {tableData, setTableData} = relationListRef;
        if (createMode) {
            const newValues = [
                {
                    id: viewModel.relationId,
                    data: [viewModel.name, viewModel.typeName]
                }, ...tableData.values,
            ];
            setTableData({
                values: newValues,
                countAll: tableData.countAll + 1
            });
        } else {
            const currentData = tableData.values.filter(val => val.id === viewModel.relationId)[0];
            if (currentData) {
                const index = tableData.values.indexOf(currentData);
                if (index > -1) {
                    tableData.values[index] = {
                        id: viewModel.relationId,
                        data: [viewModel.name, viewModel.typeName]
                    };
                    setTableData(tableData);
                }
            }
        }
    };


    const setRelationTypeData = useCallback(relationListRef && relationListRef.setRelationTypeData, [relationListRef]);
    return (
        <GridContainer>
            <GridItem
                xs={12}
                lg={4}
            >
                <RelationEditor
                    ref={ref => {
                        relationEditorRef = ref
                    }}
                    onSubmit={onSubmit}
                    setRelationTypeData={setRelationTypeData}
                />
            </GridItem>
            <GridItem
                xs={12}
                lg={8}
            >
                <RelationList ref={ref => {
                    relationListRef = ref
                }}/>
            </GridItem>
        </GridContainer>
    );
};

export default Relations;