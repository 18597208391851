import React, {useEffect} from 'react';
import Axios from "axios";
import {apiToken} from "../../../../appConfig";
import {baseUrl} from '../../../../appConfig';
import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";

const ProgressBar = ({onDone, file}) => {
    const [progress, setProgress] = React.useState(0);

    async function progressUploading(progressEvent) {
        setProgress((100 / progressEvent.total) * progressEvent.loaded);
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('file', file);
        Axios.post(baseUrl + "/FileManagers", formData,{headers : {Authorization : apiToken}, onUploadProgress : progressUploading})
            .then(res => {
                if(res.status === 200){
                    onDone(res.data.data[0].fileManagerId)
                }
            })
        // eslint-disable-next-line
    }, []);

    return (
        <CircularProgress variant="static" value={progress} />
    );
};

ProgressBar.propTypes = {
    file : PropTypes.object.isRequired,
    onDone : PropTypes.func.isRequired
};

export default ProgressBar;