import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const TitleBar = ({label, onChange, defaultValue})  => {
    const [value, setValue] = useState("");

    useEffect(()=> {
        if(defaultValue){
            setValue(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        <TextField
            fullWidth
            label={label}
            className="bg-white"
            variant="outlined"
            value={value}
            onChange={input => {
                setValue(input.target.value);
                onChange(input);
            }}
        />
    )
};

TitleBar.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired
};

export default TitleBar;