import React from 'react';
import {Route, Routes, useNavigate, useLocation} from "react-router-dom";
import {baseUrl} from './appConfig';
import {Authenticated, SetUserDetails} from "./redux/actions/actions";
import {Hidden, Backdrop, CircularProgress, createTheme, ThemeProvider} from "@material-ui/core";
import {faIR} from "@material-ui/core/locale";
import {connect} from "react-redux";
import {apiToken} from "./appConfig";
import Axios from "axios";

// core components
import Login from "./layouts/Login";
import Admin from "./layouts/Admin";
import routes from "./routes";

const theme = createTheme({
    palette: {
        direction: "rtl"
    },
    direction: "rtl"
}, faIR);


const switchRoutes = (userRole) => (
    routes.map((prop, key) => {
        if (prop.layout === "/admin" && (prop.accessRole >= userRole || prop.accessRole === null) && !prop.children) {
            return (
                <Route
                    exact={prop.exact}
                    path={prop.layout + prop.path}
                    element={<prop.component/>}
                    key={key}
                />
            );
        } else {
            prop.children && prop.children.map((data) =>
                <Route
                    exact={data.exact}
                    path={data.layout + data.path}
                    element={<data.component/>}
                    key={key}
                />
            )
        }
        return null;
    })
);

const backdropStyle = {zIndex: 5555, color: '#fff'};
const App = (props) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();

    React.useEffect(() => {
        if (!window.navigator.onLine) {
            props.dispatch(Authenticated(false));
            navigate('/500');
        } else if (apiToken === null) {
            props.dispatch(Authenticated(false));
            if (pathname.toLowerCase() !== "/login")
                navigate("/login");
        } else {
            Axios.get(baseUrl + "/Users", {headers: {Authorization: apiToken}})
                .then(async res => {
                    if (Object.values(res.data).length === 0) {
                        if (pathname.toLowerCase() !== "/login") {
                            await props.dispatch(Authenticated(false));
                            navigate("/login");
                        }
                    } else if (!res.data.data.isActive) {
                        await props.dispatch(Authenticated(false));
                        navigate("/401");
                    } else {
                        props.dispatch(SetUserDetails(res.data.data));
                        await props.dispatch(Authenticated(true));
                        if (pathname.toLowerCase() === "/login" || pathname === "/")
                            navigate('/admin/dashboard');
                    }
                })
                .catch(async res => {
                    await props.dispatch(Authenticated(false));
                    localStorage.removeItem('OsintArchiveAccessKey');
                    if (res.response && res.response.status === 401) {
                        navigate("/401");
                    } else {
                        navigate('/500');
                    }
                })
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Hidden xsUp={props.Authorization === null} implementation="css">
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="login" element={<Login/>}/>
                    <Route path="admin" element={<Admin/>}>
                        {switchRoutes(props.UserDetails.role)}
                    </Route>
                    {/*<Navigate to="/dashboard/admin" replace />*/}
                </Routes>
                <Backdrop style={backdropStyle} open={props.SiteLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </ThemeProvider>
        </Hidden>
    );
};

const mapState = states => ({
    Authorization: states.authorization,
    UserDetails: states.userDetails,
    SiteLoading: states.siteLoading
});
export default connect(mapState)(App);