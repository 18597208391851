import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CardBody from "../../Card/CardBody";
import {DataTypeProvider, EditingState} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import Chip from "@material-ui/core/Chip";
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import QuickUploader from "../../Uploaders/QuickUpload/QuickUploader";
import Typography from "@material-ui/core/Typography";
import {getUrl} from "../../../functions";
import PropTypes from 'prop-types';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

const formatterComponent = ({value, ...rest}) => {
    if (rest.column.type === "select")
        return (
            <Chip
                style={{
                    borderRadius: ".3rem",
                    maxWidth: "100%"
                }}
                color="secondary"
                label={value === "text" || !value ? 'متن' : value === "file" ? 'فایل' : "تصویر"}/>
        );
    else if (rest.column.type === "upload" || rest.column.type === "textarea") {
        if (rest.column.name === "file")
            return (
                rest.row.file ?
                    <div className="haveValue">
                        <Tooltip title={getUrl(rest.row.file)} placement="top-end">
                            <a href={getUrl(rest.row.file)} target="_blank" rel="noopener noreferrer">
                                <Typography dir="ltr" noWrap>{getUrl(rest.row.file)}</Typography>
                            </a>
                        </Tooltip>
                    </div>
                    : ""
            );
        else if (rest.column.name === "picture")
            return (
                rest.row.picture ?
                    <img className="w-100 h-auto" src={getUrl(rest.row.picture, 300)} alt=""/>
                    : ""
            );
        else if (rest.row.type === "text" || !rest.row.type)
            return (
                <Tooltip
                    title={
                        <pre
                            style={{overflowWrap: "break-word", whiteSpace: "normal"}}
                            dangerouslySetInnerHTML={{__html: rest.row.value}}
                        />
                    }
                    placement="top"
                >
                    <Chip
                        style={{
                            borderRadius: ".3rem",
                            maxWidth: "8rem",
                            // overflow: "hidden"
                        }}
                        color="secondary"
                        label={rest.row.value}
                        noWrap
                    />
                </Tooltip>
            );
    }
};

const MoreDetailsTable = ({onChange, defaultValue = []}) => {
    const [columns] = useState([
        {name: 'title', title: 'عنوان'},
        {name: 'value', title: 'مقدار', type: 'textarea'},
        {name: 'source', title: 'منبع'},
        {name: 'picture', title: 'تصویر', type: 'upload'},
        {name: 'file', title: 'فایل', type: 'upload'},
    ]);

    const [rows, setRows] = useState([]);
    const booleanColumns = ['type', 'value', 'file', 'picture'];

    useEffect(() => {
        if (defaultValue.length > 0) {
            setRows(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    const editColumnMessages = {
        addCommand: <AddCircleTwoToneIcon/>,
        editCommand: <EditTwoToneIcon/>,
        deleteCommand: <DeleteForeverTwoToneIcon color="secondary"/>,
        commitCommand: <SaveTwoToneIcon/>,
        cancelCommand: <CancelPresentationTwoToneIcon color="secondary"/>,
    };

    const editorComponent = ({value, onValueChange, column}) => {
        if (column.type === "upload")
            return (
                <QuickUploader
                    defaultValue={value}
                    onUploadCompleted={onValueChange}
                    imageType={column.name === "picture"}
                />
            );
        else if (column.type === "textarea")
            return (
                <TextareaAutosize
                    rows={7}
                    className="w-100"
                    value={value}
                    onChange={event => {
                        onValueChange(event.target.value);
                    }}
                />
            )
    };

    const TypeProvider = props => (
        <DataTypeProvider
            formatterComponent={formatterComponent}
            editorComponent={editorComponent}
            {...props}
        />
    );

    const commitChanges = ({added, changed, deleted}) => {
        let changedRows;
        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            changedRows = rows.map((row, i) =>
                (changed[i] ?
                        {...row, ...changed[i]}
                        :
                        row

                )
            );
        }
        if (deleted) {
            changedRows = rows.filter(row => row !== rows[deleted[0]]);
        }
        onChange(changedRows);
        setRows(changedRows);
    };

    return (
        <Card>
            <CardHeader stats icon>
                <CardIcon color="danger">
                    <MoreHorizIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3" style={{color: "#000"}}>جزئیات بیشتر</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    این قسمت شامل اطلاعات بیشتر در مورد سوژه می باشد که در قسمت های قبلی وارد نشده است.
                                </span>
                                <span className="my-1">
                                    به عنوان مثال : (تصاویر خانوادگی، اخبار مربوطه، مسافرت های کاری، فایل، ویدیو، تصاویر اضافی و...)
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon
                            className="cursor-pointer"
                            style={{width: '1.5rem', color: '#bc7d27'}}
                        />
                    </HtmlTooltip>
                </div>

            </CardHeader>
            <CardBody>
                <Grid
                    rows={rows}
                    columns={columns}
                >
                    <TypeProvider
                        for={booleanColumns}
                    />
                    <EditingState
                        onCommitChanges={commitChanges}
                    />
                    <Table/>
                    <TableHeaderRow/>
                    <TableEditRow/>
                    <TableEditColumn
                        showAddCommand
                        showEditCommand
                        showDeleteCommand
                        messages={editColumnMessages}
                    />
                </Grid>
            </CardBody>
        </Card>
    );
};

MoreDetailsTable.propTypes = {
    defaultValue: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default MoreDetailsTable;