/*eslint-disable*/
import React, {useEffect} from "react";
// @material-ui/core
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
// import Danger from "components/Typography/Danger";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardFooter from "../../components/Card/CardFooter";

import styles from "../../assets/jss/material-dashboard-react/views/rtlStyle";

import {connect} from "react-redux";
import CategoryStatistics from "./CategoryStatistics/CategoryStatistics";
import MostTargetRelation from "./MostTargetRelation/MostTargetRelation";
import MostProjectRelation from "./MostProjectRelation/MostProjectRelation";
import LastOnesTable from "./LastOnesTable/LastOnesTable";
import ListOfUsers from "./ListOfUsers/ListOfUsers";
import UserProfile from "./UserProfile/UserProfile";

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
    const classes = useStyles();
    const userDetails = props.UserDetails || {};
    const statistics = userDetails.statistics || {};

    useEffect(() => {
        document.getElementById('mainPanel').scrollTop = 0;
    }, []);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <AssignmentIndIcon/>
                            </CardIcon>
                            <p className={classes.cardCategory}>تعداد اشخاص</p>
                            <h3 className={classes.cardTitle}>
                                {statistics.targetCount}
                            </h3>
                        </CardHeader>
                        <CardFooter stats/>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <AssessmentRoundedIcon/>
                            </CardIcon>
                            <p className={classes.cardCategory}>تعداد پروژه ها</p>
                            <h3 className={classes.cardTitle}>{statistics.projectCount}</h3>
                        </CardHeader>
                        <CardFooter stats/>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <CategoryRoundedIcon/>
                            </CardIcon>
                            <p className={classes.cardCategory}>تعداد دسته بندی ها</p>
                            <h3 className={classes.cardTitle}>{statistics.categoryCount}</h3>
                        </CardHeader>
                        <CardFooter stats/>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <AccountTreeRoundedIcon/>
                            </CardIcon>
                            <p className={classes.cardCategory}>تعداد ارتباط ها</p>
                            <h3 className={classes.cardTitle}>{statistics.relationCount}</h3>
                        </CardHeader>
                        <CardFooter stats>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <MostTargetRelation
                    createdDate={statistics.createdDate}
                    statisticData={statistics.mostTargetRelation}
                />
                <CategoryStatistics
                    createdDate={statistics.createdDate}
                    statisticData={statistics.topCategories}
                />
                <MostProjectRelation
                    createdDate={statistics.createdDate}
                    statisticData={statistics.mostProjectRelation}
                />
            </GridContainer>
            <GridContainer>
                {
                    userDetails.role !== 2 &&
                    (
                        <LastOnesTable
                            data={statistics}
                        />
                    )
                }
                {
                    userDetails.role === 0 ?
                        <ListOfUsers
                            data={statistics.lastUsers}
                        />
                        :
                        <UserProfile xs={12} lg={userDetails.role === 2 ? 12 : 6}/>
                }
            </GridContainer>
            {
                userDetails.role === 0 &&
                (
                    <GridContainer>
                        <UserProfile xs={12}/>
                    </GridContainer>
                )
            }
        </div>
    );
};

const mapState = states => ({
    UserDetails: states.userDetails
});
export default connect(mapState)(Dashboard);
