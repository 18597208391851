import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Axios from "axios";
import {apiToken} from "../../../../appConfig";
import {baseUrl} from '../../../../appConfig';

const LinearProgressWithLabel = props => {
    return (
        <Box display="flex" alignItems="center">
            <Box className="my-0" width="100%" my={2} ml={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
});

const ProgressBar = ({onDone, file}) => {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [changeColor, setChangeColor] = useState(false);

    async function progressUploading(progressEvent) {
        setProgress((100 / progressEvent.total) * progressEvent.loaded);
    }

    React.useEffect(() => {
        const formData = new FormData();
        formData.append('file', file);
        Axios.post(baseUrl + "/FileManagers", formData,{headers : {Authorization : apiToken}, onUploadProgress : progressUploading})
            .then(res => {
                if(res.status === 200){
                    onDone(res.data.data[0]);
                    setChangeColor(true)
                }
            })
    }, []);


    return (
        <div className={classes.root}>
            <LinearProgressWithLabel style={{backgroundColor: changeColor && "#21ba45"}} value={progress} />
        </div>
    );
};

export default ProgressBar;