import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import {connect} from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import {apiToken, baseUrl} from "../../../../appConfig";
import {getUrl, sendErrorNotification, sendNotification} from "../../../../functions";
import {useSnackbar} from "notistack";
import Fab from "@material-ui/core/Fab";
import {SetMediaDrawer} from "../../../../redux/actions/actions";

const SocialMediaHandleDialog = ({onSubmit, onClose, ...props}) => {
    const {enqueueSnackbar} = useSnackbar();

    const [createOrEditDialog, setCreateOrEditDialog] = useState(null);
    const [data, setData] = useState({
        name: "",
        description: "",
        avatarId: null
    });

    useEffect(() => {
        if (typeof (createOrEditDialog && createOrEditDialog.id) === "number" && createOrEditDialog.data) {
            setData(createOrEditDialog.data)
        } else if (data.name !== "") {
            clearData()
        }
        // eslint-disable-next-line
    }, [createOrEditDialog]);

    useImperativeHandle(props.reference, () => ({
        setCreateOrEditDialog
    }), []);

    const closeDialog = useCallback(() => {
        setCreateOrEditDialog(null);
        if (onClose)
            onClose();
    }, [onClose]);

    const clearData = useCallback(() => setData({
        name: "",
        description: "",
        avatarId: null
    }), []);

    const inputHandler = useCallback(input => {
        const {name, value} = input.target;
        data[name] = value;
        setData({...data});
    }, [data]);

    const submit = () => {
        Axios.post(
            baseUrl + "/socialMedias" + (typeof (createOrEditDialog && createOrEditDialog.id) === "number" ? ("/edit/" + createOrEditDialog.id) : ""),
            data,
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "فضای مجازی شما با موفقیت " + (typeof (createOrEditDialog && createOrEditDialog.id) === "number" ? "ویرایش" : "افزوده") + " شد");
                    clearData();
                    setCreateOrEditDialog(null);
                    if (onSubmit)
                        onSubmit(res.data.data, typeof (createOrEditDialog && createOrEditDialog.id) === "number");
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message)
                }
            })
    };

    return (
        <Dialog
            open={createOrEditDialog !== null}
            onClose={closeDialog}
            PaperProps={{style: {minWidth: 320}}}
        >
            <DialogTitle>افزودن فضای مجازی</DialogTitle>
            <DialogContent>
                <TextField
                    name="name"
                    label="نام"
                    value={data.name}
                    onChange={inputHandler}
                    variant="outlined"
                    className="w-100"
                />
                <TextField
                    fullWidth
                    className="mt-3"
                    name="baseAddress"
                    label="آدرس پایه"
                    value={data.baseAddress}
                    onChange={inputHandler}
                    variant="outlined"
                />
                <Fab
                    className="w-100 mt-2"
                    variant="extended"
                    color="secondary"
                    onClick={() => props.dispatch(SetMediaDrawer({
                        type: "image",
                        value: data.avatarId,
                        action: value => inputHandler({
                            target: {
                                name: "avatarId",
                                value
                            }
                        }),
                        tagHandler: false
                    }))}
                >
                    <span>انتخاب تصویر</span>
                </Fab>
                {
                    data.avatarId &&
                    (
                        <div
                            className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light">
                            <img
                                className="h-auto"
                                style={{maxWidth: "100%", maxHeight: "20rem"}}
                                src={getUrl(data.avatarId, 500)}
                                alt="feature"
                            />
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                    color="secondary"
                >
                    انصراف
                </Button>
                <Button
                    onClick={submit}
                    color="primary"
                >
                    ثبت
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Component = connect()(SocialMediaHandleDialog);
export default forwardRef((props, ref) => (
    <Component reference={ref} {...props}/>
));