import React, {useEffect, useState, useMemo} from 'react';

//css
import './StudyGraphs.css'
//icons
import BubbleChartRoundedIcon from '@material-ui/icons/BubbleChartRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import GavelIcon from '@material-ui/icons/Gavel';
import WorkIcon from '@material-ui/icons/Work';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VideocamIcon from '@material-ui/icons/Videocam';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import EditIcon from '@material-ui/icons/Edit';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
//components
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import {connect} from "react-redux";
import {ButtonGroup} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import GraphDiagram from "../../Diagram/GraphDiagram";
import Tree from "react-tree-graph";
import PoliticalItem from "../PoliticalItem/PoliticalItem";
import IconButton from "@material-ui/core/IconButton";
import Gallery from 'react-grid-gallery';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
// import LivingPlace from "../../Editor/LivingPlace/LivingPlace";
import LivingPlaceItem from "../LivingPlaceItem/LivingPlaceItem";
import IdentityItem from "../IdentityItem/IdentityItem";
import JobItemStudy from "../JobItemStudy/JobItemStudy";
import MediaControlCard from "../../MediaControlCard/MediaControlCard";
import {ReactVideo} from "reactjs-media";
import {getUrl} from "../../../functions";
import CommentsGallery from "../../../views/ImageGallery/CommentGallery/CommentGallery";

const styles = {
    divButtonDiagram: {bottom: "5rem", right: "18rem", zIndex: "1100"},
    btnDiagram: {width: "1.7rem", height: "1.7rem"},
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    DeleteForeverRoundedIcon: {
        color: "#ffffff"
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

let diagramRef;

const captionStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    maxHeight: "240px",
    overflow: "hidden",
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "100%",
    color: "white",
    padding: "2px",
    fontSize: "90%",
    transition: "1s"
};

const customTagStyle = {
    wordWrap: "break-word",
    display: "inline-block",
    backgroundColor: "white",
    height: "auto",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: "1",
    padding: ".2em .6em .3em",
    borderRadius: ".25em",
    color: "black",
    verticalAlign: "baseline",
    margin: "2px",
    transition: "2s"
};

const StudyGraphs = (
    {
        targetType,
        personalData,
        workData,
        generalData = {},
        onChangeDiagramData,
        gavelData,
        jobData,
        locationData,
        identityData,
        audioData,
        videoData,
        pictureData,
        ...props
    }
) => {
    const classes = useStyles();
    const [diagramHeight, setDiagramHeight] = useState(500);
    const [imageData, setImageData] = useState([]);
    const [selectedDiagram, setSelectedDiagram] = useState("general");
    const [selectedGeneralDiagram, setSelectedGeneralDiagram] = useState("all");
    const [treeWH, setTreeWH] = useState(100);
    const [treeData, setTreeData] = useState({});
    const [openGallery, setOpenGallery] = useState(false);
    const [commentsOpenDialog, setCommentsOpenDialog] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    let number = 0;
    const loopData = (data) => {
        data.map(x => {
            if (x.children) {
                loopData(x.children)
            }
            number = number + 1
        });
        return number
    };

    const dataCrawler = (data, first) => {
        return data.map((d) => {
            if (d.params) {
                return {
                    name: first ? d[first] : d.value,
                    children: dataCrawler(d.params)
                }
            } else {
                return {
                    name: d.value
                }
            }
        });
    };

    useEffect(() => {
        if (selectedDiagram === 'personal') {
            diagramRef.instance.import(personalData);
        }
        workData && workData.length > 0 && setTreeData({
            name: "اطلاعات حقوقی",
            children: dataCrawler(workData, 'categoryId')
        });
        workData && Array.isArray(workData) && setTreeWH(loopData(dataCrawler(workData, 'categoryId')));
        // eslint-disable-next-line
    }, [selectedDiagram]);

    useEffect(() => {
        pictureData && setImageData(pictureData.map((item, i) => (
            {
                id: 'picturesStudy' + i,
                src: getUrl(item.thumb),
                thumbnail: getUrl(item.thumb, 320),
                thumbnailWidth: 320,
                thumbnailHeight: 320 * item.pictureRatio,
                caption: item.title,
                // tags: item.tags ? item.tags.map(it => {
                //     return {value: it.itemId, title: it.name, tagType: it.tagType}
                // }) : [],
            }
        )))
        // eslint-disable-next-line
    }, [pictureData]);

    const setCustomTags = (i) => {
        return (
            i.tags.map((t, i) => {
                return (
                    <div
                        key={i}
                        style={customTagStyle}>
                        {t.title}
                    </div>
                );
            })
        );
    };

    const images = useMemo(() => imageData.map((i, index) => {
        i.customOverlay = (
            <div
                key={index}
                className="d-flex align-items-center justify-content-center flex-column"
                style={captionStyle}
            >
                <div className="text-center mx-2">{i.caption}</div>
                <div className="d-flex justify-content-center flex-wrap">
                    {
                        i.hasOwnProperty('tags') &&
                        setCustomTags(i)
                    }
                </div>
            </div>
        );
        return i;
    }), [imageData]);

    const currentItemId = images[currentImage] && images[currentImage].id;

    return (
        <Card style={{minHeight: '20rem', marginTop: '3rem'}}>
            <CardHeader stats icon>
                <CardIcon
                    color="warning"
                    className={"cursor-pointer default-graph " + `${selectedDiagram === "general" && "active-graph"}`}
                    onClick={() => setSelectedDiagram("general")}
                >
                    <Tooltip title="کلی">
                        <BubbleChartRoundedIcon/>
                    </Tooltip>
                </CardIcon>
                {
                    ((!targetType && targetType !== 0) || targetType === 1) &&
                    workData &&
                    workData.length > 0 &&
                    <CardIcon
                        color="success"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "work" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("work")}
                    >
                        <Tooltip title="حقوقی">
                            <BusinessRoundedIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                <CardIcon
                    color="rose"
                    className={"cursor-pointer default-graph " + `${selectedDiagram === "personal" && "active-graph"}`}
                    onClick={() => setSelectedDiagram("personal")}
                >
                    <Tooltip title="شخصی سازی شده">
                        <CategoryRoundedIcon/>
                    </Tooltip>
                </CardIcon>
                {
                    identityData &&
                    (
                        (identityData.birthPlace && identityData.birthPlace.length > 0) ||
                        (identityData.birthDate && identityData.birthDate.length > 0) ||
                        (identityData.fatherName && identityData.fatherName.length > 0) ||
                        (identityData.motherName && identityData.motherName.length > 0) ||
                        (identityData.spouseName && identityData.spouseName.length > 0) ||
                        (identityData.degreeOfEducation && identityData.degreeOfEducation.length > 0) ||
                        (identityData.passportCode && identityData.passportCode.length > 0) ||
                        (identityData.moreDetails && identityData.moreDetails.length > 0)
                    ) &&
                    <CardIcon
                        color="success"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "permIdentity" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("permIdentity")}
                    >
                        <Tooltip title="اطلاعات شناسنامه ای">
                            <PermIdentityIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                {
                    gavelData && gavelData.length > 0 &&
                    <CardIcon
                        color="green"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "gavel" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("gavel")}
                    >
                        <Tooltip title="نگرش سیاسی">
                            <GavelIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                {
                    locationData &&
                    (
                        locationData.current.length > 0 ||
                        locationData.previous.length > 0 ||
                        locationData.moreDetails.length > 0
                    ) &&
                    <CardIcon
                        color="primary"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "location" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("location")}
                    >
                        <Tooltip title="محل اقامت">
                            <LocationOnRoundedIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                {
                    audioData &&
                    audioData.length > 0 &&
                    <CardIcon
                        color="tyrianPurple"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "sound" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("sound")}
                    >
                        <Tooltip title="صدا">
                            <VolumeUpIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                {
                    pictureData &&
                    pictureData.length > 0 &&
                    <CardIcon
                        color="phosphor"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "picture" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("picture")}
                    >
                        <Tooltip title="تصاویر">
                            <PhotoLibraryIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                {
                    videoData &&
                    videoData.length > 0 &&
                    <CardIcon
                        color="orange"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "video" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("video")}
                    >
                        <Tooltip title="ویدیو">
                            <VideocamIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                {
                    targetType === 0 &&
                    jobData &&
                    jobData.length > 0 &&
                    <CardIcon
                        color="info"
                        className={"cursor-pointer default-graph " + `${selectedDiagram === "job" && "active-graph"}`}
                        onClick={() => setSelectedDiagram("job")}
                    >
                        <Tooltip title="اطلاعات شغلی">
                            <WorkIcon/>
                        </Tooltip>
                    </CardIcon>
                }
                <h3
                    className="p-3"
                    style={{color: "#000"}}
                >
                    {
                        selectedDiagram === 'job' && 'اطلاعات شغلی'
                    }
                    {
                        selectedDiagram === 'permIdentity' && 'اطلاعات شناسنامه ای'
                    }
                    {
                        selectedDiagram === 'location' && 'اطلاعات محل اقامت'
                    }
                    {
                        selectedDiagram === 'gavel' && 'نگرش و سوابق سیاسی'
                    }
                    {
                        selectedDiagram === 'personal' && 'نمودار شخصی سازی شده'
                    }
                    {
                        selectedDiagram === 'general' && 'نمودار ها'
                    }
                    {
                        selectedDiagram === 'work' && 'اطلاعات حقوقی'
                    }
                    {
                        selectedDiagram === 'sound' && 'صدا'
                    }
                    {
                        selectedDiagram === 'video' && 'ویدیو'
                    }
                    {
                        selectedDiagram === 'picture' && 'تصاویر'
                    }
                </h3>
            </CardHeader>
            <CardBody className='d-flex flex-column justify-content-center position-relative'>
                {
                    selectedDiagram === "general" &&
                    <div
                        className="d-flex flex-column justify-content-start position-absolute"
                        style={{
                            right: '-1.8rem',
                            zIndex: 1000
                        }}
                    >
                        {
                            generalData.all && generalData.all[0].children.length > 0 &&
                            <CardIcon
                                color="warning"
                                className={
                                    "cursor-pointer text-white default-graph d-flex justify-content-center align-items-center my-2 " +
                                    `${selectedGeneralDiagram === 'all' && "active-graph-r"}`
                                }
                                onClick={() => {
                                    setSelectedGeneralDiagram('all');
                                    generalData.all && onChangeDiagramData(generalData.all)
                                }}
                                style={{
                                    width: '5rem',
                                    height: '5rem',
                                    whiteSpace: 'nowrap',
                                    border: selectedGeneralDiagram === 'all' && '1px solid #fff',
                                    boxShadow: selectedGeneralDiagram === 'all' && '0px 0px 0px 3px #fb8c00',
                                    // borderRadius: '1rem',
                                }}
                            >
                                   <span className="text-white">
                                       همه
                                   </span>
                            </CardIcon>
                        }
                        {
                            generalData.others && Object.keys(generalData.others).map((d, i) =>
                                Object.values(generalData.others)[i][0].children.length > 0 &&
                                <CardIcon
                                    key={i}
                                    color="warning"
                                    className={
                                        "cursor-pointer text-white default-graph d-flex justify-content-center align-items-center my-2 " +
                                        `${selectedGeneralDiagram === d && "active-graph-r"}`
                                    }
                                    onClick={() => {
                                        setSelectedGeneralDiagram(d);
                                        onChangeDiagramData(Object.values(generalData.others)[i])
                                    }}
                                    style={{
                                        width: '5rem',
                                        height: '5rem',
                                        whiteSpace: 'nowrap',
                                        border: selectedGeneralDiagram === d && '1px solid #fff',
                                        boxShadow: selectedGeneralDiagram === d && '0px 0px 0px 3px #fb8c00',
                                        // borderRadius: '1rem',
                                    }}
                                >
                                   <span className="text-white">
                                       {d}
                                   </span>
                                </CardIcon>
                            )
                        }
                    </div>
                }
                <div
                    id="chartdiv"
                    className={"chartDivDiagram " + `${selectedDiagram !== "general" && "d-none"}`}
                    style={{height: `${diagramHeight}px`}}
                >
                </div>
                {
                    selectedDiagram === "general" &&
                    <div
                        className=""
                        style={styles.divButtonDiagram}
                    >
                        <ButtonGroup
                            orientation="vertical"
                            color="primary"
                            aria-label="vertical contained primary button group"
                            variant="text"
                        >
                            <Button
                                style={styles.btnDiagram}
                                onClick={() => diagramHeight < 2500 && setDiagramHeight(diagramHeight + 500)}
                            >
                                +
                            </Button>
                            <Button
                                style={styles.btnDiagram}
                                onClick={() => diagramHeight > 500 && setDiagramHeight(diagramHeight - 500)}
                            >
                                -
                            </Button>
                        </ButtonGroup>
                    </div>
                }
                {
                    selectedDiagram === "personal" &&
                    <div className="dx-viewport mt-4 ">
                        <div className="demo-container">
                            <GraphDiagram
                                readOnly
                                ref={(ref) => {
                                    diagramRef = ref
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    selectedDiagram === "work" &&
                    <div
                        className='work-graph w-100'
                        style={{overflow: 'auto'}}
                    >
                        <Tree
                            data={treeData}
                            height={treeWH * 10 < 500 ? 500 : treeWH * 10}
                            width={treeWH * 25 < 700 ? 700 : treeWH * 25}
                            svgProps={{
                                className: 'custom'
                            }}
                        />
                    </div>
                }
                {
                    selectedDiagram === "gavel" &&
                    <div
                        className='w-100 d-flex justify-content-between flex-wrap mt-4 pt-4'
                    >
                        {
                            gavelData && gavelData.map((d, i) =>
                                <PoliticalItem
                                    key={i}
                                    title={d.politicalPartyName}
                                    politicalPartyDescription={d.politicalPartyDescription}
                                    avatar={d.avatarId}
                                    description={d.description}
                                />
                            )
                        }
                    </div>
                }
                {
                    selectedDiagram === "location" && locationData &&
                    <div
                        className='w-100 mt-5'
                    >
                        {
                            locationData.current.length > 0 &&
                            <LivingPlaceItem
                                name="محل اقامت فعلی"
                                address={locationData.current}
                            />
                        }
                        {
                            locationData.previous.length > 0 &&
                            <LivingPlaceItem
                                name="محل اقامت سابق"
                                address={locationData.previous}
                            />
                        }
                        {
                            locationData && locationData.moreDetails.map((d, i) =>
                                <LivingPlaceItem
                                    key={i}
                                    name={d.rtlName}
                                    address={d.value}
                                />
                            )
                        }
                    </div>
                }
                {
                    selectedDiagram === "permIdentity" && identityData &&
                    <div
                        className='w-100 d-flex flex-wrap justify-content-center mt-5'
                        style={{overflow: 'auto'}}
                    >
                        {
                            identityData.birthPlace &&
                            <IdentityItem
                                title="محل تولد"
                                value={identityData.birthPlace}
                            />
                        }
                        {
                            identityData.birthDate &&
                            <IdentityItem
                                title="تاریخ تولد"
                                value={identityData.birthDate}
                            />
                        }
                        {
                            identityData.fatherName &&
                            <IdentityItem
                                title="نام پدر"
                                value={identityData.fatherName}
                            />
                        }
                        {
                            identityData.motherName &&
                            <IdentityItem
                                title="نام مادر"
                                value={identityData.motherName}
                            />
                        }
                        {
                            identityData.spouseName &&
                            <IdentityItem
                                title="نام همسر"
                                value={identityData.spouseName}
                            />
                        }
                        {
                            identityData.degreeOfEducation &&
                            <IdentityItem
                                title="مدرک تحصیلی"
                                value={identityData.degreeOfEducation}
                            />
                        }
                        {
                            identityData.passportCode &&
                            <IdentityItem
                                title="کد ملی یا پاسپورت"
                                value={identityData.passportCode}
                            />
                        }
                        {
                            identityData.moreDetails &&
                            identityData.moreDetails.map((item, i) =>
                                <IdentityItem
                                    key={i}
                                    title={item.rtlName}
                                    value={item.value}
                                />
                            )
                        }
                    </div>
                }
                {
                    selectedDiagram === "job" && jobData &&
                    <div
                        className={'w-100 mt-5 d-flex flex-wrap ' + (jobData.length > 2 && " justify-content-between")}
                    >
                        {
                            jobData.map((job, i) =>
                                <JobItemStudy
                                    key={i}
                                    title={job.title}
                                    place={job.locationName}
                                    activityTitle={job.activityTitle}
                                    activityPeriod={job.activityPeriod}
                                    moreDetails={job.moreDetails}
                                />
                            )
                        }
                    </div>
                }
                {
                    <div
                        className={`w-100 my-5 flex-wrap justify-content-center ${selectedDiagram !== "sound" ? ' d-none ' : ' d-flex '}`}
                    >
                        {
                            audioData &&
                            audioData.length > 0 &&
                            audioData.map((item, i) =>
                                item.fileManagerId &&
                                <MediaControlCard
                                    key={i}
                                    id={i}
                                    title={item.title}
                                    audioSource={getUrl(item.fileManagerId)}
                                    thumb={getUrl(item.thumb)}
                                    selectedDiagram={selectedDiagram}
                                />
                            )
                        }
                    </div>
                }
                {
                    selectedDiagram === "video" &&
                    <div
                        className={`w-100 my-5 flex-wrap justify-content-center d-flex`}
                    >

                        {
                            videoData &&
                            videoData.length > 0 &&
                            videoData.map((item, i) =>
                                <div
                                    key={i}
                                    className="player d-flex flex-column justify-content-center align-items-center"
                                >
                                    <div
                                        className="video-player d-flex align-items-end overflow-hidden dir-ltr mx-2 w-100"
                                    >
                                        <ReactVideo
                                            src={getUrl(item.fileManagerId)}
                                            poster={getUrl(item.thumb)}
                                            primaryColor="#ff6c53"
                                            // other props
                                        />
                                    </div>
                                    <div className="video-player-title py-3 d-flex justify-content-center">
                                        <span>{item.title}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    pictureData &&
                    pictureData.length > 0 &&
                    selectedDiagram === "picture" &&
                    <div
                        className={`w-100 my-5`}
                    >
                        <Gallery
                            id="image-gallery-study"
                            isOpen={openGallery}
                            images={images}
                            onClose={() => setOpenGallery(false)}
                            enableImageSelection={false}
                            tagStyle={{display: "none"}}
                            backdropClosesModal
                            showImageCount={false}
                            showLightboxThumbnails={false}
                            customControls={
                                [
                                    <div className="d-flex bg-dark w-100">
                                        <IconButton
                                            key="comments"
                                            className={classes.DeleteForeverRoundedIcon + " comments-button"}
                                            onClick={() => setCommentsOpenDialog(true)}
                                        >
                                            <CommentRoundedIcon/>
                                        </IconButton>
                                    </div>
                                    // ,
                                    // <CommentsGallery
                                    //     dispatch={props.dispatch}
                                    //     className="comments-image-gallery"
                                    //     currentItemId={currentItemId}
                                    //     UserDetails={props.UserDetails}
                                    // />
                                ]
                            }
                            currentImageWillChange={id => setCurrentImage(id)}
                        />
                    </div>
                }
            </CardBody>
        </Card>
    );
};

StudyGraphs.propTypes = {
    onChange: PropTypes.func
};

const mapState = () => ({});
export default connect(mapState)(StudyGraphs);