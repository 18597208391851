import React, {useState, useEffect} from 'react';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {SetMediaDrawer} from "../../redux/actions/actions";
import {connect} from "react-redux";
import {getUrl} from "../../functions";
import Table from "../Table/Table";

const ImageUploader = ({onAddImage, defaultData, onDelete, ...props}) => {
    const [selectedImage, setSelectedImage] = useState("list");
    const [chosenPicture, setChosenPicture] = useState(null);
    const [dataTable, setData] = useState([]);
    const [value, setValue] = useState('');

    useEffect(() => {
        const values = defaultData.map((data, i) => ({
            id: i,
            data: [data.thumb, data.title]
        }));
        setData(values);
    }, [defaultData]);

    return (
        <Card>
            <CardHeader stats icon>
                <CardIcon
                    color="phosphor"
                    className={"cursor-pointer default-graph " + `${selectedImage === "list" && "active-graph"}`}
                    onClick={() => setSelectedImage("list")}
                >
                    <PhotoLibraryIcon/>
                </CardIcon>
                <CardIcon
                    color="phosphor"
                    className={"cursor-pointer default-graph " + `${selectedImage === "add" && "active-graph"}`}
                    onClick={() => setSelectedImage("add")}
                >
                    <AddPhotoAlternateIcon/>
                </CardIcon>
            </CardHeader>
            <CardBody>
                {
                    selectedImage === "add" ?
                        <div>
                            <TextField
                                variant="outlined"
                                value={value}
                                label="عنوان"
                                type='text'
                                className="my-2 text-single-information"
                                onChange={(e) => {
                                    setValue(e.target.value)
                                }}
                            />
                            {/*<div className='dropzone'>*/}
                            {/*    <input*/}
                            {/*        type='file'*/}
                            {/*        accept='audio/*'*/}
                            {/*        onChange={(e)=> console.log(e.target.files[0])}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="d-flex flex-column mt-3">
                                <Fab
                                    variant="extended"
                                    color="secondary"
                                    onClick={() => props.dispatch(SetMediaDrawer({
                                        type: "image",
                                        value: chosenPicture,
                                        action: value => {
                                            setChosenPicture(value);
                                        }
                                    }))}
                                    style={{borderRadius: '.3rem'}}
                                >
                                    <span>انتخاب تصویر</span>
                                </Fab>
                                {
                                    chosenPicture &&
                                    (
                                        <div
                                            className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light">
                                            <img
                                                className="h-auto"
                                                style={{maxWidth: "100%", maxHeight: "20rem"}}
                                                src={getUrl(chosenPicture, 500)}
                                                alt="feature"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <Fab
                                variant="extended"
                                color="secondary"
                                onClick={() => {
                                    onAddImage(
                                        {
                                            title: value,
                                            thumb: chosenPicture,
                                        }
                                    );
                                    setSelectedImage("list");
                                    setChosenPicture(null);
                                    setValue('');
                                    setData(
                                        [
                                            ...dataTable,
                                            {
                                                id: dataTable.length,
                                                data: [chosenPicture, value]
                                            }
                                        ]
                                    );
                                }}
                                className="w-100 mt-3"
                                style={{borderRadius: '.3rem', backgroundColor: '#24bc1e'}}
                            >
                                <span>اضافه کردن</span>
                            </Fab>
                        </div>
                        :
                        <Table
                            onPaging={(event, value) => 'getTargets(value,targetType)'}
                            limitViews={12}
                            tableHeaderColor="warning"
                            tableHead={["آواتار", "نام"]}
                            tableData={dataTable || []}
                            totalAmount={10}
                            actions={{
                                // onRead: (key, ID) => {
                                //     // navigate("/admin/targets/study/" + ID);
                                // },
                                onDelete: async (key, ID) => {
                                    setData([...dataTable.filter(val => val.id !== ID)]);
                                    onDelete(ID)
                                },
                                // onEdit: isSubscriber ? null : (key, ID) => {
                                //     navigate("/admin/targets/editor/" + ID);
                                // }
                            }}
                        />
                }
            </CardBody>
        </Card>
    );
};

ImageUploader.propTypes = {
    onAddImage: PropTypes.func
};

export default connect()(ImageUploader);