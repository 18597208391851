import React, {useEffect, useState} from "react";
// @material-ui/icons components
import AddIcon from '@material-ui/icons/Add';
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Table from "../../components/Table/Table";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Axios from "axios";
import {baseUrl} from '../../appConfig';
import {apiToken} from "../../appConfig";
import {SetSiteLoading} from "../../redux/actions/actions";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {useNavigate} from "react-router-dom";
import {sendErrorNotification, sendNotification} from "../../functions";
import {useSnackbar} from "notistack";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

const ProjectsList = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [tableData, setTableData] = useState({});

    const isSubscriber = props.UserDetails.role === 2;

    const getProjects = async (index = 1) => {
        await props.dispatch(SetSiteLoading(true));
        await Axios.get(baseUrl + "/Projects" + (isSubscriber ? "/forsubscriber" : "") + "?pageIndex=" + index, {headers: {Authorization: apiToken}})
            .then(res => {
                const values = res.data.data.map(data => ({
                    id: data.projectId,
                    data: [data.avatarId, data.name, data.category ? data.category.name : "ندارد"]
                }));
                setTableData({
                    values,
                    countAll : res.data.data.countAll
                });
            }).catch(() => props.dispatch(SetSiteLoading(false)));
        await props.dispatch(SetSiteLoading(false));
    };

    const removeProject = async ID => {
        return await Axios.post(baseUrl + "/projects/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "پروژه با موفقیت حذف شد");
                    return true;
                }
                return false;
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
                return false;
            })
    };

    useEffect(() => {
        getProjects().then()
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center" color="primary">
                        <div>
                            <h4 className={classes.cardTitleWhite}>جدول پروژه ها</h4>
                            <p className="m-0">جدولی از پروژه های ثبت شده به ترتیب زمان ثبت شدن</p>
                        </div>
                        <Tooltip title="افزودن پروژه">
                            <IconButton color="inherit" onClick={() => navigate('/admin/projects/editor')}>
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                    </CardHeader>
                    <CardBody>
                        <Table
                            onPaging={(event, value) => getProjects(value)}
                            limitViews={12}
                            tableHeaderColor="primary"
                            tableHead={["آواتار", "نام", "دسته بندی"]}
                            tableData={tableData.values || []}
                            totalAmount={tableData.countAll}
                            actions={{
                                onRead: (key, ID) => {
                                    navigate("/admin/projects/study/" + ID);
                                },
                                onDelete: isSubscriber ? null : async (key, ID) => {
                                    removeProject(ID).then(res => {
                                        if (res) {
                                            setTableData(tableData.values.filter(val => val.id !== ID))
                                        }
                                    });
                                },
                                onEdit: isSubscriber ? null : (key, ID) => {
                                    navigate("/admin/projects/editor/" + ID);
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

const mapState = states => ({
    UserDetails : states.userDetails
});
export default connect(mapState)(ProjectsList);
