import React from "react";
import ReactDOM from "react-dom";
import {createStore} from "redux";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {SnackbarProvider} from "notistack";
import reducer from "./redux/reducer";
import App from "./App";

import 'semantic-ui-css/semantic.min.css'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import "devexpress-diagram/dist/dx-diagram.min"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-html5video/dist/styles.css';
import './assets/css/dx-diagram.min.css';
import "./assets/css/material-dashboard-react.css?v=1.8.0";
import "./assets/css/material-ui-rtl.css";
import "./assets/css/global.css";
import "./assets/css/content.min.css";

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <App/>
            </SnackbarProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);