import React from 'react';
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import SoundUploader from "../../../components/SoundUploader/SoundUploader";
import VideoUploader from "../../../components/VideoUploader/VideoUploader";
import ImageUploader from "../../../components/ImageUploader/ImageUploader";

const MultimediaUploaders = (
    {
        onAddSound,
        onAddVideo,
        onAddImage,
        onDeleteSound,
        onDeleteVideo,
        onDeleteImage,
        imageData = [],
        videoData = [],
        soundData = []
    }
) => {
    return (
        <GridContainer>
            <GridItem xs={12} lg={4}>
                <SoundUploader
                    defaultData={soundData}
                    onDelete={onDeleteSound}
                    onAddSound={onAddSound}
                />
            </GridItem>
            <GridItem xs={12} lg={4}>
                <VideoUploader
                    defaultData={videoData}
                    onDelete={onDeleteVideo}
                    onAddVideo={onAddVideo}
                />
            </GridItem>
            <GridItem xs={12} lg={4}>
                <ImageUploader
                    defaultData={imageData}
                    onDelete={onDeleteImage}
                    onAddImage={onAddImage}
                />
            </GridItem>
        </GridContainer>
    );
};

export default MultimediaUploaders;