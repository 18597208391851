import React, {useState} from "react";
import axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import {connect} from 'react-redux';
import {SetSiteLoading} from "../../../redux/actions/actions";


const SendComment = ({currentItemId, commentPlatform, onDone, replayData, removeReplay, ...props}) => {
    let [valueComment, setValueComment] = useState("");

    const postComment = async () => {
        if (valueComment !== "") {
            await props.dispatch(SetSiteLoading(true));
            await axios.post(
                baseUrl + `/comments`,
                {
                    content: valueComment,
                    toItemId: currentItemId,
                    commentPlatform: commentPlatform,
                    repliedFrom: replayData && replayData.commentId
                },
                {headers: {Authorization: apiToken}}
            )
                .then((res) => {
                    setValueComment("");
                    onDone(res.data.data);
                    removeReplay()
                });
            await props.dispatch(SetSiteLoading(false));
        }
    };

    return (
        <>
            <div
                className="px-2 pb-2"
                style={{
                    height: "auto",
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px -10px 1.1rem'
                }}
            >
                {
                    replayData &&
                    <div className="position-relative">
                        <TextField
                            className="mt-2"
                            label={replayData.userFullName}
                            value={replayData.content}
                            variant="filled"
                            disabled
                            fullWidth
                        />
                        <CloseIcon
                            className="position-absolute"
                            style={{
                                top: "1rem",
                                left: ".5rem",
                                fontSize: ".9rem"
                            }}
                            onClick={removeReplay}
                        />
                    </div>
                }
                <IconButton
                    onClick={postComment}
                    className="m-1"
                    size="medium"
                >
                    <SendIcon fontSize="small"/>
                </IconButton>
                <TextField
                    id="outlined-multiline-static"
                    label="دیدگاه خود را بنویسید"
                    value={valueComment}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                        setValueComment(event.target.value)
                    }}
                />
            </div>
        </>
    )
};

export default SendComment;