import React from 'react';
import './LivingPlaceItem.css'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';


const LivingPlaceItem = ({name, address}) => {
    return (
        <div className="LivingPlaceItem px-3 py-1 d-flex align-items-center">
            {
                name &&
                <Chip
                    className="my-2 py-4"
                    variant="h6"
                    style={{
                        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
                        color: '#fff',
                        borderRadius: '.3rem',
                        fontSize: '1.2rem'
                    }}
                    label={name + ' :'}
                />
            }
            <Typography className="my-2 px-3" variant="subtitle1">{address}</Typography>
        </div>
    );
};

export default LivingPlaceItem;