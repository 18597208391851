import React, {forwardRef, useImperativeHandle, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import AddBoxIcon from '@material-ui/icons/AddBox';
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {getUrl} from "../../functions";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(styles);
const Tasks = ({onCreate, onEdit, onRemove, switchOnExist = false, ...props}, ref) => {
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null);

    useImperativeHandle(ref, ()=> ({
        checked,
        setChecked
    }), [checked]);

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const {tasks, rtlActive} = props;
    const tableCellClasses = classnames(classes.tableCell, {
        [classes.tableCellRTL]: rtlActive
    });

    return (
        <Table className={classes.table}>
            <TableBody>
                {
                    tasks.map(value => (
                        <TableRow key={value.postId} className={classes.tableRow}>
                            <TableCell className={tableCellClasses}>
                                <Checkbox
                                    disabled={switchOnExist}
                                    checked={checked.indexOf(value.postId) !== -1}
                                    tabIndex={-1}
                                    onClick={() => handleToggle(value.postId)}
                                    checkedIcon={<Check className={classes.checkedIcon}/>}
                                    icon={<Check className={classes.uncheckedIcon}/>}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.root
                                    }}
                                />
                            </TableCell>
                            <TableCell className={tableCellClasses}>
                                <Avatar src={getUrl(value.fileManagerId)} />
                            </TableCell>
                            <TableCell className={tableCellClasses}>{value.title}</TableCell>
                            <TableCell className={classes.tableActions}>
                                {
                                    onCreate && (switchOnExist ? !checked.includes(value.postId) : true) && (
                                        <Tooltip
                                            title="افزودن"
                                            placement="top"
                                        >
                                            <IconButton
                                                aria-label="Create"
                                                className={classes.tableActionButton}
                                                onClick={()=> onCreate(value.postId)}
                                            >
                                                <AddBoxIcon
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                                {
                                    onEdit && (
                                        <Tooltip
                                            title="ویرایش"
                                            placement="top"
                                        >
                                            <IconButton
                                                aria-label="Edit"
                                                className={classes.tableActionButton}
                                                onClick={()=> onEdit(value.postId)}
                                            >
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                                {
                                    onRemove && (switchOnExist ? checked.includes(value.postId) : true) && (
                                        <Tooltip
                                            title="حذف"
                                            placement="top"
                                        >
                                            <IconButton
                                                aria-label="Close"
                                                className={classes.tableActionButton}
                                                onClick={()=> setDeleteModal(value.postId)}
                                            >
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.close
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
            <Dialog open={deleteModal} onClose={() => setDeleteModal(null)} PaperProps={{style: {width: 320}}}>
                <DialogTitle>
                    حذف آیتم
                </DialogTitle>
                <DialogContent>
                    <Typography variant="caption">
                        آیا از این کار خود مطمئن هستید؟
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="mx-2"
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={() => setDeleteModal(null)}
                    >
                        انصراف
                    </Button>
                    <Button
                        className="mx-2"
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            onRemove(deleteModal);
                            setDeleteModal(null)
                        }}
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
        </Table>
    );
}

Tasks.propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.node),
    rtlActive: PropTypes.bool
};

export default forwardRef(Tasks);
