import React, {useEffect, useState} from 'react';
import Table from "../../../components/Table/Table";
import GridItem from "../../../components/Grid/GridItem";
import {SetSiteLoading} from "../../../redux/actions/actions";
import Axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import {connect} from "react-redux";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CardBody from "../../../components/Card/CardBody";
import {makeStyles} from "@material-ui/core/styles";
import SocialMediaHandleDialog from "./SocialMediaHandleDialog/SocialMediaHandleDialog";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";


const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

let dialogRef;
const SocialMediaTable = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [tableData, setTableData] = useState({
        data: [],
        countAll: 0
    });

    useEffect(() => {
        getSocialMedias().then();
        // eslint-disable-next-line
    }, []);

    const getSocialMedias = async (index = 1) => {
        await props.dispatch(SetSiteLoading(true));
        await Axios.get(baseUrl + "/socialMedias?pageIndex=" + index + "&returnValue=3", {headers: {Authorization: apiToken}})
            .then(res => {
                const data = res.data.data.map(data => ({
                    id: data.socialMediaId,
                    data: [data.socialMediaId, data.avatarId, data.name, data.baseAddress]
                }));
                setTableData({
                    data,
                    countAll: res.data.countAll
                });

            });
        await props.dispatch(SetSiteLoading(false));
    };

    const onCreateSocialMedia = (data, isEditMode) => {
        if (isEditMode) {
            const currentData = tableData.data.filter(val => val.id === data.socialMediaId)[0];
            if(currentData){
                const index = tableData.data.indexOf(currentData);
                tableData.data[index].data = [data.socialMediaId, data.avatarId, data.name, data.baseAddress];
                setTableData({...tableData});
            }
        }
        else
            setTableData(
                {
                    data: [{
                        id : data.socialMediaId,
                        data : [data.socialMediaId, data.avatarId, data.name, data.baseAddress]
                    }, ...tableData.data],
                    countAll: tableData.countAll + 1
                }
            )
    };

    const removeSocialMedia = async ID => {
        await Axios.post(baseUrl + "/socialMedias/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    sendNotification(enqueueSnackbar, "فضای مجازی شما با موفقیت حذف شد");
                    getSocialMedias();
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message)
                }
            })
    };

    return (
        <GridItem xs={12} lg={6}>
            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center" color="info">
                    <div>
                        <h4 className={classes.cardTitleWhite}>جدول فضاهای مجازی</h4>
                        <p className="m-0">جدولی از فضاهای مجازی های ثبت شده به ترتیب زمان ثبت شدن</p>
                    </div>
                    <Tooltip title="افزودن فضای مجازی">
                        <IconButton color="inherit" onClick={() => dialogRef.setCreateOrEditDialog(true)}>
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
                </CardHeader>
                <CardBody>
                    <Table
                        tableHeaderColor="info"
                        tableHead={["آیدی", "آواتار", "نام", "آدرس پایه"]}
                        totalAmount={tableData.countAll}
                        tableData={tableData.data}
                        limitViews={3}
                        onPaging={(event, value) => getSocialMedias(value)}
                        actions={
                            {
                                onDelete: async (key, ID) => {
                                    await removeSocialMedia(ID)
                                },
                                onEdit: (key, ID) => {
                                    dialogRef.setCreateOrEditDialog(
                                        {
                                            id: ID,
                                            data: {
                                                avatarId: tableData.data[key].data[1],
                                                name: tableData.data[key].data[2],
                                                baseAddress: tableData.data[key].data[3],
                                            }
                                        });
                                }
                            }
                        }
                    />
                </CardBody>
            </Card>
            <SocialMediaHandleDialog
                ref={ref => {
                    dialogRef = ref
                }}
                onSubmit={onCreateSocialMedia}
            />
        </GridItem>
    );
};

export default connect()(SocialMediaTable);