import {
  tyrianPurpleCardHeader,
  orangeCardHeader,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  greenCardHeader,
  grayColor,
  heliotropeCardHeader,
  phosphorCardHeader
} from "../../material-dashboard-react";

const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$greenCardHeader,&$tyrianPurpleCardHeader,&$orangeCardHeader,&$heliotropeCardHeader,&$phosphorCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  greenCardHeader,
  tyrianPurpleCardHeader,
  orangeCardHeader,
  heliotropeCardHeader,
  phosphorCardHeader,
};

export default cardIconStyle;
