import React, {useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import PropTypes from "prop-types";
import ProgressBar from "./ProgressBar/ProgressBar";
import {getUrl} from "../../../functions";
import {Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from '@material-ui/icons/Refresh';
import LinkIcon from '@material-ui/icons/Link';
import './QuickUploader.css';
import Fab from "@material-ui/core/Fab";
import {connect} from "react-redux";
import {SetMediaDrawer} from "../../../redux/actions/actions";

const QuickUploader = ({onUploadCompleted, defaultValue,imageType, ...props}) => {
    const [uploading, setUploading] = useState(false);
    const [value, setValue] = useState(null);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    useEffect(()=> {
        if(defaultValue)
            setValue(getUrl(defaultValue))
        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        acceptedFiles.length > 0 && uploading ?
            <ProgressBar
                file={acceptedFiles[0]}
                onDone={value => {
                    onUploadCompleted(value);
                    setValue(getUrl(value));
                    setUploading(false);
                }}
            />
            :
            <div className={value ? "position-relative haveValue" : ""}>
                <Typography dir="ltr" noWrap>{value}</Typography>
                <section className="container uploader">
                    <div
                        {
                            ...getRootProps({
                                className: 'dropzone'
                            })
                        }
                    >
                        <input {...getInputProps({onChange : () => setUploading(true), accept : imageType && `image/*`})} />
                        {
                            value ?
                                <RefreshIcon />
                                :
                                <p>برای آپلود، فایل را به اینجا بکشید و یا بر روی این قست کلیک کنید</p>
                        }
                    </div>
                </section>
                <Tooltip title={value} placement="top">
                    <div className={"openLink" + (value ? "" : " d-none")} onClick={()=> window.open(value)}>
                        <LinkIcon />
                    </div>
                </Tooltip>
                <Fab
                    variant="extended"
                    color="secondary"
                    onClick={() => props.dispatch(SetMediaDrawer({
                        type : "image",
                        value: value,
                        action: value => {
                            onUploadCompleted(value);
                            setValue(getUrl(value));
                        }
                    }))}
                    style={{borderRadius: '.3rem'}}
                >
                    <span>بازکردن کتابخانه رسانه</span>
                </Fab>
            </div>
    );
};

QuickUploader.propTypes = {
    defaultValue : PropTypes.string,
    onUploadCompleted: PropTypes.func.isRequired,
    imageType: PropTypes.bool,
};

export default connect()(QuickUploader);