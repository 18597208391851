let apiToken = localStorage.getItem("OsintArchiveAccessKey");
apiToken = apiToken ? "Bearer " + apiToken : null;

// const host = "http://localhost:2020";
const host = "https://osint.devuto.ir";
module.exports = {
    host,
    baseUrl: host + "/v1",
    apiToken
};
