/*eslint-disable*/
import React, {useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Link, NavLink} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../../components/Navbars/AdminNavbarLinks";
import RTLNavbarLinks from "../../components/Navbars/RTLNavbarLinks";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";
import {connect} from "react-redux";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(styles);

function Sidebar(props) {
    const classes = useStyles();
    const [openItem, setOpenItem] = useState({});

    const handleClickOp = (selected) => {
        open && setOpenItem({...openItem, [selected]: !openItem[selected]});
    };

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1;
    }

    function getChildren(prop, i) {
        let activePro = " ";
        let listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
                [" " + classes[color]]: true
            });
        } else {
            listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path)
            });
        }
        const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        if (prop.children) {
            return (
                <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={i}
                >
                    <ListItem
                        button
                        onClick={() => handleClickOp(prop.name)}
                    >
                        {typeof prop.icon === "string" ? (
                            <Icon
                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                    [classes.itemIconRTL]: props.rtlActive
                                })}
                            >
                                {prop.icon}
                            </Icon>
                        ) : (
                            <prop.icon
                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                    [classes.itemIconRTL]: props.rtlActive
                                })}
                            />
                        )}
                        <ListItemText
                            primary={props.rtlActive ? prop.rtlName : prop.name}
                            className={classNames(classes.itemText, whiteFontClasses, {
                                [classes.itemTextRTL]: props.rtlActive
                            })}
                            disableTypography={true}
                        />
                        {!(openItem[prop.name]) ? <ExpandMore/> : <ExpandLess/>}
                    </ListItem>
                    <Collapse
                        in={(openItem[prop.name])}
                        timeout="auto"
                    >
                        {
                            prop.children.map((d, index) =>
                                getChildren(d, index)
                            )
                        }
                    </Collapse>
                </NavLink>
            )
        } else {
            return (
                <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={i}
                >
                    <ListItem
                        button
                        className={classes.itemLink + listItemClasses}
                    >
                        {typeof prop.icon === "string" ? (
                            <Icon
                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                    [classes.itemIconRTL]: props.rtlActive
                                })}
                            >
                                {prop.icon}
                            </Icon>
                        ) : (
                            <prop.icon
                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                    [classes.itemIconRTL]: props.rtlActive
                                })}
                            />
                        )}
                        <ListItemText
                            primary={props.rtlActive ? prop.rtlName : prop.name}
                            className={classNames(classes.itemText, whiteFontClasses, {
                                [classes.itemTextRTL]: props.rtlActive
                            })}
                            disableTypography={true}
                        />
                    </ListItem>
                </NavLink>
            )
        }
    }

    const {color, logo, image, logoText, routes} = props;
    const links = (
        <List className={classes.list}>
            {routes.filter(x => !x.hideInSidebar && x.accessRole >= props.UserDetails.role).map((prop, key) => {
                if (prop.path === "/upgrade-to-pro") {
                }
                return (
                    getChildren(prop, key)
                );
            })}
        </List>
    );
    const brand = (
        <div className={classes.logo}>
            <a
                href="/"
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                })}
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img}/>
                </div>
                {logoText}
            </a>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={props.rtlActive ? "left" : "right"}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {props.rtlActive ? <RTLNavbarLinks/> : <AdminNavbarLinks/>}
                        {links}
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={props.rtlActive ? "right" : "left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool
};

const mapState = states => ({
    UserDetails: states.userDetails
});

export default connect(mapState)(Sidebar);