export const Authenticated = value => ({
    type:"AUTHENTICATE",
    value: value
});

export const SetUserDetails = value => ({
    type:"SETUSERDETAILS",
    value: value
});

export const SetSiteLoading = value => ({
    type:"SETSITELOADING",
    value: value
});

export const SetMediaDrawer = value => ({
    type:"SETMEDIADRAWER",
    value: value
});

export const SetUserDialog = value => ({
    type:"USERDIALOGHANDLER",
    value: value
});

export const CreateLocationHandler = value => ({
    type:"CREATELOCATIONHANDLER",
    value: value
});

export const SetUserInformationDrawer = value => ({
    type:"SETUSERINFORMATIONDRAWER",
    value: value
});