import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Axios from "axios";
import {baseUrl, apiToken} from "../../../appConfig";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {components} from "react-select";
//css
import './JobInformationTable.css'
import 'leaflet/dist/leaflet.css'
//icons
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import WorkIcon from '@material-ui/icons/Work';
import PlaylistAddRoundedIcon from "@material-ui/icons/PlaylistAddRounded";
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
//components
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";
import {CreateLocationHandler} from "../../../redux/actions/actions";
import {connect} from "react-redux";
import {
    Divider,
    TextField,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide
} from "@material-ui/core";
import AddTextField from "../AddTextField/AddTextField";
import {sendErrorNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '1px solid #fff',
    },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let jobTitles = [];

const AddJobTitleDialog = ({onSubmit, onClose, open}) => {
    const [name, setName] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        jobTitles = [];
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">اضافه کردن شغل</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="عنوان"
                    type="text"
                    className="my-2 w-100"
                    value={name ? name : ""}
                    onChange={(e) =>
                        setName(e.target.value)
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    لغو
                </Button>
                <Button
                    onClick={() => {
                        if (name !== null) {
                            onClose();
                            onSubmit(
                                {
                                    title: name,
                                    locationId: 0,
                                    partners: [],
                                    activityTitle: "",
                                    businessTrips: [],
                                    moreDetails: []
                                }
                            );
                            setName(null);
                        } else {
                            sendErrorNotification(enqueueSnackbar, "لطفا عنوان شغل را وارد کنید");
                        }
                    }}
                    color="primary"
                >
                    تایید
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const JobTitleItem = ({index, onChange, data, onClickPlayListAdd, onClickAddField}) => {
    const [locationId, setLocationId] = useState(null);
    // const [trips, setTrips] = useState(null);

    useEffect(() => {
        setLocationId(data.locationId ? {value: data.locationId, label: data.locationName} : null);
        // setTrips(data.businessTrips.map((x, i) => {
        //     return {value: i, label: x}
        // }))
        // eslint-disable-next-line
    }, []);
    const setDataPost = (d, index, value) => {
        jobTitles[index].moreDetails = jobTitles[index].moreDetails.filter(object =>
            object.rtlName !== d.rtlName
        );
        jobTitles[index].moreDetails = [...jobTitles[index].moreDetails, {
            rtlName: d.rtlName,
            type: d.type,
            value: value,
            size: d.size
        }];
        onChange(jobTitles);
    };

    const removeItem = (item, index) => {
        jobTitles[index].moreDetails = jobTitles[index].moreDetails.filter(object =>
            object.rtlName !== item.rtlName
        );
        onChange(jobTitles);
    };

    const promiseOptions = (inputValue = "") => {
        return new Promise(resolve => {
            Axios.get(
                baseUrl + `/locations/search?keyword=${inputValue}`,
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data.data);
                    }
                })
        });
    };

    const setSelectedLocation = (value = {value: 0}, index) => {
        setLocationId(value);
        jobTitles[index].locationId = parseInt(value.value);
        onChange(jobTitles);
    };

    // const setSelectedTrips = (value = [], index) => {
    //     const selectedTrips = value.map(val => {
    //         return val.label
    //     });
    //     if (!value) {
    //         jobTitles[index].businessTrips = []
    //     } else {
    //         setTrips(value);
    //         jobTitles[index].businessTrips = selectedTrips;
    //     }
    //     onChange(jobTitles);
    // };

    return (
        <Grid
            key={index}
            className="d-flex flex-wrap align-items-center"
        >
            <GridItem
                xs={12}
            >
                <div
                    className="d-flex align-items-center"
                >
                    <h4 className="mb-2">
                        {data.title}
                    </h4>
                    <IconButton
                        onClick={async () => {
                            const newData = await jobTitles.filter(x => x.title !== data.title);
                            jobTitles = newData;
                            await onChange(newData);
                        }}
                    >
                        <HighlightOffTwoToneIcon/>
                    </IconButton>
                </div>
            </GridItem>
            <GridItem
                xs={12}
                lg={6}
            >
                <div className="position-relative d-flex align-items-center">
                    <AsyncSelect
                        isRtl
                        cacheOptions
                        defaultOptions
                        value={locationId}
                        placeholder="محل کار را جستجو و انتخاب کنید ..."
                        noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                        loadingMessage={() => "در حال بارگزاری .."}
                        styles={{
                            menu: base => ({...base, zIndex: 555555}),
                            control: base => ({...base, height: "3.81rem"})
                        }}
                        loadOptions={inputValue =>
                            promiseOptions(inputValue)
                        }
                        className="my-2 w-100"
                        onChange={(selected) => setSelectedLocation(selected, index)}
                    />
                    <PlaylistAddRoundedIcon
                        className="position-absolute cursor-pointer"
                        style={{
                            top: "25px",
                            left: "50px",
                            width: "1.5rem",
                            height: "1.5rem"
                        }}
                        onClick={onClickPlayListAdd}
                    />
                </div>
            </GridItem>
            <GridItem
                xs={12}
                lg={6}
            >
                <TextField
                    variant="outlined"
                    value={data.activityPeriod}
                    label="مدت فعالیت"
                    type="text"
                    className="my-2 text-single-information"
                    onChange={(e) => {
                        jobTitles[index].activityPeriod = e.target.value;
                        onChange(jobTitles);
                    }}
                />
            </GridItem>
            {/*<GridItem*/}
            {/*    xs={12}*/}
            {/*    lg={6}*/}
            {/*>*/}
            {/*    <div className="position-relative d-flex align-items-center">*/}
            {/*        <AsyncSelect*/}
            {/*            isMulti*/}
            {/*            isRtl*/}
            {/*            cacheOptions*/}
            {/*            defaultOptions*/}
            {/*            isClearable={false}*/}
            {/*            value={trips}*/}
            {/*            placeholder="مسافرت های کاری ..."*/}
            {/*            noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}*/}
            {/*            loadingMessage={() => "در حال بارگزاری .."}*/}
            {/*            styles={{*/}
            {/*                menu: base => ({...base, zIndex: 555555}),*/}
            {/*                control: base => ({...base, height: "3.81rem"})*/}
            {/*            }}*/}
            {/*            loadOptions={inputValue =>*/}
            {/*                promiseOptions(inputValue)*/}
            {/*            }*/}
            {/*            className="my-2 w-100"*/}
            {/*            onChange={(selected) => {*/}
            {/*                selected ?*/}
            {/*                    setSelectedTrips(selected, index)*/}
            {/*                    :*/}
            {/*                    setSelectedTrips(null, index)*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <PlaylistAddRoundedIcon*/}
            {/*            className="position-absolute cursor-pointer"*/}
            {/*            style={{*/}
            {/*                top: "25px",*/}
            {/*                left: "50px",*/}
            {/*                width: "1.5rem",*/}
            {/*                height: "1.5rem"*/}
            {/*            }}*/}
            {/*            onClick={onClickPlayListAdd}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</GridItem>*/}
            <GridItem
                xs={12}
                lg={9}
            >
                <TextField
                    variant="outlined"
                    value={data.activityTitle}
                    label="سمت"
                    type="text"
                    className="my-2 text-single-information"
                    onChange={(e) => {
                        jobTitles[index].activityTitle = e.target.value;
                        onChange(jobTitles);
                    }}
                />
            </GridItem>
            {data.moreDetails && data.moreDetails.map((moreDetail, i) =>
                <GridItem
                    key={i}
                    style={{position: "relative"}}
                    className="text-field-parent"
                    xs={12}
                    sm={6}
                    lg={moreDetail.size * 3}
                >
                    <TextField
                        variant="outlined"
                        value={moreDetail.value}
                        label={moreDetail.rtlName}
                        type={moreDetail.type}
                        className="my-2 text-single-information"
                        onChange={(e) =>
                            setDataPost(moreDetail, index, e.target.value)
                        }
                    />
                    <IconButton
                        onClick={() => removeItem(moreDetail, index)}
                        className="icon-button-mini"
                    >
                        <CloseRoundedIcon/>
                    </IconButton>
                </GridItem>
            )}
            <GridItem sm={3}>
                <IconButton
                    color="primary"
                    aria-label="add an alarm"
                    onClick={() => {
                        onClickAddField(index);
                    }}
                >
                    <AddRoundedIcon/>
                </IconButton>
            </GridItem>
            <GridItem sm={12}>
                <Divider className="my-3"/>
            </GridItem>
        </Grid>
    )
};

const JobTitleItemStudy = ({index, data, labelColor}) => {
    // let trips = data.businessTrips.map((x, i) => {
    //     return {value: i, label: x}
    // });
    let locationId = {value: data.locationId, label: data.locationName};

    // const MultiValueRemove = () => ('');

    return (
        <Grid
            key={index}
            className="d-flex flex-wrap align-items-center"
        >
            <GridItem
                xs={12}
            >
                <div
                    className="d-flex align-items-center"
                >
                    <h4 className="mb-2">
                        {data.title}
                    </h4>
                </div>
            </GridItem>
            <GridItem
                xs={12}
                lg={6}
            >
                <div className="position-relative d-flex flex-column align-items-center">
                    <span>محل</span>
                    <AsyncSelect
                        isRtl
                        cacheOptions
                        defaultOptions
                        value={locationId}
                        placeholder="وارد نشده ..."
                        noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                        loadingMessage={() => "در حال بارگزاری .."}
                        styles={{
                            menu: base => ({...base, zIndex: 555555}),
                            control: base => ({...base, height: "3.81rem"}),
                            singleValue: base => ({
                                ...base,
                                padding: 5,
                                borderRadius: 3,
                                background: labelColor,
                                color: 'white',
                            }),
                        }}
                        className="my-2 w-100"
                        isDisabled
                    />
                </div>
            </GridItem>
            <GridItem
                xs={12}
                lg={6}
            >
                <div className="position-relative d-flex flex-column align-items-center">
                    <span>مدت فعالیت</span>
                    <TextField
                        variant="outlined"
                        value={data.activityPeriod}
                        label={data.activityPeriod ? '' : "وارد نشده ..."}
                        type="text"
                        className="my-2 text-single-information"
                        disabled
                    />
                </div>
            </GridItem>
            {/*<GridItem*/}
            {/*    xs={12}*/}
            {/*    lg={6}*/}
            {/*>*/}
            {/*    <div className="position-relative d-flex flex-column align-items-center">*/}
            {/*        <span>مسافرت های کاری</span>*/}
            {/*        <AsyncSelect*/}
            {/*            isMulti*/}
            {/*            isRtl*/}
            {/*            cacheOptions*/}
            {/*            defaultOptions*/}
            {/*            isClearable={false}*/}
            {/*            value={trips}*/}
            {/*            components={{MultiValueRemove}}*/}
            {/*            placeholder="وارد نشده ..."*/}
            {/*            noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}*/}
            {/*            loadingMessage={() => "در حال بارگزاری .."}*/}
            {/*            styles={{*/}
            {/*                menu: base => ({...base, zIndex: 555555}),*/}
            {/*                control: base => ({...base, height: "3.81rem"}),*/}
            {/*                multiValueLabel: base => ({*/}
            {/*                    ...base,*/}
            {/*                    background: labelColor,*/}
            {/*                    color: 'white',*/}
            {/*                }),*/}
            {/*            }}*/}
            {/*            className="my-2 w-100"*/}
            {/*            isDisabled*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</GridItem>*/}
            <GridItem
                xs={12}
                lg={9}
            >
                <TextField
                    variant="outlined"
                    value={data.activityTitle}
                    label="سمت"
                    type="text"
                    className="my-2 text-single-information"
                    disabled
                />
            </GridItem>
            {
                data.moreDetails &&
                data.moreDetails.map((moreDetail, i) =>
                    <GridItem
                        key={i}
                        style={{position: "relative"}}
                        className="text-field-parent"
                        xs={12}
                        sm={6}
                        lg={moreDetail.size * 3}
                    >
                        <TextField
                            variant="outlined"
                            value={moreDetail.value}
                            label={moreDetail.rtlName}
                            type={moreDetail.type}
                            className="my-2 text-single-information"
                            disabled
                        />
                    </GridItem>
                )}
            <GridItem sm={12}>
                <Divider className="my-3"/>
            </GridItem>
        </Grid>
    )
};

const JobInformationTable = ({onChange, defaultValue, forStudy, ...props}) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openJobTitleDialog, setOpenJobTitleDialog] = React.useState(false);
    const [key, setKey] = React.useState();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (defaultValue && defaultValue.length > 0) {
            setData(defaultValue);
            jobTitles = defaultValue
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        <Card style={{minHeight: '20rem'}}>
            <CardHeader stats icon>
                <CardIcon color="info">
                    <WorkIcon/>
                </CardIcon>
                {
                    !forStudy &&
                    <CardIcon
                        style={{cursor: "pointer"}}
                        onClick={setOpenJobTitleDialog}
                        color="info"
                    >
                        <Tooltip title="اضافه کردن شغل">
                            <AddRoundedIcon/>
                        </Tooltip>
                    </CardIcon>
                }

                {
                    forStudy ?
                        <h3
                            className="p-3"
                            style={{color: "#000"}}
                        >
                            اطلاعات شغلی
                        </h3>
                        :
                        <div className="d-flex align-items-center">
                            <h3
                                className="p-3"
                                style={{color: "#000"}}
                            >
                                اطلاعات شغلی
                            </h3>
                            <HtmlTooltip
                                placement="top"
                                title={
                                    <div className="d-flex flex-column">
                                <span>
                                    با کلیک بر روی دکمه ی نمایش میتوانید تغییرات اعمال شده را در صفحه ی نمایش مشاهده کنید.
                                </span>
                                        <span className="my-1">
                                    توجه: اگر فیلد مورد نیاز برای اطلاعات هر شغل وجود ندارد، می توانید فیلد مورد نیاز را از طریق دکمه افزودن در انتهای فیلد های مربوط به هر شغل اضافه کنید.
                                </span>
                                        <span className="my-1">
                                    توجه: اگر سوژه ای بیشتر از یک شغل دارد، از باکس اطلاعات شغلی قسمت بالا سمت چپ می توانید شغل های دیگر را اضافه کنید.
                                </span>
                                    </div>
                                }
                            >
                                <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                            </HtmlTooltip>
                        </div>
                }
            </CardHeader>
            <CardBody>
                {
                    data &&
                    data.length === 0 &&
                    <div
                        className="w-100 d-flex align-items-center justify-content-center"
                        style={{height: '13rem'}}
                    >
                        <Typography variant="h6">
                            {
                                forStudy
                                    ?
                                    'هیچ شغلی وجود ندارد'
                                    :
                                    'هیچ شغلی اضافه نشده است برای اضافه کردن شغل روی دکمه ی مثبت بالا کلیک کنید'
                            }
                        </Typography>
                    </div>
                }
                {
                    forStudy
                        ?
                        data.map((d, index) =>
                            <JobTitleItemStudy
                                data={d}
                                key={index}
                                labelColor="linear-gradient(60deg, #26c6da, #00acc1)"
                            />
                        )
                        :
                        data.map((d, index) =>
                            <JobTitleItem
                                key={index}
                                data={d}
                                index={index}
                                onChange={(data) => {
                                    setData([...data]);
                                    onChange(data)
                                }}
                                onClickAddField={(key) => {
                                    setKey(key);
                                    setOpenDialog(!openDialog)
                                }}
                                onClickPlayListAdd={() =>
                                    props.dispatch(CreateLocationHandler(true))
                                }
                            />
                        )
                }
                <AddTextField
                    open={openDialog}
                    onClose={() => setOpenDialog(!openDialog)}
                    onSubmit={(data) => {
                        jobTitles[key].moreDetails = [
                            ...jobTitles[key].moreDetails,
                            data
                        ];
                    }}
                />
                <AddJobTitleDialog
                    open={openJobTitleDialog}
                    onClose={() => setOpenJobTitleDialog(!openJobTitleDialog)}
                    onSubmit={(data) => {
                        jobTitles = [
                            ...jobTitles,
                            data
                        ];
                        setData([...jobTitles])
                    }}
                />
            </CardBody>
        </Card>
    );
};

JobInformationTable.propTypes = {
    onChange: PropTypes.func
};

const mapState = () => ({});
export default connect(mapState)(JobInformationTable);