import React, {useState} from 'react';
import {
    makeStyles,
    Typography,
    Grid,
    Box,
    Paper,
    TextField,
    CssBaseline,
    Button,
    Avatar
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import background from '../../assets/img/BackLogin.png';
import {SetSiteLoading} from "../../redux/actions/actions";
import {sendErrorNotification, sendNotification} from "../../functions";
import {baseUrl} from '../../appConfig';
import Axios from "axios";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © Osint archive '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + background + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignInSide = (props) => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [error, setError] = useState([]);

    const handleSubmit = async (form) => {
        form.preventDefault();
        form.persist();
        // eslint-disable-next-line react/prop-types
        await props.dispatch(SetSiteLoading(true));
        const inputs = form.target.querySelectorAll('input');
        let values = {};
        for (let i = 0;i < inputs.length;i++){
            values[inputs[i].name] = inputs[i].value;
        }
        if(Object.values(values).filter(val => val === "").length > 0){
            setError(Object.keys(values).filter(val => values[val] === ""))
        }
        else{
            await Axios.post(`${baseUrl}/users/login`,
                {
                    "username": values.username,
                    "password": values.password
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(async res => {
                    if (res.status === 200) {
                        await localStorage.setItem('OsintArchiveAccessKey', res.data.data.token);
                        await sendNotification(enqueueSnackbar, "شما با موفقیت وارد شدید");
                        await window.location.replace('/admin/dashboard');
                    }
                })
                .catch(res => {
                    if (res.response && res.response.status === 400) {
                        sendErrorNotification(enqueueSnackbar, res.response.data.message);
                    } else {
                        sendErrorNotification(enqueueSnackbar);
                    }
                })
        }
        // eslint-disable-next-line react/prop-types
        await props.dispatch(SetSiteLoading(false));
    };

    const inputHandler = (input) => {
        if (input.target.value === "")
            setError([error, input.target.name]);
        else if(error.indexOf(input.target.name) > -1 && input.target.value !== "")
            setError(error.filter(x => x !== input.target.name))
    };

    const classes = useStyles();
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        ورود
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            required
                            fullWidth
                            autoFocus
                            error={error.indexOf("username") > -1}
                            helperText={error.indexOf("username") > -1 ? "لطفا نام کاربری خود را وارد کنید" : ""}
                            onChange={inputHandler}
                            id="username"
                            name="username"
                            variant="outlined"
                            margin="normal"
                            label="نام کاربری"
                            autoComplete="username"
                        />
                        <PasswordInput
                            required
                            fullWidth
                            error={error.indexOf("password") > -1}
                            helperText={error.indexOf("password") > -1 ? "لطفا پسورد خود را وارد کنید" : ""}
                            onChange={inputHandler}
                            name="password"
                            label="کلمه عبور"
                            variant="outlined"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            ورود
                        </Button>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </form>
                </div>
            </Grid>
            <Grid item xs={false} sm={4} md={9} className={classes.image}/>
        </Grid>
    );
};

const mapState = () => ({});
export default connect(mapState)(SignInSide);