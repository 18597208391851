import React, {useEffect, useState} from 'react';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import CardBody from "../../Card/CardBody";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import './DetailsTable.css'
import Grid from "@material-ui/core/Grid";
import GridItem from "../../Grid/GridItem";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '.9rem',
        border: '2px solid #fff',
    },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let dataDetails = {
    identity: {
        birthPlace: "",
        birthDate: "",
        melliCode: "",
        passportCode: "",
        fatherName: "",
        phoneNumber: "",
        job: "",
        moreDetails: []
    },
};

const DetailsTable = ({onChange, defaultValue}) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [size, setSize] = React.useState("1");
    const [rtlNameForSetDetails, setRtlNameForSetDetails] = React.useState("");
    const [typeForSetDetails, setTypeForSetDetails] = React.useState("text");
    let [data, setData] = useState([]);
    let defaultData = [
        {
            name: "birthPlace",
            rtlName: 'محل تولد',
            type: 'text',
            size: 1,
            value: ""
        },
        {
            name: "birthDate",
            rtlName: 'تاریخ تولد',
            type: 'text',
            size: 1,
            value: ""
        },
        {
            name: "fatherName",
            rtlName: 'نام پدر',
            type: 'text',
            size: 1,
            value: ""
        },
        {
            name: "motherName",
            rtlName: 'نام مادر',
            type: 'text',
            size: 1,
            value: ""
        },
        {
            name: "spouseName",
            rtlName: 'نام همسر',
            type: 'text',
            size: 1,
            value: ""
        },
        {
            name: "passportCode",
            rtlName: 'شماره پاسپورت یا کد ملی',
            type: 'text',
            size: 2,
            value: ""
        },
        {
            name: "degreeOfEducation",
            rtlName: 'مدرک تحصیلی',
            type: 'text',
            size: 1,
            value: ""
        },
    ];

    useEffect(() => {
        dataDetails = {
            identity: {
                birthPlace: "",
                birthDate: "",
                melliCode: "",
                passportCode: "",
                fatherName: "",
                phoneNumber: "",
                job: "",
                moreDetails: []
            },
        };
        if (defaultValue) {
            dataDetails.identity = defaultValue;
            const valueData = [
                {
                    name: "birthPlace",
                    rtlName: 'محل تولد',
                    type: 'text',
                    size: 1,
                    value: defaultValue.birthPlace
                },
                {
                    name: "birthDate",
                    rtlName: 'تاریخ تولد',
                    type: 'text',
                    size: 1,
                    value: defaultValue.birthDate
                },
                {
                    name: "fatherName",
                    rtlName: 'نام پدر',
                    type: 'text',
                    size: 1,
                    value: defaultValue.fatherName
                },
                {
                    name: "motherName",
                    rtlName: 'نام مادر',
                    type: 'text',
                    size: 1,
                    value: defaultValue.motherName
                },
                {
                    name: "spouseName",
                    rtlName: 'نام همسر',
                    type: 'text',
                    size: 1,
                    value: defaultValue.spouseName
                },
                {
                    name: "passportCode",
                    rtlName: 'شماره پاسپورت یا کد ملی',
                    type: 'text',
                    size: 2,
                    value: defaultValue.passportCode
                },
                {
                    name: "degreeOfEducation",
                    rtlName: 'مدرک تحصیلی',
                    type: 'text',
                    size: 1,
                    value: defaultValue.degreeOfEducation
                },
            ];
            setData(valueData)
        } else {
            setData(defaultData)
        }
    }, [defaultValue]);

    function handleChangeDialog() {
        setOpenDialog(!openDialog)
    }

    const arrayCheck = [
        "birthPlace",
        "birthDate",
        "melliCode",
        "passportCode",
        "fatherName",
        "motherName",
        "spouseName",
        "job",
        "degreeOfEducation",
        "phoneNumber"
    ];

    async function setDataPost(d, value) {
        const find = arrayCheck.filter(x => d.name === x).length > 0;
        if (find) {
            dataDetails.identity[d.name] = value
        } else {
            dataDetails.identity.moreDetails = dataDetails.identity.moreDetails.filter(object => object.rtlName !== d.rtlName);
            await dataDetails.identity.moreDetails.push({
                rtlName: d.rtlName,
                type: d.type,
                value: value,
                size: d.size
            });
        }
        onChange(dataDetails);
    }

    function removeItem(item) {
        dataDetails.identity.moreDetails = dataDetails.identity.moreDetails.filter(object =>
            object.rtlName !== item.rtlName
        );
        setData(data.filter(object =>
            object.rtlName !== item.rtlName
        ));
        onChange(dataDetails);
    }

    return (
        <Card>
            <CardHeader stats icon>
                <CardIcon color="success">
                    <SettingsApplicationsTwoToneIcon/>
                </CardIcon>
                <div className="d-flex align-items-center">
                    <h3 className="p-3" style={{color: "#000"}}>اطلاعات شناسنامه ای</h3>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <div className="d-flex flex-column">
                                <span>
                                    فیلد های زیر را با توجه به اطلاعات سوژه پر کنید.
                                </span>
                                <span className="my-1">
                                    توجه: اگر فیلد مورد نیاز برای اطلاعاتی از سوژه که مربوط به اطلاعات شناسنامه ای می باشد وجود ندارد، می توانید فیلد مورد نیاز را از طریق دکمه افزودن در انتهای فیلد ها اضافه کنید.
                                </span>
                            </div>
                        }
                    >
                        <HelpIcon className="cursor-pointer" style={{width: '1.5rem', color: '#bc7d27'}}/>
                    </HtmlTooltip>
                </div>
            </CardHeader>
            <CardBody>
                <Grid
                    className="d-flex flex-wrap align-items-center"
                >
                    {
                        data &&
                        data.map((item, key) =>
                            <GridItem
                                key={key}
                                xs={12}
                                sm={6}
                                lg={item.size * 3}
                            >
                                <TextField
                                    variant="outlined"
                                    value={item.value}
                                    label={item.rtlName}
                                    type={item.type}
                                    className="my-2 text-single-information"
                                    onChange={(e) => {
                                        setDataPost(item, e.target.value).then();
                                        data[key].value = e.target.value
                                        setData([...data])
                                    }}
                                />
                            </GridItem>
                        )
                    }
                    {
                        dataDetails.identity &&
                        dataDetails.identity.moreDetails.map((data, i) =>
                            <GridItem
                                key={i}
                                style={{position: "relative"}}
                                className="text-field-parent"
                                xs={12}
                                sm={6}
                                lg={data.size * 3}
                            >
                                <TextField
                                    variant="outlined"
                                    defaultValue={data.value}
                                    label={data.rtlName}
                                    type={data.type}
                                    className="my-2 text-single-information"
                                    onChange={(e) =>
                                        setDataPost(data, e.target.value)
                                    }
                                />
                                <IconButton
                                    onClick={() => removeItem(data)}
                                    className="icon-button-mini"
                                >
                                    <CloseRoundedIcon/>
                                </IconButton>
                            </GridItem>
                        )
                    }
                    <GridItem sm={3}>
                        <IconButton
                            color="primary"
                            aria-label="add an alarm"
                            onClick={handleChangeDialog}
                        >
                            <AddRoundedIcon/>
                        </IconButton>
                    </GridItem>
                </Grid>
                <Dialog
                    open={openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleChangeDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"فیلد مورد نظر خود را بسازید"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label="عنوان"
                            type="text"
                            className="my-2 w-100"
                            value={rtlNameForSetDetails}
                            onChange={(e) =>
                                setRtlNameForSetDetails(e.target.value)
                            }
                        />
                        <div className="d-flex justify-content-between align-items-end">
                            <span className="mb-2">
                                طول:
                            </span>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                defaultValue="1"
                                onChange={(e) => setSize(e.target.value)}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio color="primary"/>}
                                    label="1"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio color="primary"/>}
                                    label="2"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio color="primary"/>}
                                    label="3"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="4"
                                    control={<Radio color="primary"/>}
                                    label="4"
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleChangeDialog} color="primary">
                            لغو
                        </Button>
                        <Button
                            onClick={() => {
                                handleChangeDialog();
                                dataDetails.identity.moreDetails = [
                                    ...dataDetails.identity.moreDetails,
                                    {
                                        rtlName: rtlNameForSetDetails,
                                        type: typeForSetDetails,
                                        size: parseInt(size),
                                        value: ""
                                    }
                                ];
                                setRtlNameForSetDetails("");
                                setTypeForSetDetails("");
                            }}
                            color="primary"
                        >
                            تایید
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardBody>
        </Card>
    );
};

DetailsTable.propTypes = {
    onChange: PropTypes.func
};

export default DetailsTable;