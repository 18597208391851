import React, {useEffect, useState} from 'react';
// Core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Editor as TextEditor} from '@tinymce/tinymce-react';
import Publish from "../../components/Editor/Publish/Publish";
import FeatureImage from "../../components/Editor/FeatureImage/FeatureImage";
import MoreDetailsTable from "../../components/Editor/MoreDetailsTable/MoreDetailsTable";
import TitleBar from "../../components/Editor/TitleBar/TitleBar";
import {getUrl, sendErrorNotification, sendNotification} from "../../functions";
import {useNavigate, useParams} from "react-router-dom";
import {SetMediaDrawer, SetSiteLoading} from "../../redux/actions/actions";
import {useSnackbar} from "notistack";
import PoliticalTable from "../../components/Editor/PoliticalTable/PoliticalTable";
import SocialTable from "../../components/Editor/SocialTable/SocialTable";
import InformationTable from "../../components/Editor/InformationTable/InformationTable";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {apiToken} from "../../appConfig";
import {baseUrl} from '../../appConfig';
import Axios from "axios";
import './TargetsEditor.css'
// @material-ui/core components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import RelationsTable from "../../components/Editor/RelationsTable/RelationsTable";
import DetailsTable from "../../components/Editor/DetailsTable/DetailsTable";
import LivingPlace from "../../components/Editor/LivingPlace/LivingPlace";
import JobInformationTable from "../../components/Editor/JobInformationTable/JobInformationTable";
import GraphDiagram from "../../components/Diagram/GraphDiagram";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MultimediaUploaders from "./MultimediaUploaders/MultimediaUploaders";
import RelationsTableProject from "../../components/Editor/RelationsTableProject/RelationsTableProject";


let diagramRef;
const TargetsEditor = ({...props}) => {
    const navigate = useNavigate();
    const {targetId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    let [params, setParams] = useState({
        name: "",
        avatarId: null,
        description: '<p style="text-align : right">در این قسمت می توانید توضیحات مربوط به هدف را وارد کنید</p>',
        details: {
            jobTitles: [],
            graphData: "",
            picturs: [],
            videos: [],
            audios: [],
            pdf: []
        },
        relations: [],
        targetType: 0,
        politicalAttitudes: [],
        projectRelations: []
    });

    useEffect(() => {
        if (targetId && params.name.length < 1) {
            props.dispatch(SetSiteLoading(true));
            Axios.get(baseUrl + "/targets/" + targetId, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        setParams({
                            ...res.data.data
                        });
                        diagramRef.instance.import(res.data.data.details.graphData);
                    } else if (res.status === 204) {
                        sendErrorNotification(enqueueSnackbar, "محتوای مورد نظر وجود ندارد");
                        history.goBack();
                    }
                    props.dispatch(SetSiteLoading(false));
                })
                .catch(() => {
                    sendErrorNotification(enqueueSnackbar);
                    props.dispatch(SetSiteLoading(false));
                });
        }
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    const createPost = async () => {
        await props.dispatch(SetSiteLoading(true));
        params.details.graphData = diagramRef.instance.export();
        await Axios.post(baseUrl + "/targets", params, {headers: {Authorization: apiToken}})
            .then(async res => {
                if (res.status === 200) {
                    await sendNotification(enqueueSnackbar, "هدف مورد نظر شما اضافه شد");
                    navigate("/admin/targets");
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                }
                props.dispatch(SetSiteLoading(false));
            })
    };

    const editPost = async () => {
        await props.dispatch(SetSiteLoading(true));
        params.details.graphData = diagramRef.instance.export();
        await Axios.post(
            baseUrl + "/targets/edit/" + targetId, params,
            {headers: {Authorization: apiToken}}
        )
            .then(async res => {
                if (res.status === 200) {
                    await sendNotification(enqueueSnackbar, "هدف مورد نظر شما ویرایش شد");
                    setParams({...params})
                }
            })
            .catch(res => {
                if (res.response && res.response.status === 400) {
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                }
            });
        await props.dispatch(SetSiteLoading(false));
    };

    const onSelectType = (type) => {
        if (type === 0) {
            params.details = {
                graphData: ""
            };
        }
        params.targetType = type.target.value;
        setParams({...params})
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Paper className="bg-transparent">
                    <Box p={2}>
                        <GridContainer>
                            <GridItem
                                xs={12}
                                md={8}
                                xl={9}
                            >
                                <div className="d-flex flex-column flex-md-row TitleBar-p">
                                    <GridItem
                                        xs={12}
                                        md={8}
                                        xl={9}
                                    >
                                        <TitleBar
                                            label="نام سوژه"
                                            defaultValue={params.name}
                                            onChange={input => {
                                                params.name = input.target.value
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem
                                        xs={12}
                                        md={4}
                                        xl={3}
                                    >
                                        <FormControl
                                            variant="outlined"
                                            className="w-100 bg-white"
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">نوع سوژه</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={params.targetType}
                                                onChange={onSelectType}
                                                label="نوع سوژه"
                                            >
                                                <MenuItem value={0}>حقیقی</MenuItem>
                                                <MenuItem value={1}>حقوقی</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </div>
                                <Box mt={2}>
                                    <TextEditor
                                        id="full-featured-non-premium"
                                        value={params.description}
                                        init={{
                                            height: '800px',
                                            selector: 'textarea#full-featured-non-premium',
                                            menubar: true,
                                            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                            toolbar_mode: 'sliding',
                                            image_advtab: true,
                                            image_caption: true,
                                            file_picker_callback: (callback, value, meta) => {
                                                if (meta.filetype === 'file') {
                                                    props.dispatch(SetMediaDrawer({
                                                        type : "application",
                                                        action: value => {
                                                            callback(getUrl(value), { text: 'file' });
                                                        }
                                                    }))
                                                }
                                                if (meta.filetype === 'image') {
                                                    props.dispatch(SetMediaDrawer({
                                                        type : "image",
                                                        action: value => {
                                                            callback(getUrl(value), { alt: 'p-chosen' });
                                                        }
                                                    }))
                                                }
                                                if (meta.filetype === 'media') {
                                                    props.dispatch(SetMediaDrawer({
                                                        type : "video",
                                                        action: value => {
                                                            callback(getUrl(value), { alt: 'p-chosen' });
                                                        }
                                                    }))
                                                }
                                            },
                                        }}
                                        onEditorChange={text => {
                                            params.description = text
                                        }}
                                    />
                                </Box>
                                {
                                    params.targetType === 0 &&
                                    <PoliticalTable
                                        defaultValue={
                                            params.politicalAttitudes
                                                ?
                                                params.politicalAttitudes.map(data => ({
                                                    politicalParties: {
                                                        value: data.politicalPartyId,
                                                        label: data.politicalPartyName
                                                    },
                                                    title: data.title,
                                                    description: data.description,
                                                    targetPoliticalAttitudeId: data.targetPoliticalAttitudeId
                                                }))
                                                :
                                                []
                                        }
                                        onChange={value => {
                                            params.politicalAttitudes = value.map(data => {
                                                return {
                                                    targetPoliticalAttitudeId: data.targetPoliticalAttitudeId,
                                                    politicalPartyId: parseInt(data.politicalParties.value),
                                                    politicalPartyName: data.politicalParties.label,
                                                    title: data.title,
                                                    description: data.description
                                                }
                                            })
                                        }}
                                    />
                                }
                                <RelationsTableProject
                                    defaultValue={
                                        params.projectRelations ?
                                            params.projectRelations.map(data => ({
                                                target: {
                                                    value: data.projectId,
                                                    label: data.projectName
                                                },
                                                relation: {
                                                    value: data.relationId,
                                                    label: data.relationName
                                                },
                                                description: data.description,
                                            }))
                                            :
                                            []
                                    }
                                    onChange={value => {
                                        params.projectRelations = value.map(data => {
                                            return {
                                                projectId: parseInt(data.target.value),
                                                projectName: data.target.label,
                                                relationId: parseInt(data.relation.value),
                                                relationName: data.relation.label,
                                                description: data.description
                                            }
                                        });
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={4} xl={3}>
                                <Publish
                                    id={targetId}
                                    editMode={targetId !== undefined}
                                    onPublish={targetId ? editPost : createPost}
                                    previousPageAddress="/admin/targets"
                                    addPDF={(pdf) => {
                                        params.details.pdf ?
                                            params.details.pdf = [...params.details.pdf, pdf]
                                            :
                                            params.details.pdf = [pdf];
                                        setParams({...params})
                                    }}
                                    pdfs={params.details.pdf ? params.details.pdf : []}
                                    removePDF={(pdf) => {
                                        params.details.pdf = params.details.pdf.filter(x => x.fileManagerId !== pdf.fileManagerId);
                                        setParams({...params})
                                    }}
                                    targetType={params.targetType}
                                />
                                <FeatureImage
                                    defaultValue={params.avatarId ? params.avatarId : ""}
                                    onChoose={value => {
                                        params.avatarId = value
                                    }}
                                />
                                <SocialTable
                                    defaultValues={params.socialMedias ? params.socialMedias : []}
                                    onChange={(e) =>
                                        params.socialMedias = e
                                    }
                                    className="pb-3"
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <RelationsTable
                                    defaultValue={params.relations ? params.relations.map(data => ({
                                        target: {value: data.targetId, label: data.targetName},
                                        relation: {value: data.relationId, label: data.relationName},
                                        relationType: {value: data.relationTypeId, label: data.relationTypeName},
                                        targetRelation: {value: data.targetRelationId, label: data.targetRelationName},
                                        description: data.description,
                                    })) : []}
                                    onChange={value => {
                                        params.relations = value.map(data => {
                                            return {
                                                targetId: parseInt(data.target.value),
                                                targetName: data.target.label,
                                                relationId: parseInt(data.relation.value),
                                                relationName: data.relation.label,
                                                targetRelationId: parseInt(data.targetRelation.value),
                                                targetRelationName: data.targetRelation.label,
                                                description: data.description,
                                                relationTypeId: data.relationType.value,
                                                relationTypeName: data.relationType.label,
                                            }
                                        });
                                    }}
                                />
                                {
                                    params.targetType === 0 &&
                                    <>
                                        <DetailsTable
                                            defaultValue={params.details.identity}
                                            onChange={value => {
                                                params.details.identity = value.identity;
                                            }}
                                            targetId={targetId}
                                        />
                                        <JobInformationTable
                                            defaultValue={params.details.jobTitles}
                                            onChange={value => {
                                                params.details.jobTitles = value;
                                            }}
                                            targetId={targetId}
                                        />
                                    </>
                                }
                                {
                                    params.targetType === 1 &&
                                    <InformationTable
                                        defaultValue={params.details.legalTargetDetails ? params.details.legalTargetDetails : []}
                                        onChange={value => {
                                            params.details.legalTargetDetails = value;
                                        }}
                                        targetId={targetId}
                                    />
                                }
                                <LivingPlace
                                    defaultValue={params.details.livingPlace}
                                    onChange={value => {
                                        params.details.livingPlace = value.livingPlace;
                                    }}
                                    targetId={targetId}
                                    targetType={params.targetType}
                                />
                                <MultimediaUploaders
                                    onAddImage={value => {
                                        params.details.picturs ?
                                            params.details.picturs = [...params.details.picturs, value]
                                            :
                                            params.details.picturs = [value];
                                    }}
                                    onAddVideo={value => {
                                        params.details.videos ?
                                            params.details.videos = [...params.details.videos, value]
                                            :
                                            params.details.videos = [value];
                                    }}
                                    onAddSound={value => {
                                        params.details.audios ?
                                            params.details.audios = [...params.details.audios, value]
                                            :
                                            params.details.audios = [value];
                                    }}
                                    imageData={params.details.picturs}
                                    soundData={params.details.audios}
                                    videoData={params.details.videos}
                                    onDeleteImage={(index) =>
                                        params.details.picturs.splice(index, 1)
                                    }
                                    onDeleteSound={(index) => params.details.audios.splice(index, 1)}
                                    onDeleteVideo={(index) => params.details.videos.splice(index, 1)}
                                />
                                <MoreDetailsTable
                                    defaultValue={params.details.moreDetails ? JSON.parse(params.details.moreDetails) : []}
                                    onChange={value => {
                                        params.details.moreDetails = JSON.stringify(value)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Box>
                </Paper>
            </GridItem>
            <GridItem xs={12}>
                <div className="dx-viewport mt-4">
                    <div className="demo-container">
                        <GraphDiagram
                            ref={(ref) => {
                                diagramRef = ref
                            }}
                        />
                    </div>
                </div>
            </GridItem>
        </GridContainer>
    );
};

TargetsEditor.propTypes = {
    dispatch: PropTypes.func
};

const mapState = () => ({});
export default connect(mapState)(TargetsEditor);