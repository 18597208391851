import React, {useEffect, useMemo, useState} from 'react';
import Gallery from 'react-grid-gallery';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import axios from "axios";

import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';

import {makeStyles} from '@material-ui/core/styles';
import {apiToken, baseUrl} from "../../appConfig";

import {getUrl, sendErrorNotification, sendNotification} from "../../functions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {SetSiteLoading} from "../../redux/actions/actions";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import HandleArchivePictureDialog from "./HandleArchivePicture/HandleArchivePictureDialog";
import Slide from "@material-ui/core/Slide";
import moment from 'moment-jalali';
import CommentsGallery from "./CommentGallery/CommentGallery";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import './ImageGallery.css';

moment.loadPersian();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    DeleteForeverRoundedIcon: {
        color: "#ffffff"
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const captionStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    maxHeight: "240px",
    overflow: "hidden",
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "100%",
    color: "white",
    padding: "2px",
    fontSize: "90%",
    transition: "1s"
};

const customTagStyle = {
    wordWrap: "break-word",
    display: "inline-block",
    backgroundColor: "white",
    height: "auto",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: "1",
    padding: ".2em .6em .3em",
    borderRadius: ".25em",
    color: "black",
    verticalAlign: "baseline",
    margin: "2px",
    transition: "2s"
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ImageGallery = (props) => {
    const classes = useStyles();
    const [imageData, setImageData] = useState([]);
    const [searchImageData, setSearchImageData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setEditOpenDialog] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [commentsOpenDialog, setCommentsOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    const isSubscriber = props.UserDetails.role === 2;

    const setCustomTags = (i) => {
        return (
            i.tags.map((t, i) => {
                return (
                    <div
                        key={i}
                        style={customTagStyle}>
                        {t.title}
                    </div>
                );
            })
        );
    };

    useEffect(() => {
        getImages().then();
        document.getElementById('mainPanel').scrollTop = 0;
        // eslint-disable-next-line
    }, []);

    const getImages = (pageIndex = 1, returnValue = 45) =>
        axios.get(baseUrl + "/picturearchives" + (isSubscriber ? "/forSubscriber" : "") + "?pageIndex=" + pageIndex + "&returnValue=" + returnValue, {headers: {Authorization: apiToken}})
            .then(async res => {
                setImageData([...imageData, ...res.data.data.map(item => (
                    {
                        id: item.pictureArchiveId,
                        src: getUrl(item.fileManagerId),
                        thumbnail: getUrl(item.fileManagerId, 320),
                        thumbnailWidth: 320 * item.pictureRatio,
                        thumbnailHeight: 320,
                        caption: item.title,
                        tags: item.tags ? item.tags.map(it => {
                            return {value: it.itemId, title: it.name, tagType: it.tagType}
                        }) : [],
                    }
                ))]);
            });

    const addPicture = ({nameForTarget, chosenPicture, selectedTags}) => {
        props.dispatch(SetSiteLoading(true));
        axios.post(
            baseUrl + "/picturearchives",
            {
                title: nameForTarget,
                fileManagerId: chosenPicture,
                tags: selectedTags.map(item => {
                    return {itemId: item.value, tagType: item.type}
                })
            },
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    const {data} = res.data;
                    setImageData([
                        ...imageData,
                        {
                            id: data.pictureArchiveId,
                            src: getUrl(data.fileManagerId),
                            thumbnail: getUrl(data.fileManagerId, 320),
                            thumbnailWidth: 320 * data.pictureRatio,
                            thumbnailHeight: 320,
                            caption: data.title,
                            tags: data.tags ? data.tags.map(it => {
                                return {value: it.itemId, title: it.name, tagType: it.tagType}
                            }) : [],
                        }
                    ]);
                    sendNotification(enqueueSnackbar, "تصویر به گالری اضافه شد");
                    setOpenDialog(false);
                    props.dispatch(SetSiteLoading(false))
                }
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            )
    };

    const updatePicture = ({editItemId, nameForTarget, chosenPicture, selectedTags}) => {
        props.dispatch(SetSiteLoading(true));
        axios.post(
            baseUrl + "/picturearchives/edit/" + editItemId,
            {
                title: nameForTarget,
                tags: selectedTags.map(item => {
                    return {itemId: item.value, tagType: item.type}
                })
            },
            {headers: {Authorization: apiToken}}
        )
            .then(res => {
                if (res.status === 200) {
                    const {data} = res.data;
                    const image = imageData.filter(val => val.id === data.pictureArchiveId)[0];
                    if (image) {
                        const index = imageData.indexOf(image);
                        imageData[index] = {
                            id: data.pictureArchiveId,
                            src: getUrl(data.fileManagerId),
                            thumbnail: getUrl(data.fileManagerId, 320),
                            thumbnailWidth: 320 * data.pictureRatio,
                            thumbnailHeight: 320,
                            caption: data.title,
                            tags: data.tags ? data.tags.map(it => {
                                return {value: it.itemId, title: it.name, tagType: it.tagType}
                            }) : [],
                        };
                        setImageData([...imageData]);
                        setEditOpenDialog(false);
                    }
                    sendNotification(enqueueSnackbar, "تصویر با موفقیت ویرایش شد");
                    setOpenDialog(false);
                    props.dispatch(SetSiteLoading(false))
                }
            })
            .catch(err => {
                    props.dispatch(SetSiteLoading(false));
                    if (err.response && err.response.status === 400)
                        sendErrorNotification(enqueueSnackbar, err.response.data.message);
                    else
                        sendErrorNotification(enqueueSnackbar);
                    return false;
                }
            )
    };

    const images = useMemo(() => imageData.map((i) => {
        i.customOverlay = (
            <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={captionStyle}
            >
                <div className="text-center mx-2">{i.caption}</div>
                <div className="d-flex justify-content-center flex-wrap">
                    {
                        i.hasOwnProperty('tags') &&
                        setCustomTags(i)
                    }
                </div>
            </div>
        );
        return i;
    }), [imageData]);

    const currentItemId = images[currentImage] && images[currentImage].id;

    const removePictureArchive = async () => {
        if ((currentImage || currentImage === 0) && currentItemId) {
            await props.dispatch(SetSiteLoading(true));
            await axios.post(baseUrl + "/pictureArchives/remove/" + currentItemId, {}, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        sendNotification(enqueueSnackbar, "آرشیو عکس مورد نظر با موفقیت حذف شد");
                        setImageData(imageData.filter(x => x.id !== currentItemId));
                        props.dispatch(SetSiteLoading(false));
                        setOpenDeleteDialog(false);
                        setOpenGallery(false)
                    }
                })
                .catch(err => {
                        props.dispatch(SetSiteLoading(false));
                        if (err.response && err.response.status === 400)
                            sendErrorNotification(enqueueSnackbar, err.response.data.message);
                        else
                            sendErrorNotification(enqueueSnackbar);
                        return false;
                    }
                )
        }
    };

    const searchPictureArchive = async keyword => {
        if (keyword.length > 0) {
            await axios.get(baseUrl + "/pictureArchives/search" + (isSubscriber ? "/forSubscriber" : "") + "?keyword=" + keyword, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        setSearchImageData(res.data.data.map(item => (
                            {
                                id: item.pictureArchiveId,
                                src: getUrl(item.fileManagerId),
                                thumbnail: getUrl(item.fileManagerId, 320),
                                thumbnailWidth: 320 * item.pictureRatio,
                                thumbnailHeight: 320,
                                caption: item.title,
                                tags: item.tags ? item.tags.map(it => {
                                    return {value: it.itemId, title: it.name, tagType: it.tagType}
                                }) : [],
                            }
                        )))
                    }
                })
        }
    };

    const searchedImages = useMemo(() => searchImageData && searchImageData.map((i) => {
        i.customOverlay = (
            <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={captionStyle}
            >
                <div className="text-center mx-2">{i.caption}</div>
                <div className="d-flex justify-content-center flex-wrap">
                    {
                        i.hasOwnProperty('tags') &&
                        setCustomTags(i)
                    }
                </div>
            </div>
        );
        return i;
            // eslint-disable-next-line
    }), [searchImageData]);

    const currentSearchedImageId = useMemo(()=>
        searchImageData &&
        searchImageData[currentImage] &&
        searchImageData[currentImage].id
        // eslint-disable-next-line
        , [currentImage, searchImageData]);

    const currentImageId = useMemo(()=>
        imageData[currentImage] &&
        imageData[currentImage].id
        // eslint-disable-next-line
        , [currentImage]);

    return (
        <div className="image-gallery-page">
            <div className="d-flex mb-2">
                <Paper
                    onSubmit={res => res.preventDefault()}
                    component="form"
                    className={classes.root}
                >
                    <IconButton
                        disabled
                        className={classes.iconButton}
                        aria-label="menu"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <InputBase
                        id="search-input"
                        className={classes.input}
                        placeholder="جستجوی عکس"
                        inputProps={{'aria-label': 'search google maps'}}
                        onChange={data => searchPictureArchive(data.target.value)}
                        onClick={() => setSearchImageData([])}
                    />
                    {
                        searchImageData && searchImageData.length >= 0 ?
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="search"
                                onClick={() => {
                                    setSearchImageData(null);
                                    document.getElementById("search-input").value = "";
                                }}
                            >
                                <HighlightOffRoundedIcon/>
                            </IconButton>
                            :
                            <IconButton
                                className={classes.iconButton}
                                aria-label="search"
                                onClick={() => {
                                    setSearchImageData([]);
                                    document.getElementById("search-input").focus();
                                }}
                            >
                                <SearchIcon/>
                            </IconButton>
                    }
                    {
                        !isSubscriber && (
                            <>
                                <Divider
                                    className={classes.divider}
                                    orientation="vertical"
                                />
                                <IconButton
                                    color="primary"
                                    className={classes.iconButton}
                                    aria-label="directions"
                                    onClick={() => setOpenDialog(true)}
                                >
                                    <AddAPhotoRoundedIcon/>
                                </IconButton>
                            </>
                        )
                    }
                </Paper>
            </div>
            {
                searchedImages ?
                    searchedImages.length > 0 ?
                        <Gallery
                            images={searchedImages}
                            enableImageSelection={false}
                            tagStyle={{display: "none"}}
                            customControls={
                                [
                                    <div className="d-flex bg-dark w-100">
                                        <IconButton
                                            key="deleteImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        >
                                            <DeleteForeverRoundedIcon/>
                                        </IconButton>,
                                        <IconButton
                                            key="comments"
                                            className={classes.DeleteForeverRoundedIcon + " comments-button"}
                                            onClick={() => setCommentsOpenDialog(true)}
                                        >
                                            <CommentRoundedIcon/>
                                        </IconButton>,
                                        <IconButton
                                            key="editImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() =>{
                                                setEditOpenDialog(true)
                                                setOpenDialog(false)
                                            }}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </div>
                                    ,
                                    <CommentsGallery
                                        dispatch={props.dispatch}
                                        className="comments-image-gallery"
                                        currentItemId={currentSearchedImageId}
                                        UserDetails={props.UserDetails}
                                    />
                                ]
                            }
                            currentImageWillChange={id => setCurrentImage(id)}
                        />
                        :
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <ImageSearchRoundedIcon style={{fontSize: "10vw"}}/>
                        </div>
                    :
                    <Gallery
                        id="image-gallery"
                        isOpen={openGallery}
                        images={images}
                        onClose={() => setOpenGallery(false)}
                        enableImageSelection={false}
                        tagStyle={{display: "none"}}
                        backdropClosesModal
                        showImageCount={false}
                        showLightboxThumbnails={false}
                        customControls={
                            [
                                <div className="d-flex bg-dark w-100">
                                    {
                                        !isSubscriber &&
                                        <IconButton
                                            key="deleteImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        >
                                            <DeleteForeverRoundedIcon/>
                                        </IconButton>
                                    }
                                    <IconButton
                                        key="comments"
                                        className={classes.DeleteForeverRoundedIcon + " comments-button"}
                                        onClick={() => setCommentsOpenDialog(true)}
                                    >
                                        <CommentRoundedIcon/>
                                    </IconButton>,
                                    {
                                        !isSubscriber &&
                                        <IconButton
                                            key="editImage"
                                            className={classes.DeleteForeverRoundedIcon}
                                            onClick={() => setEditOpenDialog(true)}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    }
                                </div>
                                ,
                                <CommentsGallery
                                    dispatch={props.dispatch}
                                    className="comments-image-gallery"
                                    currentItemId={currentImageId}
                                    UserDetails={props.UserDetails}
                                />
                            ]
                        }
                        currentImageWillChange={id => setCurrentImage(id)}
                    />
            }
            <HandleArchivePictureDialog
                style={{zIndex: 2002}}
                open={openDialog || openEditDialog}
                onClose={() => {
                    if (openDialog)
                        setOpenDialog(false);
                    else if (openEditDialog)
                        setEditOpenDialog(false);
                }}
                onSubmit={data => {
                    if (openDialog)
                        addPicture(data);
                    else if (openEditDialog)
                        updatePicture(data)
                }}
                isEditMode={openEditDialog}
                editItemId={currentImageId}
                currentImage={imageData.filter(x => x.id === currentImageId)[0]}
            />
            <Dialog
                keepMounted
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                style={{zIndex: 2002}}
            >
                <DialogTitle id="alert-dialog-slide-title">حذف تصویر</DialogTitle>
                <DialogContent>
                    آیا از حذف این تصویر مطمئن هستید؟
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDeleteDialog(false)}
                        color="primary"
                        variant="outlined"
                        className="mx-2"
                    >
                        انصراف
                    </Button>
                    <Button
                        onClick={removePictureArchive}
                        color="secondary"
                        variant="outlined"
                    >
                        بله
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                keepMounted
                open={commentsOpenDialog}
                onClose={() => setCommentsOpenDialog(false)}
                style={{zIndex: 2002, maxHeight: '100vh'}}
                fullScreen
                TransitionComponent={Transition}
            >
                <IconButton
                    onClick={() => setCommentsOpenDialog(false)}
                    color="primary"
                    variant="outlined"
                    className="mx-2 position-fixed"
                    style={{left: '1rem', top: '1rem', zIndex: 99999}}
                >
                    <CloseIcon/>
                </IconButton>
                <CommentsGallery
                    dispatch={props.dispatch}
                    currentItemId={currentItemId}
                    UserDetails={props.UserDetails}
                />
            </Dialog>
        </div>
    )
};

const mapState = states => ({
    UserDetails: states.userDetails
});
export default connect(mapState)(ImageGallery);