import React, {useEffect, useState} from 'react';
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Table from "../../../components/Table/Table";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/rtlStyle";
import {apiToken} from "../../../appConfig";
import {baseUrl} from "../../../appConfig";
import Axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import {SetUserDialog} from '../../../redux/actions/actions'
import {connect} from "react-redux";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(styles + {
    appBar: {
        position: 'relative',
    },
});

const ListOfUsers = ({data,...props}) => {
    const returnValue = 5;
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [tableData, setTableData] = useState({});


    useEffect(() => {
        if (data) {
            Axios.get(baseUrl + "/Users/Count", {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        setTableData({
                            values: data.map(value => ({
                                id: value.userId,
                                data: [
                                    value.userId,
                                    value.userName,
                                    (value.firstName + " " + value.lastName),
                                    value.role === 0 ? "مدیر" : value.role === 1 ? "نویسنده" : "مشترک",
                                    "فعال"
                                ]
                            })),
                            countAll: res.data.data
                        });
                    }
                });
        }
        // eslint-disable-next-line
    }, [data]);

    const getUsers = (index = 1) => {
        Axios.get(baseUrl + "/Users/All?pageIndex=" + index + "&returnValue=" + returnValue, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    setTableData({
                        values: res.data.data.map(value => ({
                            id: value.userId,
                            data: [
                                value.userId,
                                value.userName,
                                (value.firstName + " " + value.lastName),
                                value.role === 0 ? "مدیر" : value.role === 1 ? "نویسنده" : "مشترک",
                                "فعال"
                            ]
                        })),
                        countAll: res.data.countAll
                    })
                }
            })
    };

    const removeUser = async ID => {
        return await Axios.post(baseUrl + "/users/remove/" + ID, {}, {headers: {Authorization: apiToken}})
            .then(res => {
                if (res.status === 200) {
                    getUsers();
                    sendNotification(enqueueSnackbar, "کاربر با موفقیت حذف شد");
                    return true;
                }
                return false;
            })
            .catch(res => {
                if (res.response && res.response.status === 400)
                    sendErrorNotification(enqueueSnackbar, res.response.data.message);
                else
                    sendErrorNotification(enqueueSnackbar);
                return false;
            })
    };

    const afterAdd = data => {
        setTableData({
            values : [{
                id: data.userId,
                data: [
                    data.userId,
                    data.userName,
                    (data.firstName + " " + data.lastName),
                    data.role === 0 ? "مدیر" : data.role === 1 ? "نویسنده" : "مشترک",
                    "فعال"
                ]
            }, ...tableData.values],
            countAll : tableData.countAll + 1
        });
        props.dispatch(SetUserDialog(null));
    };

    return (
        <GridItem xs={12} sm={12} md={6}>
            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center" color="warning">
                    <h4 style={{margin:0}} >آمار کاربران</h4>
                    <p style={{margin:0}} className={classes.cardCategoryWhite}>
                        به ترتیب کاربران جدید
                    </p>

                    <Tooltip title="افزودن">
                        <IconButton
                            color="inherit"
                            onClick={() => props.dispatch(SetUserDialog(afterAdd))}
                        >
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
                </CardHeader>
                <CardBody>
                    <Table
                        tableHeaderColor="warning"
                        tableHead={["کد", "نام کاربری", "نام و نام خانوادگی", "نقش", "وضعیت"]}
                        tableData={tableData.values || []}
                        limitViews={returnValue}
                        onPaging={(event, value) => getUsers(value)}
                        totalAmount={tableData.countAll || 0}
                        actions={{
                            onDelete : (key, id) => removeUser(id)
                        }}
                    />
                </CardBody>
            </Card>
        </GridItem>
    );
};

const mapState = states => ({
    UserDialogHandler : states.userDialogHandler
});

export default connect(mapState)(ListOfUsers);