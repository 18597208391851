import React from 'react';
//css
import './AddTextField.css'
import 'leaflet/dist/leaflet.css'
//
import {connect} from "react-redux";
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide, RadioGroup, FormControlLabel, Radio
} from "@material-ui/core";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddTextField = ({open, onClose, onSubmit}) => {
    const [size, setSize] = React.useState("1");
    const [rtlNameForSetDetails, setRtlNameForSetDetails] = React.useState("");
    const [typeForSetDetails, setTypeForSetDetails] = React.useState("text");

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"فیلد مورد نظر خود را بسازید"}</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="عنوان"
                    type="text"
                    className="my-2 w-100"
                    value={rtlNameForSetDetails}
                    onChange={(e) =>
                        setRtlNameForSetDetails(e.target.value)
                    }
                />
                <div className="d-flex justify-content-between align-items-end">
                    <span className="mb-2">
                        طول:
                    </span>
                    <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="1"
                        onChange={(e) => setSize(e.target.value)}
                    >
                        <FormControlLabel
                            value="1"
                            control={<Radio color="primary"/>}
                            label="1"
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio color="primary"/>}
                            label="2"
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="3"
                            control={<Radio color="primary"/>}
                            label="3"
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio color="primary"/>}
                            label="4"
                            labelPlacement="top"
                        />
                    </RadioGroup>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    لغو
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                        onSubmit(
                            {
                                rtlName: rtlNameForSetDetails,
                                type: typeForSetDetails,
                                size: parseInt(size),
                                value: ""
                            }
                        );
                        setRtlNameForSetDetails("");
                        setTypeForSetDetails("");
                    }}
                    color="primary"
                >
                    تایید
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const mapState = (states) => ({
    CreateLocationHandler: states.CreateLocationHandler,
});

export default connect(mapState)(AddTextField);