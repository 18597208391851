import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import AddIcon from '@material-ui/icons/Add'
import CardIcon from "../../../components/Card/CardIcon";
import {SetSiteLoading} from "../../../redux/actions/actions";
import {baseUrl} from "../../../appConfig";
import {apiToken} from "../../../appConfig";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import Axios from "axios";
import Inputs from "./Inputs";

let inputsRef;
const RelationEditor = ({onSubmit, reference, setRelationTypeData, ...props}) => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {relationId} = useParams();

    useImperativeHandle(reference, () => ({
        inputsRef
        // eslint-disable-next-line
    }), [inputsRef]);

    useEffect(() => {
        if (relationId && relationId !== "" && inputsRef) {
            Axios.get(baseUrl + "/relations/" + relationId, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        inputsRef.setName(res.data.data.name);
                        inputsRef.setRelationType(res.data.data.typeId);
                    } else if (res.status === 204) {
                        sendErrorNotification(enqueueSnackbar, "ارتباط مورد نظر وجود ندارد");
                        navigate("/admin/relations")
                    }
                })
                .catch(() => {
                    sendErrorNotification(enqueueSnackbar)
                })
        }
        // eslint-disable-next-line
    }, [relationId, inputsRef]);

    const handleSubmit = async (form) => {
        form.preventDefault();
        form.persist();
        await props.dispatch(SetSiteLoading(true));
        const inputs = form.target.querySelectorAll('input, select');
        let values = {};
        for (let i = 0; i < inputs.length; i++) {
            values[inputs[i].name] = {};
            values[inputs[i].name].importance = inputs[i].getAttribute('custom-importance') === "true";
            values[inputs[i].name].value = inputs[i].value;
        }
        if (Object.values(values).filter(val => val.value === "" && val.importance).length > 0) {
            inputsRef.setError(Object.keys(values).filter(val => values[val].value === "" && values[val].importance))
        } else {
            await Axios.post(`${baseUrl}/relations${relationId ? ("/edit/" + relationId) : ""}`,
                {
                    name: values.name.value,
                    typeId: parseInt(values.relationType.value)
                },
                {
                    headers: {
                        Authorization: apiToken,
                        "Content-Type": "application/json"
                    }
                })
                .then(async res => {
                    if (res.status === 200) {
                        if (onSubmit) {
                            onSubmit(res.data.data, relationId === undefined);
                        }
                        if (relationId !== undefined) {
                            Object.values(inputs).forEach(input => {
                                input.value = "";
                            });
                            sendNotification(enqueueSnackbar, "ارتباط شما با موفقیت ویرایش شد");
                            navigate("/admin/relations");
                        } else {
                            sendNotification(enqueueSnackbar, "ارتباط شما با موفقیت اضافه شد")
                        }
                    }
                })
                .catch(res => {
                    if (res.response && res.response.status === 400) {
                        sendErrorNotification(enqueueSnackbar, res.response.data.message);
                    } else {
                        sendErrorNotification(enqueueSnackbar);
                    }
                })
        }
        await props.dispatch(SetSiteLoading(false));
    };

    return (
        <Card>
            <CardHeader icon>
                <CardIcon color="success">
                    <AddIcon className="px-1"/>
                </CardIcon>
                <h4 style={{color: "black"}}>{`${relationId ? "ویرایش" : "افزودن"}`} ارتباط</h4>
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <Inputs
                        ref={ref => {
                            inputsRef = ref
                        }}
                        setRelationTypeData={setRelationTypeData}
                    />
                    {
                        relationId &&
                        (
                            <Button
                                color="secondary"
                                variant="outlined"
                                className="mt-3"
                                onClick={() => navigate("/admin/relations")}
                            >
                                انصراف
                            </Button>
                        )
                    }
                    <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        className="mt-3 mx-2"
                    >
                        {relationId ? "ویرایش" : "افزودن"}
                    </Button>
                </form>
            </CardBody>
        </Card>
    );
};

const Component = connect()(RelationEditor);

export default forwardRef((props, ref) => (
    <Component reference={ref} {...props} />
));