import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import {connect} from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import {apiToken, baseUrl} from "../../../../appConfig";
import {sendErrorNotification, sendNotification} from "../../../../functions";
import {useSnackbar} from "notistack";

const LocationTypeHandleDialog = ({onSubmit, onClose, ...props}) => {
    const {enqueueSnackbar} = useSnackbar();

    const [createOrEditDialog, setCreateOrEditDialog] = useState(null);
    const [data, setData] = useState({
        title: ""
    });

    useEffect(()=> {
        if(typeof (createOrEditDialog && createOrEditDialog.id) === "number" && createOrEditDialog.data){
            setData(createOrEditDialog.data)
        }
        else if(data.title !== ""){
            clearData()
        }
        // eslint-disable-next-line
    }, [createOrEditDialog]);

    useImperativeHandle(props.reference, () => ({
        setCreateOrEditDialog
    }), []);

    const closeDialog = useCallback(() => {
        setCreateOrEditDialog(null);
        if (onClose)
            onClose();
    }, [onClose]);

    const clearData = useCallback(() => setData({
    }), []);

    const inputHandler = useCallback(input => {
        const {name, value} = input.target;
        data[name] = value;
        setData({...data});
    }, [data]);

    const submit = ()=> {
        Axios.post(
            baseUrl + "/locationTypes" + (typeof (createOrEditDialog && createOrEditDialog.id) === "number" ? ("/edit/" + createOrEditDialog.id) : ""),
            data,
            {headers : {Authorization : apiToken}}
        )
            .then(res => {
                if(res.status === 200){
                    sendNotification(enqueueSnackbar, "نوع مکان شما با موفقیت " + (typeof (createOrEditDialog && createOrEditDialog.id) === "number" ? "ویرایش" : "افزوده") + " شد");
                    clearData();
                    setCreateOrEditDialog(null);
                    if(onSubmit)
                        onSubmit(res.data.data, typeof (createOrEditDialog && createOrEditDialog.id) === "number");
                }
            })
            .catch(res => {
                if(res.response && res.response.status === 400){
                    sendErrorNotification(enqueueSnackbar, res.response.data.message)
                }
            })
    };

    return (
        <Dialog
            open={createOrEditDialog !== null}
            onClose={closeDialog}
            PaperProps={{style: {minWidth: 320}}}
        >
            <DialogTitle>افزودن نوع مکان</DialogTitle>
            <DialogContent>
                <TextField
                    name="title"
                    label="عنوان"
                    value={data.title}
                    onChange={inputHandler}
                    variant="outlined"
                    className="w-100"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                    color="secondary"
                >
                    انصراف
                </Button>
                <Button
                    onClick={submit}
                    color="primary"
                >
                    ثبت
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Component = connect()(LocationTypeHandleDialog);
export default forwardRef((props, ref) => (
    <Component reference={ref} {...props}/>
));