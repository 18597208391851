import React from 'react';
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardAvatar from "../../../components/Card/CardAvatar";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CardBody from "../../../components/Card/CardBody";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/rtlStyle";
import {connect} from "react-redux";

const useStyles = makeStyles(styles);
const UserProfile = ({data, UserDetails, ...props}) => {
    const classes = useStyles();
    return (
        <GridItem {...props}>
            <Card profile>
                <CardAvatar profile>
                    <AccountCircleIcon className="w-100 h-100"/>
                </CardAvatar>
                <CardBody profile>
                    <h6 className={classes.cardCategory}>{UserDetails.role === 0 ? "مدیر" : UserDetails.role === 1 ? "نویسنده" : "مشترک"}</h6>
                    <h4 className={classes.cardTitle}>{UserDetails.firstName + " " + UserDetails.lastName}</h4>
                    <p className={classes.description}>
                        طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن
                        صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده
                        می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و
                        ظاهر متن باشد. معمولا طراحان گرافیک برای صفحه‌آرایی، نخست از
                        متن‌های آزمایشی و بی‌معنی استفاده می‌کنند ...
                    </p>
                </CardBody>
            </Card>
        </GridItem>
    );
};

const mapState = states => ({
    UserDetails: states.userDetails
});
export default connect(mapState)(UserProfile);