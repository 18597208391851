import React, {useCallback, useEffect, useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {getUrl} from "../../../functions";
import ListItemText from "@material-ui/core/ListItemText";
import {convertDateZoneToCurrentZone} from "../../../timeFunctions";
import Divider from "@material-ui/core/Divider";
import RepliedComment from "../RepliedComments/RepliedComments";
import SendComment from "../SendComment/SendComment";
import {apiToken, baseUrl} from "../../../appConfig";
import axios from "axios";

const CommentsGallery = ({dispatch, commentPlatform = 2, UserDetails, currentItemId, ...props}) => {
    const [replayData, setReplayData] = useState(null);
    const [commentData, setCommentData] = useState([]);

    useEffect(() => {
        if (currentItemId)
            getCommentsForCurrentImage(currentItemId)
        // eslint-disable-next-line
    }, [currentItemId]);

    const replayComment = (item) => {
        setReplayData(item);
    };

    const getCommentsForCurrentImage = () => {
        axios.get(
            baseUrl + `/comments/${currentItemId}?commentPlatform=${commentPlatform}`,
            {headers: {Authorization: apiToken}}
        )
            .then((res) => {
                setCommentData([]);
                setCommentData([...res.data.data])
            })
    };

    const deleteComment = (item) => {
        axios.post(
            baseUrl + `/comments/remove/${item.commentId}`,
            {},
            {headers: {Authorization: apiToken}}
        )
            .then(() => {
                getCommentsForCurrentImage();
            })
    };

    const processDataToFind = (data, target, newData) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].repliedComment) {
                const find = data[i].repliedComment.filter(val => val === target)[0];
                if (find) {
                    const index = data[i].repliedComment.indexOf(find);
                    if (data[i].repliedComment[index].repliedComment)
                        data[i].repliedComment[index].repliedComment.push(newData);
                    else
                        data[i].repliedComment[index].repliedComment = [newData];
                    return data;
                }
                const newLoop = processDataToFind(data[i].repliedComment);
                if (newLoop)
                    return newLoop;
            } else {
                data[i].repliedComment = [newData];
                return data;
            }
        }
    };

    const onSendComment = data => {
        if (replayData) {
            const find = commentData.filter(val => val === replayData)[0];
            if (find) {
                const index = commentData.indexOf(find);
                if (commentData[index].repliedComment)
                    commentData[index].repliedComment.push(data);
                else
                    commentData[index].repliedComment = [data];
                setCommentData([...commentData]);
            } else {
                const newData = processDataToFind(commentData, replayData, data);
                setCommentData([...newData]);
            }
        } else {
            setCommentData([...commentData, data])
        }
    };

    const haveQuality = useCallback(userId =>
        UserDetails.userId === userId || UserDetails.role === 0, [UserDetails]);


    const handleHover = leave => element => {
        const {currentTarget} = element;
        const query = currentTarget.querySelector(".controls-btn");
        if (!leave)
            query.classList.add("d-flex");
        else
            query.classList.remove("d-flex");
    };

    return (
        <div
            {...props}
        >
            <div
                style={{
                    overflow: "auto",
                    height: "100%",
                    padding: "0 .5rem"
                }}
            >
                <List>
                    {
                        commentData.map((comment, i) =>
                            <React.Fragment key={i}>
                                <ListItem
                                    className="d-flex flex-column align-items-start position-relative comment-img-gallery"
                                    onMouseEnter={handleHover(false)}
                                    onMouseLeave={handleHover(true)}
                                >
                                    <div
                                        className="controls-btn position-absolute"
                                    >
                                        {
                                            haveQuality(comment.from) && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => deleteComment(comment)}
                                                >
                                                    <DeleteForeverRoundedIcon/>
                                                </IconButton>
                                            )
                                        }
                                        <IconButton
                                            size="small"
                                            onClick={() => replayComment(comment)}
                                        >
                                            <ReplyRoundedIcon/>
                                        </IconButton>
                                    </div>
                                    <div className="d-flex ">
                                        <ListItemAvatar>
                                            <Avatar
                                                alt=""
                                                src={comment.avatarId && getUrl(comment.avatarId)}
                                            >
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={comment.userFullName}
                                            secondary={convertDateZoneToCurrentZone(comment.createdDate, "Asia/Tehran").fromNow()}
                                        />
                                    </div>
                                    <ListItemText
                                        primary={comment.content}
                                    />
                                    {
                                        comment.repliedComment && comment.repliedComment.length > 0 &&
                                        <RepliedComment
                                            haveQuality={haveQuality}
                                            onReplay={replayComment}
                                            data={comment.repliedComment}
                                            onDelete={deleteComment}
                                        />
                                    }
                                </ListItem>
                                <Divider component="li"/>
                            </React.Fragment>
                        )
                    }
                </List>
            </div>
            <SendComment
                removeReplay={() => setReplayData(null)}
                replayData={replayData}
                currentItemId={currentItemId}
                onDone={onSendComment}
                dispatch={dispatch}
                commentPlatform={commentPlatform}
            />
        </div>
    )
};

export default CommentsGallery;