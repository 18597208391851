import React, {useEffect} from "react";
import {Routes, Route, Outlet} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

import {connect} from "react-redux";
import Axios from "axios";

// core components
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";

import routes from "../routes";


import MediaDrawer from "../components/MediaDrawer/MediaDrawer";
import UserInformationDrawer from "../components/UserInformationDrawer/UserInformationDrawer";
import AddUserDialog from "../components/AddUserDialog/AddUserDialog";
import {SetMediaDrawer, SetUserInformationDrawer} from "../redux/actions/actions";
import CreateLocation from "../components/Editor/CreateLocation/CreateLocation";
import {apiToken, baseUrl} from "../appConfig";
import bgImage from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/logo.png";

import styles from "../assets/jss/material-dashboard-react/layouts/rtlStyle";

const useStyles = makeStyles(styles);

function Admin({MediaDrawerHandler, UserInformationDrawerRedux, ...rest}) {
    const classes = useStyles();
    const mainPanel = React.createRef();
    const [image, setImage] = React.useState(bgImage);
    const [dataType, setDataType] = React.useState([]);
    const [color, setColor] = React.useState("red");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown");
    const [mobileOpen, setMobileOpen] = React.useState(false);

    useEffect(() => {
        Axios.get(
            baseUrl + "/locationtypes?returnValue=50",
            {headers: {Authorization: apiToken}}
        )
            .then(res =>
                setDataType(res.data.data)
            )
        // eslint-disable-next-line
    }, []);

    const handleImageClick = image => {
        setImage(image);
    };
    const handleColorClick = color => {
        setColor(color);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const getRoute = () => {
        return window.location.pathname !== "/dashboard/maps";
    };

    return (
        <div
            className={classes.wrapper}
        >
            <Sidebar
                routes={routes}
                logoText={"آرشیو اوسینت"}
                logo={logo}
                image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                rtlActive
                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel} id="mainPanel">
                <Navbar
                    routes={routes}
                    handleDrawerToggle={handleDrawerToggle}
                    rtlActive
                    {...rest}
                />
                {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <Outlet/>
                        </div>
                    </div>
                ) : (
                    <div className={classes.map}>
                        <Outlet/>
                    </div>
                )}
                {getRoute() ? <Footer/> : null}
                <FixedPlugin
                    rtlActive
                    handleImageClick={handleImageClick}
                    handleColorClick={handleColorClick}
                    bgColor={color}
                    bgImage={image}
                    handleFixedClick={handleFixedClick}
                    fixedClasses={fixedClasses}
                />
            </div>
            <MediaDrawer
                open={MediaDrawerHandler !== null}
                onClose={() => rest.dispatch(SetMediaDrawer(null))}
                type={MediaDrawerHandler && MediaDrawerHandler.type}
                value={MediaDrawerHandler && MediaDrawerHandler.value}
                onChange={MediaDrawerHandler && MediaDrawerHandler.action}
                tagHandler={MediaDrawerHandler && MediaDrawerHandler.tagHandler}
            />
            <UserInformationDrawer
                open={UserInformationDrawerRedux !== null}
                onClose={() => rest.dispatch(SetUserInformationDrawer(null))}
                type={UserInformationDrawerRedux && UserInformationDrawerRedux.type}
                value={UserInformationDrawerRedux && UserInformationDrawerRedux.id}
                onChange={UserInformationDrawerRedux && UserInformationDrawerRedux.action}
            />
            <AddUserDialog/>
            {
                apiToken &&
                <CreateLocation dataType={dataType}/>
            }
        </div>
    );
}

const mapState = states => ({
    MediaDrawerHandler: states.mediaDrawerHandler,
    UserInformationDrawerRedux: states.userInformationDrawer
});
export default connect(mapState)(Admin)
