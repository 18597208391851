import React, {useState, useEffect} from 'react';
import FlowPointChart from "../../components/Charts/FlowPoints/FlowPointChart";
import Button from "@material-ui/core/Button";
import XLSX from 'xlsx';
import sample from '../../assets/sample/smaple.xlsx';

import './RelationPeople.css'

let chartRef;

function RelationPeople() {
    const [loading, setLoading] = useState(false);
    const [valueNumber, setValueNumber] = useState(4);

    useEffect(() => {
        document.getElementById('mainPanel').scrollTop = 0;
    }, []);

    const openSampleFile = () => {
        const element = document.createElement('a');
        element.setAttribute('href', sample);
        element.setAttribute('download', 'sample.xlsx');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const ExcelToJSON = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                const data = e.target.result;
                const workbook = XLSX.read(data, {
                    type: 'binary'
                });

                workbook.SheetNames.forEach((sheetName) => {
                    let values = [];
                    const XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    XL_row_object.forEach(data => {
                        Object.keys(data).forEach(key => {
                            if (values[key])
                                values[key].push(data[key]);
                            else
                                values[key] = [data[key]];
                        });
                    });
                    resolve(values);
                })

            };

            reader.onerror = function (ex) {
                reject(ex);
            };

            reader.readAsBinaryString(file);
        });
    };

    return (
        <div style={{position: "relative", height: "80vw"}}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="mb-3 pl-4">
                    <h2 style={{fontSize: "1rem", margin: "0"}}>
                        ابتدا تعداد رابطه ها را انتخاب و سپس فایل exel مورد نظر را آپلود کنید تا گراف ارتباطات نمایش
                        داده شود.
                    </h2>
                    <h3 style={{fontSize: "1rem", margin: "0"}}>
                        توجه داشته باشید که ساختار فایل آپلود شده باید مانند فایل رو به رو باشد
                    </h3>
                </div>
                <Button
                    color="primary"
                    variant="outlined"
                    style={{width: "10rem"}}
                    onClick={openSampleFile}
                >
                    فایل نمونه
                </Button>
            </div>
            <div style={
                {
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: loading ? "flex" : "none",
                    background: "rgba(0, 0, 0, 0.5)",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    zIndex: 2
                }
            }>
                صبر کنید ...
            </div>
            <div
                className=" d-flex"
                style={{position: "absolute", zIndex: 1}}
            >
                <input
                    type="file"
                    onChange={input => {
                        setLoading(true);
                        ExcelToJSON(input.target.files[0])
                            .then(res => {
                                chartRef.setData(res);
                                setLoading(false);
                            })
                    }}
                />
                <div
                    className="d-flex mx-3"
                >
                    <span style={{fontSize: ".9rem"}} className="mx-1">تعداد ارتباطات:</span>
                    <input
                        type="number"
                        defaultValue={4}
                        onChange={val => {
                            if (parseInt(val.target.value) > 1) {
                                setLoading(true);
                                setValueNumber(parseInt(val.target.value));
                                chartRef.setSubscriptionPointAmount(parseInt(val.target.value));
                                setLoading(false);
                            } else {
                                setLoading(true);
                                setValueNumber(1);
                                chartRef.setSubscriptionPointAmount(parseInt("1"));
                                setLoading(false);
                            }
                        }}
                        value={valueNumber}
                    />
                </div>
            </div>
            <FlowPointChart ref={ref => {
                chartRef = ref
            }}/>
        </div>
    );
}

export default RelationPeople;
