import React, {useCallback, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {useDropzone} from "react-dropzone";
import ProgressBar from "./ProgressBar/ProgressBar";


const Uploader = ({onDoneUpload, type = "All"})=> {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({accept: type === "All" ? "" : 'image/*'});

    const onDoneUploadOverRide = async data => {
        await onDoneUpload(data);
        await forceUpdate();
    };

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            <Typography variant="caption">{file.name}</Typography>
            <ProgressBar onDone={onDoneUploadOverRide} file={file} />
        </li>
    ));

    return (
        <div>
            <section className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>برای آپلود، فایل را به اینجا بکشید و یا بر روی این قست کلیک کنید</p>
                </div>
                {
                    acceptedFiles.length > 0 &&
                    (
                        <aside>
                            <h4>فایل ها</h4>
                            <ul>{files}</ul>
                        </aside>
                    )
                }
            </section>
        </div>
    );
};

export default Uploader;
