import React from 'react';
import './JobItemStudy.css'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const JobListItem = ({name, value}) => {
    return (
        <div className=" JobListItem d-flex justify-content-between align-items-center mb-3 w-100 ">
            <Typography className="my-2" variant="h6">{name} :  </Typography>
            <Chip
                style={{
                    background: "linear-gradient(60deg, #26c6da, #00acc1)",
                    fontSize: "1.2rem",
                }}
                color="secondary"
                label={value}
                className="mr-3 chip-default-study py-1"
            />
        </div>
    );
};

const JobItemStudy = ({title, place, activityTitle, activityPeriod, moreDetails}) => {
    return (
        <div className="JobItemStudy p-3 d-flex flex-column align-items-center justify-content-between mx-3 ">
            <Typography
                className="mb-3 w-100 pb-3"
                variant="h4"
                style={{borderBottom: '1px solid #fff'}}
            >
                {title}
            </Typography>
            <div className=" d-flex flex-column justify-content-start h-100 w-100">
                {
                    place &&
                    <JobListItem
                        name="محل"
                        value={place}
                    />
                }
                {
                    activityTitle &&
                    <JobListItem
                        name="سمت"
                        value={activityTitle}
                    />
                }
                {
                    activityPeriod &&
                    <JobListItem
                        name="مدت فعالیت"
                        value={activityPeriod}
                    />
                }
                {
                    moreDetails &&
                    moreDetails.map((d, i)=>
                        <JobListItem
                            key={i}
                            name={d.rtlName}
                            value={d.value}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default JobItemStudy;