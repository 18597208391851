import React, {useState, useEffect} from 'react';
import FolderItem from "./FolderItem/FolderItem";
import './FoldersMedia.css'
import {SetSiteLoading} from "../../redux/actions/actions";
import {apiToken, baseUrl} from "../../appConfig";
import {getThumb, getUrl, sendErrorNotification, sendNotification} from "../../functions";
import {useSnackbar} from "notistack";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {Tooltip} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FoldersMedia = ({moreButtonProp, ...props}) => {
        const [imageData, setImageData] = useState([]);
        const {enqueueSnackbar} = useSnackbar();
        const [openModalAddFolder, setOpenModalAddFolder] = useState(false);
        const [folderName, setFolderName] = useState('');
        const [deletedItems, setDeletedItems] = useState([]);
        const [folderData, setFolderData] = useState([]);
        const [selectedFolderId, setSelectedFolderId] = useState(null);
        const [selectedFolderData, setSelectedFolderData] = useState([]);
        const [moreButton, setMoreButton] = useState(true);
        const [pageIndex, setPageIndex] = useState(1);

        const getFolders = () => {
            props.dispatch(SetSiteLoading(true));
            axios.get(
                baseUrl + "/folders?folderPlatform=0",
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    setFolderData(res.data.data);
                    props.dispatch(SetSiteLoading(false));
                })
                .catch(() =>
                    props.dispatch(SetSiteLoading(false))
                )
        };

        useEffect(() => {
            document.getElementById('mainPanel').scrollTop = 0;
            // eslint-disable-next-line
        }, []);

        const getFilesWithoutFolder = (pageIndex, returnValue = 45) => {
            props.dispatch(SetSiteLoading(true));
            axios.get(baseUrl + "/fileManagers" + "?folderId=0&pageIndex=" + pageIndex + "&returnValue=" + returnValue, {headers: {Authorization: apiToken}})
                .then(async res => {
                    res.data.data.length < returnValue && setMoreButton(false);
                    if (pageIndex !== 1) {
                        setImageData([...imageData, ...res.data.data.map(item => (
                            {
                                id: item.fileManagerId,
                                folderId: item.folderId,
                                fileFormat: item.fileFormat,
                                src: getThumb(item.fileFormat, item.fileManagerId),
                                thumbnail: getThumb(item.fileFormat, item.fileManagerId),
                                thumbnailWidth: 320 * item.pictureRatio,
                                thumbnailHeight: 320,
                                caption: item.fileName,
                                tags: item.tags ? item.tags.map(it => {
                                    return {value: it.itemId, title: it.name, tagType: it.tagType}
                                }) : [],
                            }
                        ))]);
                    } else {
                        if (selectedFolderId === null) {
                            setImageData(
                                res.data.data.map(item => (
                                    {
                                        id: item.fileManagerId,
                                        folderId: item.folderId,
                                        fileFormat: item.fileFormat,
                                        src: getThumb(item.fileFormat, item.fileManagerId),
                                        thumbnail: getThumb(item.fileFormat, item.fileManagerId),
                                        thumbnailWidth: 100 * item.pictureRatio,
                                        thumbnailHeight: 100,
                                        caption: item.fileName,
                                        tags: item.tags ? item.tags.map(it => {
                                            return {value: it.itemId, title: it.name, tagType: it.tagType}
                                        }) : [],
                                    }
                                ))
                            );
                        }
                    }
                    props.dispatch(SetSiteLoading(false));
                })
                .catch(() =>
                    props.dispatch(SetSiteLoading(false))
                );
        };

        const getSelectedFolderData = () => {
            props.dispatch(SetSiteLoading(true));
            if (selectedFolderId) {
                axios.get(
                    baseUrl + `/folders/${selectedFolderId}`,
                    {headers: {Authorization: apiToken}}
                )
                    .then(res => {
                        setSelectedFolderData(res.data.data.data);
                        props.dispatch(SetSiteLoading(false));
                        setMoreButton(false)
                    })
                    .catch(() =>
                        props.dispatch(SetSiteLoading(false))
                    )
            } else {
                props.dispatch(SetSiteLoading(false))
            }
        };

        useEffect(() => {
            getFilesWithoutFolder(pageIndex, 45)
        }, [pageIndex]);

        useEffect(() => {
            if (selectedFolderId) {
                getSelectedFolderData();
            } else {
                setDeletedItems([]);
                setPageIndex(1);
                setMoreButton(true);
                getFilesWithoutFolder(1, 45);
            }
        }, [selectedFolderId]);

        useEffect(() => {
            getFolders();
        }, []);

        const addFolder = () => {
            props.dispatch(SetSiteLoading(true));
            axios.post(
                baseUrl + "/folders",
                {
                    name: folderName,
                    folderPlatform: 0
                },
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    if (res.status === 200) {
                        sendNotification(enqueueSnackbar, "فولدر به گالری اضافه شد");
                        props.dispatch(SetSiteLoading(false));
                        setOpenModalAddFolder(false);
                        setFolderName('');
                        setFolderData([...folderData, res.data.data])
                    }
                })
                .catch(err => {
                        setOpenModalAddFolder(false);
                        props.dispatch(SetSiteLoading(false));
                        if (err.response && err.response.status === 400)
                            sendErrorNotification(enqueueSnackbar, err.response.data.message);
                        else
                            sendErrorNotification(enqueueSnackbar);
                        return false;
                    }
                )
        };

        const drag = (ev, thumb) => {
            ev.dataTransfer.setData("id", thumb);
        };

        const removeFolder = (folderId) => {
            axios.post(
                baseUrl + `/folders/remove/${folderId}`,
                {},
                {headers: {Authorization: apiToken}}
            )
                .then(res => {
                    if (res.status === 200) {
                        sendNotification(enqueueSnackbar, "فولدر حذف شد");
                        props.dispatch(SetSiteLoading(false));
                        setFolderName('');
                        setFolderData([...folderData.filter(x => x.folderId !== folderId)])
                    }
                })
                .catch(err => {
                        props.dispatch(SetSiteLoading(false));
                        if (err.response && err.response.status === 400)
                            sendErrorNotification(enqueueSnackbar, err.response.data.message);
                        else
                            sendErrorNotification(enqueueSnackbar);
                        return false;
                    }
                )
        };

        return (
            <>
                <div
                    className="d-flex justify-content-center flex-wrap"
                >
                    <FolderItem
                        all
                        title='همه'
                        deleteFromImageData={() => {
                            if (selectedFolderId) {
                                getSelectedFolderData()
                            }
                        }}
                        onClick={() => setSelectedFolderId(null)}
                    />
                    {
                        folderData.map((d, i) =>
                            <FolderItem
                                key={i}
                                title={d.name}
                                selectedFolder={selectedFolderId}
                                folderId={d.folderId}
                                deleteFromImageData={(id) => {
                                    if (selectedFolderId) {
                                        getSelectedFolderData()
                                    } else {
                                        setDeletedItems([...deletedItems, id])
                                    }
                                }}
                                onDeleteFolder={removeFolder}
                                onDoubleClick={() => setSelectedFolderId(d.folderId)}
                            />
                        )
                    }
                    <FolderItem
                        add
                        onClick={() => setOpenModalAddFolder(true)}
                    />
                </div>
                <div className="d-flex justify-content-center flex-wrap align-items-start">
                    {
                        selectedFolderId ?
                            selectedFolderData &&
                            selectedFolderData.map((image, key) =>
                                <Tooltip title={image.fileName} placement="top">
                                    <div
                                        key={key}
                                        draggable={true}
                                        onDragStart={(event) => drag(event, image.fileManagerId)}
                                        className="image-item-folders m-1 d-flex flex-column align-items-center"
                                        style={{
                                            maxWidth: 220
                                        }}
                                    >
                                        <img
                                            className="h-100"
                                            src={
                                                !image.fileName.includes("mp4") &&
                                                !image.fileName.includes("mp3") &&
                                                !image.fileName.includes("pdf") ?
                                                    getUrl(image.fileManagerId) :
                                                    getThumb(image.fileFormat, image.fileManagerId)
                                            }
                                            alt={image.fileName}
                                        />
                                    </div>
                                </Tooltip>
                            )
                            :
                            imageData.map((image, key) =>
                                !deletedItems.includes(image.id) &&
                                <Tooltip title={image.caption} placement="top">
                                    <div
                                        key={key}
                                        draggable={true}
                                        onDragStart={(event) => drag(event, image.id)}
                                        className=" m-1 d-flex flex-column align-items-center"
                                        style={{
                                            maxWidth: image.fileFormat === "png" ||
                                            image.fileFormat === "jpg" ||
                                            image.fileFormat === "jpeg" ?
                                                image.thumbnailWidth
                                                : 220,
                                            maxHeight: image.thumbnailHeight + 70
                                        }}
                                    >
                                        <img
                                            src={image.thumbnail}
                                            alt={image.caption}
                                            style={{height: 100}}
                                        />
                                    </div>
                                </Tooltip>
                            )
                    }
                </div>
                <Dialog
                    keepMounted
                    open={openModalAddFolder}
                    onClose={() => setOpenModalAddFolder(false)}
                    style={{zIndex: 2002}}
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-slide-title">اضافه کردن فولدر</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant='outlined'
                            label="نام فولدر"
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                            style={{width: '30rem'}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpenModalAddFolder(false)}
                            color="primary"
                            variant="outlined"
                            className="mx-2"
                        >
                            انصراف
                        </Button>
                        <Button
                            onClick={() => addFolder()}
                            color="secondary"
                            variant="outlined"
                        >
                            افزودن
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className=" d-flex w-100 justify-content-center pt-5">
                    {
                        !selectedFolderId &&
                        moreButtonProp &&
                        moreButton &&
                        <Button
                            onClick={() => setPageIndex(pageIndex + 1)}
                            variant='contained'
                            color="primary"
                            size="large"
                        >
                            بیشتر
                        </Button>
                    }
                </div>
            </>
        );
    }
;

export default connect()(FoldersMedia);