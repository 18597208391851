import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import AddIcon from '@material-ui/icons/Add'
import CardIcon from "../../../components/Card/CardIcon";
import {SetSiteLoading} from "../../../redux/actions/actions";
import Axios from "axios";
import {baseUrl} from "../../../appConfig";
import {apiToken} from "../../../appConfig";
import {sendErrorNotification, sendNotification} from "../../../functions";
import {useSnackbar} from "notistack";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Inputs from "./Inputs";

let inputsRef;
const CategoryEditor = ({onSubmit, reference, ...props}) => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();

    const {categoryId} = params;

    useImperativeHandle(reference, ()=> ({
        inputsRef
        // eslint-disable-next-line
    }), [inputsRef]);

    useEffect(()=> {
        if(categoryId && categoryId !== "" && inputsRef){
            Axios.get(baseUrl + "/categories/" + categoryId, {headers : {Authorization : apiToken}})
                .then(res => {
                    if(res.status === 200){
                        inputsRef.setName(res.data.data.name);
                        inputsRef.setSlug(res.data.data.slug);
                        inputsRef.setTerm(res.data.data.termId);
                    }
                    else if(res.status === 204){
                        sendErrorNotification(enqueueSnackbar, "دسته بندی مورد تظر وجود ندارد");
                        navigate("/admin/categories")
                    }
                })
                .catch(()=> {
                    sendErrorNotification(enqueueSnackbar)
                })
        }
        // eslint-disable-next-line
    }, [categoryId, inputsRef]);

    const handleSubmit = async (form) => {
        form.preventDefault();
        form.persist();
        await props.dispatch(SetSiteLoading(true));
        const inputs = form.target.querySelectorAll('input, select');
        let values = {};
        for (let i = 0; i < inputs.length; i++) {
            values[inputs[i].name] = {};
            values[inputs[i].name].importance = inputs[i].getAttribute('custom-importance') === "true";
            values[inputs[i].name].value = inputs[i].value;
        }
        if (Object.values(values).filter(val => val.value === "" && val.importance).length > 0) {
            inputsRef.setError(Object.keys(values).filter(val => values[val].value === "" && values[val].importance))
        }
        else{
            await Axios.post(`${baseUrl}/categories${categoryId ? ("/edit/" + categoryId) : ""}`,
                {
                    name : values.name.value,
                    slug : values.slug.value,
                    termId : values.term.value
                },
                {
                    headers: {
                        Authorization : apiToken,
                        "Content-Type": "application/json"
                    }
                })
                .then(async res => {
                    if (res.status === 200) {
                        if(onSubmit)
                            onSubmit(res.data.data, categoryId === undefined);
                        if(!categoryId){
                            Object.values(inputs).forEach(input => {
                                input.value = "";
                            });
                        }
                        else
                            navigate("/admin/categories");
                        sendNotification(enqueueSnackbar, "دسته بندی شما با موفقیت اضافه شد")
                    }
                })
                .catch(res => {
                    if (res.response && res.response.status === 400) {
                        sendErrorNotification(enqueueSnackbar, res.response.data.message);
                    } else {
                        sendErrorNotification(enqueueSnackbar);
                    }
                })
        }
        await props.dispatch(SetSiteLoading(false));
    };

    return (
        <Card>
            <CardHeader icon>
                <CardIcon color="success">
                    <AddIcon className="px-1" />
                </CardIcon>
                <h4 style={{color:"black"}}>{`${categoryId ? "ویرایش" : "افزودن"}`} دسته بندی</h4>
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <Inputs ref={ref => {inputsRef = ref}} />
                    {
                        categoryId &&
                        (
                            <Button
                                color="secondary"
                                variant="outlined"
                                className="mt-3"
                                onClick={()=> navigate("/admin/categories")}
                            >
                                انصراف
                            </Button>
                        )
                    }
                    <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        className="mt-3 mx-2"
                    >
                        {categoryId ? "ویرایش" : "افزودن"}
                    </Button>
                </form>
            </CardBody>
        </Card>
    );
};

const TheComponent = connect()(CategoryEditor);

export default forwardRef((props, ref) => (
    <TheComponent reference={ref} {...props} />
));