import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Fab from "@material-ui/core/Fab";
import {SetMediaDrawer, SetSiteLoading} from "../../../redux/actions/actions";
import {getUrl} from "../../../functions";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import axios from "axios";
import {apiToken, baseUrl} from "../../../appConfig";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HandleArchivePictureDialog = ({isEditMode = false, editItemId, onSubmit, onClose, currentImage, ...props}) => {
    const [nameForTarget, setNameForTarget] = useState("");
    const [chosenPicture, setChosenPicture] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);

    const getTags = (keyword = "") => {
        return axios.get(
            baseUrl + `/picturearchives/search/tags?keyword=${keyword}&returnValue=15`,
            {headers: {Authorization: apiToken}}
        )
            .then(async res => {
                    return [
                        {
                            label: 'دسته بندی',
                            options: res.data.data.categories.map((d) => {
                                return {value: parseInt(d.value), label: d.label, type: 0}
                            }),
                        },
                        {
                            label: 'سوزه ها',
                            options: res.data.data.targets.map((d) => {
                                return {value: parseInt(d.value), label: d.label, type: 1}
                            }),
                        },
                        {
                            label: 'مکان ها',
                            options: res.data.data.locations.map((d) => {
                                return {value: parseInt(d.value), label: d.label, type: 2}
                            }),
                        },
                    ]
                }
            );

    };

    useEffect(() => {
        if (currentImage) {
            const tgs = currentImage.tags.map((d) => {
                return {value: d.value, label: d.title}
            })
            setSelectedTags(tgs)
            setNameForTarget(currentImage.caption)
        }
        if (isEditMode) {
            props.dispatch(SetSiteLoading(true));
            axios.get(baseUrl + "/pictureArchives/" + editItemId, {headers: {Authorization: apiToken}})
                .then(res => {
                    if (res.status === 200) {
                        const {data} = res.data;
                        setChosenPicture(data.fileManagerId);
                        setNameForTarget(data.title);
                        if (data.tags)
                            setSelectedTags(data.tags.map(it => ({
                                value: it.itemId,
                                label: it.name,
                                type: it.tagType
                            })));
                    }
                });
            props.dispatch(SetSiteLoading(false));
        } else {
            setSelectedTags([])
            setNameForTarget("")
            setChosenPicture(null)
        }
        // eslint-disable-next-line
    }, [editItemId]);

    return (
        <Dialog
            {...props}
            keepMounted
            onBackdropClick={onClose}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">تصویر مورد نظر را انتخاب و تگ گذاری کنید</DialogTitle>
            <DialogContent>
                <Card
                    style={{height: "70vh", maxHeight: "37rem"}}
                >
                    <CardBody>
                        <TextField
                            variant="outlined"
                            label="نام"
                            type="text"
                            className="my-2 w-100"
                            onChange={(e) =>
                                setNameForTarget(e.target.value)
                            }
                            value={nameForTarget}
                        />
                        <AsyncSelect
                            isRtl
                            isMulti
                            defaultOptions
                            isClearable
                            noOptionsMessage={() => "هیچ گزینه ای وجود ندارد"}
                            loadingMessage={() => "در حال بارگزاری .."}
                            loadOptions={inputValue =>
                                getTags(inputValue)
                            }
                            placeholder="تگ ها را انتخاب کنید"
                            onChange={setSelectedTags}
                            value={selectedTags}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: "3rem",
                                })
                            }}
                        />

                        {
                            !isEditMode && (
                                <Fab
                                    className="w-100 mt-2"
                                    variant="extended"
                                    color="secondary"
                                    onClick={() => props.dispatch(SetMediaDrawer({
                                        type: "image",
                                        value: chosenPicture,
                                        action: setChosenPicture,
                                        tagHandler: false
                                    }))}
                                >
                                    <span>انتخاب تصویر</span>
                                </Fab>
                            )
                        }
                        {
                            isEditMode ?
                                ""
                                    // <div
                                    //     className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light"
                                    //     onClick={() => props.dispatch(SetMediaDrawer({
                                    //         type: "image",
                                    //         value: chosenPicture,
                                    //         action: setChosenPicture,
                                    //         tagHandler: false
                                    //     }))}
                                    // >
                                    //     <img
                                    //         className="h-auto"
                                    //         style={{maxWidth: "100%", maxHeight: "20rem"}}
                                    //         src={currentImage.thumbnail}
                                    //         alt="feature"
                                    //     />
                                    // </div>)
                                :
                                chosenPicture &&
                                (
                                    <div
                                        className="image-preview d-flex align-items-center justify-content-center mt-3 p-3 bg-light">
                                        <img
                                            className="h-auto"
                                            style={{maxWidth: "100%", maxHeight: "20rem"}}
                                            src={getUrl(chosenPicture, 500)}
                                            alt="feature"
                                        />
                                    </div>
                                )
                        }
                    </CardBody>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    لغو
                </Button>
                <Button
                    onClick={() => {
                        isEditMode ?
                            onSubmit({editItemId, nameForTarget, chosenPicture, selectedTags})
                            :
                            onSubmit({nameForTarget, chosenPicture, selectedTags})
                    }}
                    color="primary"
                >
                    {isEditMode ? "ویرایش" : "ثبت"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect()(HandleArchivePictureDialog);